import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enableMapSet, enablePatches } from "immer";
import { SnackbarProvider } from "notistack";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { GlobalLoadingFallback } from "~/components/GlobalLoadingFallback";
import "~/css/styles.css";
import { AuthenticationGuard } from "~/domain/auth";
import {
  DsmCommonResourcesProvider,
  DSMProvider,
  GuestLandingPage,
} from "~/dsm";
import {
  DataStoreCreatePage,
  DataStoreDetailsPage,
  DataStoreEditPage,
  DataStoresPage,
  DsmUserCreatePage,
  DsmUserDetailsPage,
  DsmUserEditPage,
  DsmUserTablePage,
} from "~/dsm/pages";
import * as dsmPaths from "~/dsm/paths";
import { DefaultErrorFallback } from "~/errors";
import { DataStoreProvider } from "~/lqs";
import {
  ApiKeyPage,
  ApiKeysPage,
  DataStoreDashboardPage,
  DigestionPage,
  DigestionPartPage,
  DigestionPartsPage,
  DigestionsPage,
  DigestionTopicPage,
  DigestionTopicsPage,
  EditApiKeyPage,
  EditDigestionPage,
  EditDigestionPartPage,
  EditDigestionTopicPage,
  EditGroupPage,
  EditIngestionPage,
  EditIngestionPartPage,
  EditLabelPage,
  EditLogPage,
  EditLogQueryPage,
  EditObjectStorePage,
  EditRecordPage,
  EditRolePage,
  EditTagPage,
  EditTopicPage,
  EditWorkflowHookPage,
  EditWorkflowPage,
  GroupPage,
  GroupsPage,
  IngestionPage,
  IngestionPartPage,
  IngestionPartsPage,
  IngestionsPage,
  LabelPage,
  LabelsPage,
  LogObjectPage,
  LogObjectsPage,
  LogPage,
  LogQueriesPage,
  LogQueryPage,
  LogsPage,
  LqsUserCreatePage,
  LqsUserDetailsPage,
  LqsUserEditPage,
  LqsUserTablePage,
  NewApiKeyPage,
  NewDigestionPage,
  NewDigestionPartPage,
  NewDigestionTopicPage,
  NewGroupPage,
  NewIngestionPage,
  NewIngestionPartPage,
  NewLabelPage,
  NewLogPage,
  NewLogQueryPage,
  NewObjectStorePage,
  NewRecordPage,
  NewRolePage,
  NewTagPage,
  NewTopicPage,
  NewWorkflowHookPage,
  NewWorkflowPage,
  NotFoundPage,
  ObjectStoreObjectPage,
  ObjectStoreObjectsPage,
  ObjectStorePage,
  ObjectStoresPage,
  PlayerPage,
  ProfilePage,
  RecordPage,
  RecordsPage,
  RolePage,
  RolesPage,
  StudioHomepage,
  TaggingPage,
  TagPage,
  TagsPage,
  TopicPage,
  TopicsPage,
  UploadLogObjectPage,
  UploadPage,
  WorkflowHookPage,
  WorkflowHooksPage,
  WorkflowPage,
  WorkflowsPage,
} from "~/pages";
import * as paths from "~/paths";
import { CommonQueryClientProvider } from "~/providers/CommonQueryClientProvider";
import ThemeProvider from "~/providers/ThemeProvider";
import { SettingsProvider } from "~/settings";

enablePatches();
enableMapSet();

const ROUTER_BASENAME = process.env.PUBLIC_URL || undefined;

export default function App() {
  return (
    <SettingsProvider>
      <HelmetProvider>
        <ThemeProvider>
          <AuthenticationGuard
            pendingFallback={<GlobalLoadingFallback />}
            unauthenticatedFallback={<GuestLandingPage />}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ErrorBoundary FallbackComponent={DefaultErrorFallback}>
                <DSMProvider>
                  <CommonQueryClientProvider>
                    <Router basename={ROUTER_BASENAME}>
                      <QueryParamProvider adapter={ReactRouter6Adapter}>
                        <SnackbarProvider maxSnack={1}>
                          <Routes>
                            <Route
                              element={
                                <DsmCommonResourcesProvider>
                                  <Outlet />
                                </DsmCommonResourcesProvider>
                              }
                            >
                              <Route
                                path={paths.STUDIO_HOMEPAGE}
                                element={<StudioHomepage />}
                              />
                              <Route
                                path={dsmPaths.DATASTORE_TABLE}
                                element={<DataStoresPage />}
                              />
                              <Route
                                path={dsmPaths.DATASTORE_CREATE}
                                element={<DataStoreCreatePage />}
                              />
                              <Route
                                path={dsmPaths.DATASTORE_DETAILS}
                                element={<DataStoreDetailsPage />}
                              />
                              <Route
                                path={dsmPaths.DATASTORE_EDIT}
                                element={<DataStoreEditPage />}
                              />
                              <Route
                                path={dsmPaths.USER_TABLE}
                                element={<DsmUserTablePage />}
                              />
                              <Route
                                path={dsmPaths.USER_CREATE}
                                element={<DsmUserCreatePage />}
                              />
                              <Route
                                path={dsmPaths.USER_DETAILS}
                                element={<DsmUserDetailsPage />}
                              />
                              <Route
                                path={dsmPaths.USER_EDIT}
                                element={<DsmUserEditPage />}
                              />
                            </Route>
                            <Route
                              element={
                                <DataStoreProvider>
                                  <Outlet />
                                </DataStoreProvider>
                              }
                            >
                              <Route
                                path={paths.DATASTORE}
                                element={<DataStoreDashboardPage />}
                              />
                              <Route
                                path={paths.UPLOAD}
                                element={<UploadPage />}
                              />
                              <Route
                                path={paths.PLAYER}
                                element={<PlayerPage />}
                              />
                              <Route
                                path={paths.TAGGING}
                                element={<TaggingPage />}
                              />
                              <Route path={paths.LOGS} element={<LogsPage />} />
                              <Route path={paths.LOG} element={<LogPage />} />
                              <Route
                                path={paths.NEW_LOG}
                                element={<NewLogPage />}
                              />
                              <Route
                                path={paths.EDIT_LOG}
                                element={<EditLogPage />}
                              />
                              <Route path={paths.TAGS} element={<TagsPage />} />
                              <Route
                                path={paths.NEW_TAG}
                                element={<NewTagPage />}
                              />
                              <Route path={paths.TAG} element={<TagPage />} />
                              <Route
                                path={paths.EDIT_TAG}
                                element={<EditTagPage />}
                              />
                              <Route
                                path={paths.LOG_OBJECTS}
                                element={<LogObjectsPage />}
                              />
                              <Route
                                path={paths.UPLOAD_LOG_OBJECT}
                                element={<UploadLogObjectPage />}
                              />
                              <Route
                                path={paths.LOG_OBJECT}
                                element={<LogObjectPage />}
                              />
                              <Route
                                path={paths.LOG_QUERIES}
                                element={<LogQueriesPage />}
                              />
                              <Route
                                path={paths.NEW_LOG_QUERY}
                                element={<NewLogQueryPage />}
                              />
                              <Route
                                path={paths.LOG_QUERY}
                                element={<LogQueryPage />}
                              />
                              <Route
                                path={paths.EDIT_LOG_QUERY}
                                element={<EditLogQueryPage />}
                              />
                              <Route
                                path={paths.INGESTIONS}
                                element={<IngestionsPage />}
                              />
                              <Route
                                path={paths.INGESTION}
                                element={<IngestionPage />}
                              />
                              <Route
                                path={paths.NEW_INGESTION}
                                element={<NewIngestionPage />}
                              />
                              <Route
                                path={paths.EDIT_INGESTION}
                                element={<EditIngestionPage />}
                              />
                              <Route
                                path={paths.INGESTION_PARTS}
                                element={<IngestionPartsPage />}
                              />
                              <Route
                                path={paths.NEW_INGESTION_PART}
                                element={<NewIngestionPartPage />}
                              />
                              <Route
                                path={paths.INGESTION_PART}
                                element={<IngestionPartPage />}
                              />
                              <Route
                                path={paths.EDIT_INGESTION_PART}
                                element={<EditIngestionPartPage />}
                              />
                              <Route
                                path={paths.DIGESTIONS}
                                element={<DigestionsPage />}
                              />
                              <Route
                                path={paths.DIGESTION}
                                element={<DigestionPage />}
                              />
                              <Route
                                path={paths.NEW_DIGESTION}
                                element={<NewDigestionPage />}
                              />
                              <Route
                                path={paths.EDIT_DIGESTION}
                                element={<EditDigestionPage />}
                              />
                              <Route
                                path={paths.DIGESTIONS_TOPICS}
                                element={<DigestionTopicsPage />}
                              />
                              <Route
                                path={paths.NEW_DIGESTION_TOPIC}
                                element={<NewDigestionTopicPage />}
                              />
                              <Route
                                path={paths.DIGESTION_TOPIC}
                                element={<DigestionTopicPage />}
                              />
                              <Route
                                path={paths.EDIT_DIGESTION_TOPIC}
                                element={<EditDigestionTopicPage />}
                              />
                              <Route
                                path={paths.DIGESTION_PARTS}
                                element={<DigestionPartsPage />}
                              />
                              <Route
                                path={paths.NEW_DIGESTION_PART}
                                element={<NewDigestionPartPage />}
                              />
                              <Route
                                path={paths.DIGESTION_PART}
                                element={<DigestionPartPage />}
                              />
                              <Route
                                path={paths.EDIT_DIGESTION_PART}
                                element={<EditDigestionPartPage />}
                              />
                              <Route
                                path={paths.TOPICS}
                                element={<TopicsPage />}
                              />
                              <Route
                                path={paths.TOPIC}
                                element={<TopicPage />}
                              />
                              <Route
                                path={paths.NEW_TOPIC}
                                element={<NewTopicPage />}
                              />
                              <Route
                                path={paths.EDIT_TOPIC}
                                element={<EditTopicPage />}
                              />
                              <Route
                                path={paths.RECORDS}
                                element={<RecordsPage />}
                              />
                              <Route
                                path={paths.NEW_RECORD}
                                element={<NewRecordPage />}
                              />
                              <Route
                                path={paths.RECORD}
                                element={<RecordPage />}
                              />
                              <Route
                                path={paths.EDIT_RECORD}
                                element={<EditRecordPage />}
                              />
                              <Route
                                path={paths.USERS}
                                element={<LqsUserTablePage />}
                              />
                              <Route
                                path={paths.USER}
                                element={<LqsUserDetailsPage />}
                              />
                              <Route
                                path={paths.NEW_USER}
                                element={<LqsUserCreatePage />}
                              />
                              <Route
                                path={paths.EDIT_USER}
                                element={<LqsUserEditPage />}
                              />
                              <Route
                                path={paths.GROUPS}
                                element={<GroupsPage />}
                              />
                              <Route
                                path={paths.GROUP}
                                element={<GroupPage />}
                              />
                              <Route
                                path={paths.NEW_GROUP}
                                element={<NewGroupPage />}
                              />
                              <Route
                                path={paths.EDIT_GROUP}
                                element={<EditGroupPage />}
                              />
                              <Route
                                path={paths.API_KEYS}
                                element={<ApiKeysPage />}
                              />
                              <Route
                                path={paths.NEW_API_KEY}
                                element={<NewApiKeyPage />}
                              />
                              <Route
                                path={paths.API_KEY}
                                element={<ApiKeyPage />}
                              />
                              <Route
                                path={paths.EDIT_API_KEY}
                                element={<EditApiKeyPage />}
                              />
                              <Route
                                path={paths.ROLES}
                                element={<RolesPage />}
                              />
                              <Route
                                path={paths.NEW_ROLE}
                                element={<NewRolePage />}
                              />
                              <Route path={paths.ROLE} element={<RolePage />} />
                              <Route
                                path={paths.EDIT_ROLE}
                                element={<EditRolePage />}
                              />
                              <Route
                                path={paths.WORKFLOWS}
                                element={<WorkflowsPage />}
                              />
                              <Route
                                path={paths.NEW_WORKFLOW}
                                element={<NewWorkflowPage />}
                              />
                              <Route
                                path={paths.WORKFLOW}
                                element={<WorkflowPage />}
                              />
                              <Route
                                path={paths.EDIT_WORKFLOW}
                                element={<EditWorkflowPage />}
                              />
                              <Route
                                path={paths.HOOKS}
                                element={<WorkflowHooksPage />}
                              />
                              <Route
                                path={paths.NEW_HOOK}
                                element={<NewWorkflowHookPage />}
                              />
                              <Route
                                path={paths.HOOK}
                                element={<WorkflowHookPage />}
                              />
                              <Route
                                path={paths.EDIT_HOOK}
                                element={<EditWorkflowHookPage />}
                              />
                              <Route
                                path={paths.LABELS}
                                element={<LabelsPage />}
                              />
                              <Route
                                path={paths.LABEL}
                                element={<LabelPage />}
                              />
                              <Route
                                path={paths.NEW_LABEL}
                                element={<NewLabelPage />}
                              />
                              <Route
                                path={paths.EDIT_LABEL}
                                element={<EditLabelPage />}
                              />
                              <Route
                                path={paths.OBJECT_STORES}
                                element={<ObjectStoresPage />}
                              />
                              <Route
                                path={paths.NEW_OBJECT_STORE}
                                element={<NewObjectStorePage />}
                              />
                              <Route
                                path={paths.OBJECT_STORE}
                                element={<ObjectStorePage />}
                              />
                              <Route
                                path={paths.EDIT_OBJECT_STORE}
                                element={<EditObjectStorePage />}
                              />
                              <Route
                                path={paths.OBJECT_STORE_OBJECTS}
                                element={<ObjectStoreObjectsPage />}
                              />
                              <Route
                                path={paths.OBJECT_STORE_OBJECT}
                                element={<ObjectStoreObjectPage />}
                              />
                              <Route
                                path={paths.PROFILE}
                                element={<ProfilePage />}
                              />
                            </Route>
                            <Route path="*" element={<NotFoundPage />} />
                          </Routes>
                        </SnackbarProvider>
                      </QueryParamProvider>
                    </Router>
                  </CommonQueryClientProvider>
                </DSMProvider>
              </ErrorBoundary>
            </LocalizationProvider>
          </AuthenticationGuard>
        </ThemeProvider>
      </HelmetProvider>
    </SettingsProvider>
  );
}
