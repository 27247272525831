import { Alert, Link } from "@mui/material";

const STUDIO_V0_ORIGIN = "https://studio-v0.logqs.com/";

export function MigrationAnnouncement() {
  return (
    <Alert
      sx={{
        mb: 4,
        color: (theme) => (theme.palette.mode === "dark" ? "white" : undefined),
      }}
      severity="info"
      variant="filled"
    >
      We've upgraded LogQS! You can still access the previous version at{" "}
      <Link
        sx={{ color: "inherit", fontWeight: "bold" }}
        href={STUDIO_V0_ORIGIN}
      >
        {STUDIO_V0_ORIGIN}
      </Link>
    </Alert>
  );
}
