import {
  CheckboxField,
  ObjectField,
  SelectField,
  TextField,
} from "~/components/Form";
import {
  NewLqsResourceForm,
  processStateOptions,
  processTypeOptions,
  useCreateWorkflowHook,
} from "~/lqs";
import { makeHookLocation, useWorkflowParams } from "~/paths";
import { createWorkflowHookSchema } from "../schemas";

export function WorkflowHookCreateForm() {
  const { workflowId } = useWorkflowParams();

  return (
    <NewLqsResourceForm
      schema={createWorkflowHookSchema}
      defaultValues={{
        triggerProcess: null,
        triggerState: null,
        name: null,
        disabled: false,
        managed: false,
        uri: null,
        secret: null,
        note: null,
        context: null,
      }}
      resourceName="hook"
      mutation={useCreateWorkflowHook(workflowId)}
      createDetailsLocation={(response) =>
        makeHookLocation({ workflowId, hookId: response.data.id })
      }
    >
      {(control) => (
        <>
          <TextField control={control} name="name" />
          <SelectField
            control={control}
            name="triggerProcess"
            options={processTypeOptions}
            required
          />
          <SelectField
            control={control}
            name="triggerState"
            options={processStateOptions}
            required
          />
          <TextField control={control} name="uri" />
          <TextField control={control} name="secret" />
          <CheckboxField control={control} name="managed" />
          <CheckboxField control={control} name="disabled" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </NewLqsResourceForm>
  );
}
