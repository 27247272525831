import type { UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import { DetailsLayout, LockCard } from "~/components/DetailsCards";
import type { IngestionPart } from "~/lqs";
import {
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteIngestionPart,
  useIngestionPart,
  useUpdateIngestionPart,
} from "~/lqs";
import {
  calculateProcessRefetchInterval,
  Process,
} from "~/lqs/components/process";
import {
  makeEditIngestionPartLocation,
  makeIngestionPartsLocation,
  useIngestionPartParams,
} from "~/paths";
import { selectData } from "~/utils";

export function IngestionPartDetails() {
  const { ingestionId, ingestionPartId } = useIngestionPartParams();

  const query = useIngestionPart(ingestionId, ingestionPartId, {
    select: selectData,
    refetchInterval: calculateProcessRefetchInterval,
  });

  const generalSection = <GeneralSection query={query} />;
  const processSection = (
    <Process
      query={query}
      mutation={useUpdateIngestionPart(ingestionId, ingestionPartId)}
    />
  );
  const lockSection = (
    <LockCard
      resourceName="ingestion part"
      query={query}
      updateMutation={useUpdateIngestionPart(ingestionId, ingestionPartId)}
    />
  );
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="ingestion part"
      query={query}
      editLocation={makeEditIngestionPartLocation({
        ingestionId,
        ingestionPartId,
      })}
      deleteMutation={useDeleteIngestionPart(ingestionId, ingestionPartId)}
      getReadableName={(ingestionPart) => ingestionPart.id}
      listLocation={makeIngestionPartsLocation({ ingestionId })}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {processSection}
        </>
      }
      secondaryGridColumn={
        <>
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {processSection}
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<IngestionPart> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          {
            dataType: "foreign-key",
            resourceType: "ingestion",
            accessor: "ingestionId",
          },
          { dataType: "number", accessor: "sequence" },
        ]}
      />
    </Card>
  );
}
