import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { TablePage } from "~/components/Table";
import { LqsNavigation } from "~/lqs";
import { IngestionPartFilters } from "./components/IngestionPartFilters";
import {
  IngestionPartSearchRequestProvider,
  IngestionPartTable,
} from "./components/IngestionPartTable";

export function IngestionPartsPage() {
  return (
    <TablePage
      title="Search Ingestion Parts"
      navigation={<LqsNavigation />}
      RequestProvider={IngestionPartSearchRequestProvider}
      table={
        <>
          <DataStoreBreadcrumbs />
          <IngestionPartTable />
        </>
      }
      filters={<IngestionPartFilters />}
    />
  );
}
