import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { UserDetailsPage } from "~/domain/common";
import { LqsNavigation } from "~/lqs";

export function LqsUserDetailsPage() {
  return (
    <UserDetailsPage
      navigation={<LqsNavigation />}
      breadcrumbs={<DataStoreBreadcrumbs />}
    />
  );
}
