import { Button } from "@mui/material";
import {
  ObjectExplorer,
  ObjectExplorerFilters as LogObjectFilters,
  ObjectSearchRequestProvider as LogObjectSearchRequestProvider,
} from "~/components/ObjectExplorer";
import type { Log } from "~/lqs";
import {
  stripLogKeyPrefix,
  useLogObjectQueryOptionsFactory,
  useLogObjectsQueryOptionsFactory,
} from "~/lqs";
import {
  DataStoreLink,
  makeLogObjectLocation,
  makeUploadLogObjectLocation,
} from "~/paths";
import type { Maybe } from "~/types";

export { LogObjectSearchRequestProvider, LogObjectFilters };

export function LogObjectTable({
  logId,
  homeName = logId,
  subResourcePrefix,
}: {
  logId: Log["id"];
  homeName?: string;
  subResourcePrefix?: string;
}) {
  const createLogObjectQueryOptions = useLogObjectQueryOptionsFactory();
  const createLogObjectsQueryOptions = useLogObjectsQueryOptionsFactory();

  return (
    <ObjectExplorer
      homeName={homeName}
      toolbarAction={
        <Button
          color="primary"
          variant="contained"
          disableElevation
          component={DataStoreLink}
          to={makeUploadLogObjectLocation({ logId })}
        >
          Upload Object
        </Button>
      }
      createObjectQueryOptions={createLogObjectQueryOptions.bind(null, logId)}
      createSearchQueryOptions={(request) =>
        createLogObjectsQueryOptions(logId, {
          ...request,
          prefix: addSubResourcePrefix(subResourcePrefix, request.prefix),
        })
      }
      createObjectLocation={(objectKey) =>
        makeLogObjectLocation({
          logId,
          key: stripLogKeyPrefix(logId, objectKey),
        })
      }
      prepareDirectorySearchParam={(directory) => {
        return removeSubResourcePrefix(
          subResourcePrefix,
          stripLogKeyPrefix(logId, directory),
        );
      }}
    />
  );
}

function addSubResourcePrefix(
  subResourcePrefix: Maybe<string>,
  key: Maybe<string>,
): Maybe<string> {
  if (subResourcePrefix == null) {
    return key;
  } else if (key == null) {
    return subResourcePrefix;
  } else {
    // `subResourcePrefix` should already end with delimiter
    return `${subResourcePrefix}${key}`;
  }
}

function removeSubResourcePrefix(
  subResourcePrefix: Maybe<string>,
  directory: string,
): string {
  if (subResourcePrefix == null) {
    return directory;
  } else if (directory.startsWith(subResourcePrefix)) {
    return directory.slice(subResourcePrefix.length);
  } else {
    return directory;
  }
}
