import React from "react";
import { Settings } from "@mui/icons-material";
import { Container } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import * as Layout from "~/layout";
import {
  FullWidthContainer,
  LayoutStateProvider,
  ScrollableContainer,
  SidebarSwitch,
  SidebarTrigger,
} from "~/layout";
import { SettingsSidebar } from "~/settings";
import { DocumentTitle } from "../document-title";
import { SearchValidationErrorFallback } from "./withSearchValidationErrorBoundary";

export function TablePage({
  title,
  navigation,
  RequestProvider,
  table,
  filters,
}: {
  title: string;
  navigation: React.ReactNode;
  RequestProvider: React.ComponentType<{ children: React.ReactNode }>;
  table: React.ReactNode;
  filters: React.ReactNode;
}) {
  return (
    <>
      <DocumentTitle>{title}</DocumentTitle>
      <LayoutStateProvider>
        <Layout.Root>
          <Layout.Navigation>{navigation}</Layout.Navigation>
          <Layout.Header
            title={title}
            actions={
              <SidebarTrigger
                title="Settings"
                sidebarId="settings"
                icon={<Settings />}
              />
            }
          />
          <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
              <Layout.Main>
                <ScrollableContainer>
                  <Container fixed>
                    <SearchValidationErrorFallback
                      error={error}
                      resetErrorBoundary={resetErrorBoundary}
                    />
                  </Container>
                </ScrollableContainer>
              </Layout.Main>
            )}
          >
            <RequestProvider>
              <Layout.Main>
                <ScrollableContainer>
                  <FullWidthContainer>{table}</FullWidthContainer>
                </ScrollableContainer>
              </Layout.Main>
              <Layout.Sidebar>
                <SidebarSwitch
                  options={[
                    {
                      sidebarId: "settings",
                      element: <SettingsSidebar />,
                    },
                    {
                      sidebarId: "filters",
                      element: filters,
                    },
                  ]}
                />
              </Layout.Sidebar>
            </RequestProvider>
          </ErrorBoundary>
        </Layout.Root>
      </LayoutStateProvider>
    </>
  );
}
