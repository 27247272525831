import { fromUnixTime, nanosecondsToSeconds } from "~/lib/dates";
import type { Maybe } from "~/types";

export function formatTimestamp(timestamp: bigint) {
  const date = fromUnixTime(nanosecondsToSeconds(timestamp));

  return date.toUTCString();
}

interface TimestampProps {
  value: Maybe<bigint>;
}

export function Timestamp({ value }: TimestampProps) {
  return value == null ? (
    "-"
  ) : (
    <span title={String(value)}>{formatTimestamp(value)}</span>
  );
}
