import React from "react";
import { Delete, Edit, Refresh } from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import type { DataStorePathGenerator } from "~/paths";
import { DataStoreLink } from "~/paths";

export interface DetailsToolbarProps<TResource> {
  query: UseQueryResult<TResource>;
  startActions?: React.ReactNode;
  editLocation?: DataStorePathGenerator;
  onInitiateDelete?: () => void;
}

export function DetailsToolbar<TResource>({
  query,
  startActions,
  editLocation,
  onInitiateDelete,
}: DetailsToolbarProps<TResource>) {
  function handleRefresh() {
    query.refetch();
  }

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
      {startActions}
      <Tooltip title="Refresh">
        <span>
          <IconButton onClick={handleRefresh} disabled={query.isLoading}>
            <Refresh />
          </IconButton>
        </span>
      </Tooltip>
      {editLocation !== undefined && (
        <Tooltip title="Edit">
          <IconButton component={DataStoreLink} to={editLocation}>
            <Edit />
          </IconButton>
        </Tooltip>
      )}
      {onInitiateDelete !== undefined && (
        <Box sx={{ ml: "auto" }}>
          <Tooltip title="Delete...">
            <span>
              <IconButton
                onClick={onInitiateDelete}
                color="error"
                disabled={!query.isSuccess}
              >
                <Delete />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      )}
    </Stack>
  );
}
