import type { Location, ParamParseKey, Params } from "react-router-dom";
import { generatePath, useParams } from "react-router-dom";
import type {
  IsEmptyObject,
  OverrideProperties,
  RequireExactlyOne,
  SetReturnType,
} from "type-fest";
import type { Log, Record, Topic } from "~/lqs";
import { serializeSearchParams } from "~/utils";
import type { DataStorePathGenerator } from "./types";

type NonNullableFields<TType extends object> = {
  [Key in keyof TType]: NonNullable<TType[Key]>;
};

function createDataStoreResourcePaths<
  const TResource extends string,
  const TIdentifier extends string,
>(resource: TResource, identifier: TIdentifier) {
  const LIST = `${DATASTORE}/${resource}` as const;
  const NEW = `${LIST}/new` as const;
  const DETAILS = `${LIST}/:${identifier}` as const;
  const EDIT = `${DETAILS}/edit` as const;

  return {
    LIST,
    makeListLocation: createDataStoreLocationGenerator(LIST),
    NEW,
    makeNewLocation: createDataStoreLocationGenerator(NEW),
    DETAILS,
    makeDetailsLocation: createDataStoreLocationGenerator(DETAILS),
    EDIT,
    makeEditLocation: createDataStoreLocationGenerator(EDIT),
  };
}

function createDataStoreSubresourcePaths<
  const TBasePath extends `${typeof DATASTORE}/${string}`,
  const TResource extends string,
  const TIdentifier extends string,
>(basePath: TBasePath, resource: TResource, identifier: TIdentifier) {
  const LIST = `${basePath}/${resource}` as const;
  const NEW = `${LIST}/new` as const;
  const DETAILS = `${LIST}/:${identifier}` as const;
  const EDIT = `${DETAILS}/edit` as const;

  return {
    LIST,
    NEW,
    DETAILS,
    EDIT,
  };
}

type ParamsShape<TPath extends string> = NonNullableFields<
  Params<ParamParseKey<TPath>>
>;

/**
 * Creates a typed version of react-router's `useParams` hook for a specific
 * path.
 */
export type UseTypedParams<TPath extends string> = SetReturnType<
  typeof useParams,
  ParamsShape<TPath>
>;

type DataStoreResourcePathParams<
  TPath extends `${typeof DATASTORE}/${string}`,
> = TPath extends `${typeof DATASTORE}${infer Rest}`
  ? ParamsShape<Rest>
  : never;

function createDataStoreLocationGenerator<
  TPath extends `${typeof DATASTORE}/${string}`,
>(
  path: TPath,
): IsEmptyObject<DataStoreResourcePathParams<TPath>> extends true
  ? () => DataStorePathGenerator
  : (params: DataStoreResourcePathParams<TPath>) => DataStorePathGenerator {
  return function makeLocation(params: any): DataStorePathGenerator {
    return (dataStore) => {
      return {
        pathname: generatePath(path, {
          dataStoreName: dataStore.name,
          ...params,
        }),
      };
    };
  } as any;
}

export const STUDIO_HOMEPAGE = "/" as const;

export interface StudioHomepageState {
  unknownDataStore?: string;
}

export function makeStudioHomepageLocation(
  state?: RequireExactlyOne<StudioHomepageState>,
): Partial<Location> {
  return {
    pathname: STUDIO_HOMEPAGE,
    state,
  };
}

export const DATASTORE = "/datastores/:dataStoreName" as const;

export function makeDataStoreDashboardLocation(): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(DATASTORE, { dataStoreName: dataStore.name }),
    };
  };
}

export const useDataStoreParams = useParams as UseTypedParams<typeof DATASTORE>;

export const UPLOAD = `${DATASTORE}/upload` as const;

export const makeUploadLocation = createDataStoreLocationGenerator(UPLOAD);

export const PLAYER = `${DATASTORE}/player` as const;

export type PlayerQuery = { logId?: Log["id"] };

export function makePlayerLocation(
  query?: PlayerQuery,
): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(PLAYER, { dataStoreName: dataStore.name }),
      search: makeSearchString(query),
    };
  };
}

export const TAGGING = `${DATASTORE}/tagging` as const;

export const makeTaggingLocation = createDataStoreLocationGenerator(TAGGING);

export const PROFILE = `${DATASTORE}/profile` as const;

export const makeProfileLocation = createDataStoreLocationGenerator(PROFILE);

export const {
  NEW: NEW_LOG,
  makeNewLocation: makeNewLogLocation,
  DETAILS: LOG,
  makeDetailsLocation: makeLogLocation,
  EDIT: EDIT_LOG,
  makeEditLocation: makeEditLogLocation,
} = createDataStoreResourcePaths("logs", "logId");

export const LOGS = `${DATASTORE}/logs` as const;

export const makeLogsLocation = createDataStoreLocationGenerator(LOGS);

export const useLogParams = useParams as UseTypedParams<typeof LOG>;

export const {
  LIST: TAGS,
  NEW: NEW_TAG,
  DETAILS: TAG,
  EDIT: EDIT_TAG,
} = createDataStoreSubresourcePaths(LOG, "tags", "tagId");

export const makeTagsLocation = createDataStoreLocationGenerator(TAGS);

export const makeNewTagLocation = createDataStoreLocationGenerator(NEW_TAG);

export const makeTagLocation = createDataStoreLocationGenerator(TAG);

export const useTagParams = useParams as UseTypedParams<typeof TAG>;

export const makeEditTagLocation = createDataStoreLocationGenerator(EDIT_TAG);

export const { LIST: LOG_OBJECTS, DETAILS: LOG_OBJECT } =
  createDataStoreSubresourcePaths(LOG, "objects", "key");

export interface LogObjectsLocationQuery {
  directory?: string;
  processing?: boolean;
}

export function makeLogObjectsLocation(
  logId: Log["id"],
  query?: LogObjectsLocationQuery,
): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(LOG_OBJECTS, {
        dataStoreName: dataStore.name,
        logId,
      }),
      search: makeSearchString(query),
    };
  };
}

export const UPLOAD_LOG_OBJECT = `${LOG_OBJECTS}/upload` as const;

export const makeUploadLogObjectLocation =
  createDataStoreLocationGenerator(UPLOAD_LOG_OBJECT);

export function makeLogObjectLocation({
  logId,
  key,
}: DataStoreResourcePathParams<typeof LOG_OBJECT>): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(LOG_OBJECT, {
        dataStoreName: dataStore.name,
        logId,
        // Object keys can contain forward slashes. URI encoding is necessary
        // for react-router to match correctly
        key: encodeURIComponent(key),
      }),
    };
  };
}

export const useLogObjectParams = useParams as UseTypedParams<
  typeof LOG_OBJECT
>;

export const {
  LIST: LOG_QUERIES,
  NEW: NEW_LOG_QUERY,
  DETAILS: LOG_QUERY,
  EDIT: EDIT_LOG_QUERY,
} = createDataStoreSubresourcePaths(LOG, "queries", "queryId");

export const makeLogQueriesLocation =
  createDataStoreLocationGenerator(LOG_QUERIES);

export const makeNewLogQueryLocation =
  createDataStoreLocationGenerator(NEW_LOG_QUERY);

export const makeLogQueryLocation = createDataStoreLocationGenerator(LOG_QUERY);

export const useLogQueryParams = useParams as UseTypedParams<typeof LOG_QUERY>;

export const makeEditLogQueryLocation =
  createDataStoreLocationGenerator(EDIT_LOG_QUERY);

export const {
  LIST: INGESTIONS,
  makeListLocation: makeIngestionsLocation,
  NEW: NEW_INGESTION,
  makeNewLocation: makeNewIngestionLocation,
  DETAILS: INGESTION,
  makeDetailsLocation: makeIngestionLocation,
  EDIT: EDIT_INGESTION,
  makeEditLocation: makeEditIngestionLocation,
} = createDataStoreResourcePaths("ingestions", "ingestionId");

export const useIngestionParams = useParams as UseTypedParams<typeof INGESTION>;

export const {
  LIST: INGESTION_PARTS,
  NEW: NEW_INGESTION_PART,
  DETAILS: INGESTION_PART,
  EDIT: EDIT_INGESTION_PART,
} = createDataStoreSubresourcePaths(INGESTION, "parts", "ingestionPartId");

export const makeIngestionPartsLocation =
  createDataStoreLocationGenerator(INGESTION_PARTS);

export const makeNewIngestionPartLocation =
  createDataStoreLocationGenerator(NEW_INGESTION_PART);

export const makeIngestionPartLocation =
  createDataStoreLocationGenerator(INGESTION_PART);

export const useIngestionPartParams = useParams as UseTypedParams<
  typeof INGESTION_PART
>;

export const makeEditIngestionPartLocation =
  createDataStoreLocationGenerator(EDIT_INGESTION_PART);

export const {
  LIST: TOPICS,
  makeListLocation: makeTopicsLocation,
  NEW: NEW_TOPIC,
  makeNewLocation: makeNewTopicLocation,
  DETAILS: TOPIC,
  makeDetailsLocation: makeTopicLocation,
  EDIT: EDIT_TOPIC,
  makeEditLocation: makeEditTopicLocation,
} = createDataStoreResourcePaths("topics", "topicId");

export const useTopicParams = useParams as UseTypedParams<typeof TOPIC>;

export const {
  LIST: RECORDS,
  NEW: NEW_RECORD,
  DETAILS: RECORD,
  EDIT: EDIT_RECORD,
} = createDataStoreSubresourcePaths(TOPIC, "records", "timestamp");

export const makeRecordsLocation = createDataStoreLocationGenerator(RECORDS);

export const makeNewRecordLocation =
  createDataStoreLocationGenerator(NEW_RECORD);

export function makeRecordLocation({
  topicId,
  timestamp,
}: {
  topicId: Topic["id"];
  timestamp: Record["timestamp"];
}): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(RECORD, {
        dataStoreName: dataStore.name,
        topicId,
        timestamp: timestamp.toString(),
      }),
    };
  };
}

export function useRecordParams(): OverrideProperties<
  ParamsShape<typeof RECORD>,
  { timestamp: bigint }
> {
  const { dataStoreName, topicId, timestamp } = useParams() as ParamsShape<
    typeof RECORD
  >;

  return { dataStoreName, topicId, timestamp: BigInt(timestamp) };
}

export function makeEditRecordLocation({
  topicId,
  timestamp,
}: {
  topicId: Topic["id"];
  timestamp: Record["timestamp"];
}): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(EDIT_RECORD, {
        dataStoreName: dataStore.name,
        topicId,
        timestamp: String(timestamp),
      }),
    };
  };
}

export const {
  LIST: DIGESTIONS,
  makeListLocation: makeDigestionsLocation,
  NEW: NEW_DIGESTION,
  makeNewLocation: makeNewDigestionLocation,
  DETAILS: DIGESTION,
  EDIT: EDIT_DIGESTION,
  makeEditLocation: makeEditDigestionLocation,
} = createDataStoreResourcePaths("digestions", "digestionId");

export function makeDigestionLocation(
  params: DataStoreResourcePathParams<typeof DIGESTION>,
  query?: { directory?: string },
): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(DIGESTION, {
        dataStoreName: dataStore.name,
        ...params,
      }),
      search: makeSearchString(query),
    };
  };
}

export const useDigestionParams = useParams as UseTypedParams<typeof DIGESTION>;

export const {
  LIST: DIGESTIONS_TOPICS,
  NEW: NEW_DIGESTION_TOPIC,
  DETAILS: DIGESTION_TOPIC,
  EDIT: EDIT_DIGESTION_TOPIC,
} = createDataStoreSubresourcePaths(DIGESTION, "topics", "topicId");

export const makeDigestionTopicsLocation =
  createDataStoreLocationGenerator(DIGESTIONS_TOPICS);

export const makeNewDigestionTopicLocation =
  createDataStoreLocationGenerator(NEW_DIGESTION_TOPIC);

export const makeDigestionTopicLocation =
  createDataStoreLocationGenerator(DIGESTION_TOPIC);

export const useDigestionTopicParams = useParams as UseTypedParams<
  typeof DIGESTION_TOPIC
>;

export const makeEditDigestionTopicLocation =
  createDataStoreLocationGenerator(EDIT_DIGESTION_TOPIC);

export const {
  LIST: DIGESTION_PARTS,
  NEW: NEW_DIGESTION_PART,
  DETAILS: DIGESTION_PART,
  EDIT: EDIT_DIGESTION_PART,
} = createDataStoreSubresourcePaths(DIGESTION, "parts", "digestionPartId");

export const makeDigestionPartsLocation =
  createDataStoreLocationGenerator(DIGESTION_PARTS);

export const makeNewDigestionPartLocation =
  createDataStoreLocationGenerator(NEW_DIGESTION_PART);

export const makeDigestionPartLocation =
  createDataStoreLocationGenerator(DIGESTION_PART);

export const useDigestionPartParams = useParams as UseTypedParams<
  typeof DIGESTION_PART
>;

export const makeEditDigestionPartLocation =
  createDataStoreLocationGenerator(EDIT_DIGESTION_PART);

export const {
  LIST: USERS,
  makeListLocation: makeUsersLocation,
  NEW: NEW_USER,
  makeNewLocation: makeNewUserLocation,
  DETAILS: USER,
  makeDetailsLocation: makeUserLocation,
  EDIT: EDIT_USER,
  makeEditLocation: makeEditUserLocation,
} = createDataStoreResourcePaths("users", "userId");

export const useUserParams = useParams as UseTypedParams<typeof USER>;

export const {
  LIST: GROUPS,
  makeListLocation: makeGroupsLocation,
  NEW: NEW_GROUP,
  makeNewLocation: makeNewGroupLocation,
  DETAILS: GROUP,
  makeDetailsLocation: makeGroupLocation,
  EDIT: EDIT_GROUP,
  makeEditLocation: makeEditGroupLocation,
} = createDataStoreResourcePaths("groups", "groupId");

export const useGroupParams = useParams as UseTypedParams<typeof GROUP>;

export const {
  LIST: API_KEYS,
  makeListLocation: makeApiKeysLocation,
  NEW: NEW_API_KEY,
  makeNewLocation: makeNewApiKeyLocation,
  DETAILS: API_KEY,
  makeDetailsLocation: makeApiKeyLocation,
  EDIT: EDIT_API_KEY,
  makeEditLocation: makeEditApiKeyLocation,
} = createDataStoreResourcePaths("api-keys", "apiKeyId");

export const useApiKeyParams = useParams as UseTypedParams<typeof API_KEY>;

export const {
  LIST: ROLES,
  makeListLocation: makeRolesLocation,
  NEW: NEW_ROLE,
  makeNewLocation: makeNewRoleLocation,
  DETAILS: ROLE,
  makeDetailsLocation: makeRoleLocation,
  EDIT: EDIT_ROLE,
  makeEditLocation: makeEditRoleLocation,
} = createDataStoreResourcePaths("roles", "roleId");

export const useRoleParams = useParams as UseTypedParams<typeof ROLE>;

export const {
  LIST: WORKFLOWS,
  makeListLocation: makeWorkflowsLocation,
  NEW: NEW_WORKFLOW,
  makeNewLocation: makeNewWorkflowLocation,
  DETAILS: WORKFLOW,
  makeDetailsLocation: makeWorkflowLocation,
  EDIT: EDIT_WORKFLOW,
  makeEditLocation: makeEditWorkflowLocation,
} = createDataStoreResourcePaths("workflows", "workflowId");

export const useWorkflowParams = useParams as UseTypedParams<typeof WORKFLOW>;

export const {
  LIST: HOOKS,
  NEW: NEW_HOOK,
  DETAILS: HOOK,
  EDIT: EDIT_HOOK,
} = createDataStoreSubresourcePaths(WORKFLOW, "hooks", "hookId");

export const makeHooksLocation = createDataStoreLocationGenerator(HOOKS);

export const makeNewHookLocation = createDataStoreLocationGenerator(NEW_HOOK);

export const makeHookLocation = createDataStoreLocationGenerator(HOOK);

export const useHookParams = useParams as UseTypedParams<typeof HOOK>;

export const makeEditHookLocation = createDataStoreLocationGenerator(EDIT_HOOK);

export const {
  LIST: LABELS,
  makeListLocation: makeLabelsLocation,
  NEW: NEW_LABEL,
  makeNewLocation: makeNewLabelLocation,
  DETAILS: LABEL,
  makeDetailsLocation: makeLabelLocation,
  EDIT: EDIT_LABEL,
  makeEditLocation: makeEditLabelLocation,
} = createDataStoreResourcePaths("labels", "labelId");

export const useLabelParams = useParams as UseTypedParams<typeof LABEL>;

export const {
  LIST: OBJECT_STORES,
  makeListLocation: makeObjectStoresLocation,
  NEW: NEW_OBJECT_STORE,
  makeNewLocation: makeNewObjectStoreLocation,
  DETAILS: OBJECT_STORE,
  makeDetailsLocation: makeObjectStoreLocation,
  EDIT: EDIT_OBJECT_STORE,
  makeEditLocation: makeEditObjectStoreLocation,
} = createDataStoreResourcePaths("object-stores", "objectStoreId");

export const useObjectStoreParams = useParams as UseTypedParams<
  typeof OBJECT_STORE
>;

export const { LIST: OBJECT_STORE_OBJECTS, DETAILS: OBJECT_STORE_OBJECT } =
  createDataStoreSubresourcePaths(OBJECT_STORE, "objects", "objectKey");

interface ObjectStoreObjectsLocationQuery {
  directory?: string;
  processing?: boolean;
}

export function makeObjectStoreObjectsLocation(
  params: DataStoreResourcePathParams<typeof OBJECT_STORE_OBJECTS>,
  query?: ObjectStoreObjectsLocationQuery,
): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(OBJECT_STORE_OBJECTS, {
        dataStoreName: dataStore.name,
        ...params,
      }),
      search: makeSearchString(query),
    };
  };
}

export function makeObjectStoreObjectLocation(
  params: DataStoreResourcePathParams<typeof OBJECT_STORE_OBJECT>,
): DataStorePathGenerator {
  return (dataStore) => {
    return {
      pathname: generatePath(OBJECT_STORE_OBJECT, {
        dataStoreName: dataStore.name,
        ...params,
        // Object keys can contain forward slashes. URI encoding is necessary
        // for react-router to match correctly
        objectKey: encodeURIComponent(params.objectKey),
      }),
    };
  };
}

export const useObjectStoreObjectParams = useParams as UseTypedParams<
  typeof OBJECT_STORE_OBJECT
>;

function makeSearchString(query: any): string {
  if (query == null) {
    return "";
  }

  return serializeSearchParams({ params: query }).toString();
}
