import { CheckboxField, ObjectField, TextField } from "~/components/Form";
import {
  GroupSelectField,
  NewLqsResourceForm,
  useCreateLog,
  WorkflowSelect,
} from "~/lqs";
import { makeLogLocation } from "~/paths";
import { createLogSchema } from "../schemas";

export function LogCreateForm() {
  return (
    <NewLqsResourceForm
      schema={createLogSchema}
      defaultValues={{
        name: null,
        groupId: null,
        defaultWorkflowId: null,
        locked: false,
        note: null,
        context: null,
      }}
      resourceName="log"
      mutation={useCreateLog()}
      createDetailsLocation={(response) =>
        makeLogLocation({ logId: response.data.id })
      }
    >
      {(control) => (
        <>
          <TextField control={control} name="name" required />
          <GroupSelectField control={control} name="groupId" required />
          <WorkflowSelect control={control} name="defaultWorkflowId" />
          <CheckboxField control={control} name="locked" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </NewLqsResourceForm>
  );
}
