import React, { useEffect, useRef, useState } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import prettyMilliseconds from "pretty-ms";
import { Dl, renderDlGroup } from "~/components/DescriptionList";
import { QueryRenderer } from "~/components/QueryRenderer";
import { ErrorMessage } from "~/components/error-message";
import { ResourceInfo } from "~/components/resource-info";
import { BULLET_POINT } from "~/constants";
import { selectCountableListResponse } from "~/domain/common";
import { Timestamp } from "~/format";
import { useDebouncedValue, useSessionStorage } from "~/hooks";
import { SidebarHeader } from "~/layout";
import { nanosecondsToMilliseconds } from "~/lib/dates";
import { invariant } from "~/lib/invariant";
import type { Log } from "~/lqs";
import { useLogs } from "~/lqs";
import { makeLogLocation, useNormalizeDataStorePath } from "~/paths";
import { usePlayerConfig, usePlayerLog } from "../hooks";

type RefetchReason = "input" | "previous" | "next";

const limit = 10;

const SEARCH_QUERY_STORAGE_KEY = "log-drawer-search-query";
const SEARCH_OFFSET_STORAGE_KEY = "log-drawer-search-offset";

export default function LogSidebar() {
  const { logId, setLogId } = usePlayerConfig();
  const currentLogQuery = usePlayerLog();

  const normalizeDataStorePath = useNormalizeDataStorePath();

  const [refetchReason, setRefetchReason] = useState<RefetchReason | null>(
    null,
  );
  const searchHeaderRef = useRef<HTMLParagraphElement | null>(null);

  const [search, setSearch] = useSessionStorage(SEARCH_QUERY_STORAGE_KEY, "");
  const [offset, setOffset] = useSessionStorage(SEARCH_OFFSET_STORAGE_KEY, 0);
  const debouncedSearch = useDebouncedValue(search, 200);
  const searchQuery = useLogs(
    {
      nameLike: debouncedSearch,
      offset,
      limit,
      sort: "desc",
      order: "start_time",
      startTimeNull: false,
      endTimeNull: false,
      includeCount: true,
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      select: selectCountableListResponse,
    },
  );

  useEffect(
    function scrollToTopOfSearchOnPageChange() {
      if (
        // `refetchReason` will change immediately but `searchQuery.data` will
        // change later, so make sure the search header is only scrolled into
        // view when `searchQuery` is no longer refetching. Otherwise, it would
        // get scrolled into view twice.
        !searchQuery.isRefetching &&
        searchQuery.data !== undefined &&
        (refetchReason === "previous" || refetchReason === "next")
      ) {
        searchHeaderRef.current?.scrollIntoView(true);
      }
    },
    [searchQuery.isRefetching, searchQuery.data, refetchReason],
  );

  const isPaginationDisabled =
    !searchQuery.isSuccess || searchQuery.data.count === 0;

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setRefetchReason("input");

    setSearch(e.target.value);
    setOffset(0);
  }

  function createPaginationChangeHandler(direction: "previous" | "next") {
    return function handlePaginationChange() {
      setRefetchReason(direction);

      const change = direction === "previous" ? -limit : limit;
      setOffset(offset + change);
    };
  }

  function causedRefetch(reason: RefetchReason) {
    return searchQuery.isRefetching && refetchReason === reason;
  }

  return (
    <>
      <SidebarHeader title="Logs" />
      <Stack spacing={2}>
        <div>
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" component="p">
              Current Log
            </Typography>
          </Stack>
          {logId == null ? (
            <Typography paragraph>
              No log selected. Search for one below
            </Typography>
          ) : (
            <QueryRenderer
              query={currentLogQuery}
              loading={<Typography paragraph>Loading the log...</Typography>}
              error={<ErrorMessage>Error fetching log</ErrorMessage>}
              success={(log) => (
                <Dl
                  spacing={3}
                  sx={{
                    mt: 0,
                    "& > .MuiGrid-root:first-of-type": {
                      pt: 0,
                    },
                  }}
                >
                  {renderDlGroup(
                    "Name",
                    <ResourceInfo
                      name={log.name}
                      identifier={log.id}
                      to={normalizeDataStorePath(
                        makeLogLocation({ logId: log.id }),
                      )}
                    />,
                    { xs: 12 },
                  )}
                  {renderDlGroup(
                    "Start Time",
                    <Timestamp value={log.startTime} />,
                    { xs: 12 },
                  )}
                  {renderDlGroup(
                    "Duration",
                    log.startTime === null || log.endTime === null
                      ? "-"
                      : renderLogDuration(log),
                    { xs: 12 },
                  )}
                </Dl>
              )}
            />
          )}
        </div>
        <Divider />
        <div>
          <Typography
            ref={searchHeaderRef}
            variant="h6"
            component="p"
            gutterBottom
          >
            Search Logs
          </Typography>
          <Typography paragraph>
            Select a log from the search results below to play its records and
            create digestions of its topics
          </Typography>
          <TextField
            label="Search for logs"
            fullWidth
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: causedRefetch("input") ? (
                <CircularProgress size="1.5rem" />
              ) : undefined,
            }}
          />
          <QueryRenderer
            query={searchQuery}
            loading={<Typography sx={{ my: 2 }}>Fetching logs...</Typography>}
            error={
              <Box sx={{ my: 3 }}>
                <ErrorMessage>Error fetching logs</ErrorMessage>
              </Box>
            }
            success={(searchResult) => (
              <>
                <List>
                  {searchResult.count === 0 ? (
                    <ListItem>No logs matched the search</ListItem>
                  ) : (
                    searchResult.data.map((log) => {
                      const hasDefinedBounds =
                        log.startTime !== null && log.endTime !== null;

                      return (
                        <ListItem key={log.id} disablePadding>
                          <ListItemButton
                            selected={log.id === logId}
                            role={undefined}
                            onClick={() => setLogId(log.id)}
                          >
                            <ListItemText
                              disableTypography
                              secondary={
                                hasDefinedBounds ? (
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: "text.secondary",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {renderLogDuration(log)}
                                    {` ${BULLET_POINT} `}
                                    <Timestamp value={log.startTime} />
                                  </Typography>
                                ) : (
                                  "-"
                                )
                              }
                            >
                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                  width: 1,
                                  alignItems: "center",
                                  wordBreak: "break-all",
                                }}
                              >
                                <Typography sx={{ fontWeight: "bold" }}>
                                  {log.name}
                                </Typography>
                              </Stack>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      );
                    })
                  )}
                </List>
                <Stack direction="row" justifyContent="space-between">
                  <LoadingButton
                    loading={causedRefetch("previous")}
                    startIcon={<ChevronLeft />}
                    disabled={isPaginationDisabled || offset === 0}
                    onClick={createPaginationChangeHandler("previous")}
                  >
                    Previous
                  </LoadingButton>
                  <LoadingButton
                    loading={causedRefetch("next")}
                    endIcon={<ChevronRight />}
                    disabled={
                      isPaginationDisabled ||
                      offset + limit >= searchQuery.data.count
                    }
                    onClick={createPaginationChangeHandler("next")}
                  >
                    Next
                  </LoadingButton>
                </Stack>
              </>
            )}
          />
        </div>
      </Stack>
    </>
  );
}

function renderLogDuration(log: Log) {
  const { startTime, endTime } = log;

  invariant(
    startTime !== null && endTime !== null,
    "Log must have defined bounds to render duration",
  );

  return prettyMilliseconds(nanosecondsToMilliseconds(endTime - startTime), {
    secondsDecimalDigits: 0,
  });
}
