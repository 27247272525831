import React, { useState } from "react";
import {
  Error as ErrorIcon,
  FileDownload,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Tune,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Breadcrumbs,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import type {
  InfiniteData,
  UseInfiniteQueryOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import type { Path } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { z } from "zod";
import { createSafeContext } from "~/contexts";
import { boolean, deserializeBooleanParam, filterText } from "~/domain/common";
import { useSidebarTrigger } from "~/layout";
import { invariant } from "~/lib/invariant";
import { isEqual } from "~/lib/std";
import type {
  CloudObject,
  ListObjectsRequest,
  ObjectDataResponse,
  ObjectListResponse,
} from "~/lqs";
import type { DataStorePathGenerator } from "~/paths";
import { DataStoreLink } from "~/paths";
import { pluralize, serializeSearchParams } from "~/utils";
import { Card } from "./Card";
import { Center } from "./Center";
import { CheckboxField, TextField, useStudioForm } from "./Form";
import { Loading } from "./Loading";
import { QueryRenderer } from "./QueryRenderer";
import type { Column } from "./Table";
import {
  EmbeddedContext,
  useSearchRequest,
  useEmbedded,
  FiltersSidebar,
  normalizeColumn,
} from "./Table";
import { ErrorMessage } from "./error-message";

const defaultBoolean = z.preprocess(
  // Neither `null` nor `undefined` are allowed but since this is parsing
  // search params those values need to be defaulted rather than rejected
  (arg) => deserializeBooleanParam(arg) ?? false,
  boolean,
);

const listObjectsSchema = z.object({
  directory: filterText,
  prefix: filterText,
  processing: defaultBoolean,
});

export const OBJECT_KEY_DELIMITER = "/";

function EmbeddedDownloadLink({
  cloudObject,
  createObjectQueryOptions,
}: { cloudObject: CloudObject } & Pick<
  ObjectExplorerProps,
  "createObjectQueryOptions"
>) {
  const query = useQuery({
    ...createObjectQueryOptions(cloudObject.key),
    select({ data: { presignedUrl } }) {
      invariant(presignedUrl !== null, "Expected presigned URL to be defined");

      return presignedUrl;
    },
  });

  let tooltipTitle: string;
  switch (query.status) {
    case "loading": {
      tooltipTitle = "Preparing download";
      break;
    }
    case "error": {
      tooltipTitle = "Error preparing download";
      break;
    }
    case "success": {
      tooltipTitle = "Download object";
      break;
    }
  }

  const href = query.data;

  return (
    <Tooltip title={tooltipTitle}>
      <Box component="span" sx={{ ml: 2 }}>
        <IconButton
          size="small"
          {...(href == null ? { disabled: true } : { href })}
        >
          <FileDownload fontSize="small" />
        </IconButton>
      </Box>
    </Tooltip>
  );
}

function ObjectLinkCell({
  cloudObject,
  createObjectQueryOptions,
  createObjectLocation,
}: {
  cloudObject: CloudObject;
} & Pick<
  ObjectExplorerProps,
  "createObjectQueryOptions" | "createObjectLocation"
>) {
  const embedded = useEmbedded();

  let downloadLink = null;
  if (embedded) {
    downloadLink = (
      <EmbeddedDownloadLink
        cloudObject={cloudObject}
        createObjectQueryOptions={createObjectQueryOptions}
      />
    );
  }

  return (
    <TableCell>
      <Link
        component={DataStoreLink}
        to={createObjectLocation(cloudObject.key)}
      >
        {formatObjectKey(cloudObject.key)}
      </Link>
      {downloadLink}
    </TableCell>
  );
}

const [useObjectSearchRequest, ObjectSearchRequestContext] = createSafeContext<
  ReturnType<typeof useSearchRequest<z.infer<typeof listObjectsSchema>>>
>("ObjectSearchRequest");

export function ObjectSearchRequestProvider({
  embedded = false,
  children,
}: {
  embedded?: boolean;
  children: React.ReactNode;
}) {
  return (
    <EmbeddedContext.Provider value={embedded}>
      <ObjectSearchRequestContext.Provider
        value={useSearchRequest(listObjectsSchema)}
      >
        {children}
      </ObjectSearchRequestContext.Provider>
    </EmbeddedContext.Provider>
  );
}

function useObjectsSearch({
  createSearchQueryOptions,
}: Pick<ObjectExplorerProps, "createSearchQueryOptions">) {
  const embedded = useEmbedded();

  const [request] = useObjectSearchRequest();

  const searchQuery = useInfiniteQuery({
    ...createSearchQueryOptions({
      delimiter: OBJECT_KEY_DELIMITER,
      prefix: `${request.directory ?? ""}${request.prefix ?? ""}` || null,
      processing: request.processing,
      maxKeys: embedded ? 10 : 100,
    }),
    cacheTime: 0,
    keepPreviousData: true,
    getNextPageParam(lastPage) {
      // react-query would interpret a `null` continuation token to mean
      // there are more pages, so `null` needs to be defaulted to `undefined`
      return lastPage.nextContinuationToken ?? undefined;
    },
  });

  const [pagination, setPagination] = useState({
    previousRequest: request,
    page: 0,
  });

  // Pagination should be reset whenever the filters change. However, pagination
  // can't be stored directly with those filters since it shouldn't be a search
  // param. Consequently, setting state during render seems like the only choice
  // to perform this reset. The calling component can return early if this
  // variable indicates state was set during render.
  //
  // Note: Using a `key` prop based on the request parameters on a wrapper
  // component didn't work. There were various UI inconsistencies caused by
  // remounting essentially the entire page when a filter changed.
  let _didQueuePaginationReset = false;
  if (!isEqual(pagination.previousRequest, request)) {
    _didQueuePaginationReset = true;
    setPagination({ previousRequest: request, page: 0 });
  }

  const { page } = pagination;

  const previousPageEnabled = !searchQuery.isFetching && page > 0;
  const nextPageEnabled =
    searchQuery.isSuccess &&
    !searchQuery.isFetching &&
    // The `hasNextPage` field is only useful when the user is on the last
    // page of the table. If they're on a prior page, that implies a
    // subsequent page has been fetched before so they can definitely go
    // to the next page.
    (page === searchQuery.data.pages.length - 1
      ? searchQuery.hasNextPage
      : true);

  return {
    _didQueuePaginationReset,
    searchQuery,
    request,
    previousPageDisabled: !previousPageEnabled,
    toPreviousPage() {
      invariant(previousPageEnabled, "Cannot go to previous page");

      setPagination({ ...pagination, page: page - 1 });
    },
    nextPageDisabled: !nextPageEnabled,
    toNextPage() {
      invariant(nextPageEnabled, "Cannot go to next page");

      const nextPage = page + 1;

      setPagination({ ...pagination, page: nextPage });

      if (nextPage === searchQuery.data.pageParams.length) {
        searchQuery.fetchNextPage();
      }
    },
    getCurrentPageResponse(data: InfiniteData<ObjectListResponse>) {
      // Need to access the current page based on the `page` state variable.
      // However, if incrementing `page` led to the next page being fetched,
      // then there won't be a response at `page`'s index until the fetch
      // succeeds. In that situation the prior page should be shown instead
      if (searchQuery.isFetchingNextPage && page === data.pages.length) {
        return data.pages.at(-1)!;
      } else {
        const response = data.pages.at(page);

        invariant(response !== undefined, `No page found at index ${page}`);

        return response;
      }
    },
  };
}

interface ObjectExplorerProps {
  homeName: string;
  toolbarAction?: React.ReactNode;
  createObjectQueryOptions: (
    objectKey: CloudObject["key"],
  ) => Pick<UseQueryOptions<ObjectDataResponse>, "queryKey" | "queryFn">;
  createSearchQueryOptions: (
    request: Pick<
      ListObjectsRequest,
      "delimiter" | "prefix" | "processing" | "maxKeys"
    >,
  ) => Pick<
    UseInfiniteQueryOptions<ObjectListResponse>,
    "queryKey" | "queryFn"
  >;
  createObjectLocation: (
    objectKey: CloudObject["key"],
  ) => DataStorePathGenerator;
  prepareDirectorySearchParam?: (directory: string) => string;
}

export function ObjectExplorer({
  homeName,
  createObjectQueryOptions,
  toolbarAction,
  createSearchQueryOptions,
  createObjectLocation,
  prepareDirectorySearchParam,
}: ObjectExplorerProps) {
  const embedded = useEmbedded();

  const objectsSearch = useObjectsSearch({ createSearchQueryOptions });

  const toggleFiltersSidebar = useSidebarTrigger("filters");

  if (objectsSearch._didQueuePaginationReset) {
    return null;
  }

  const columns: Array<Column<CloudObject>> = [
    {
      header: "Key",
      renderCell(cloudObject) {
        return (
          <ObjectLinkCell
            createObjectQueryOptions={createObjectQueryOptions}
            cloudObject={cloudObject}
            createObjectLocation={createObjectLocation}
          />
        );
      },
    },
    {
      accessor: "size",
      dataType: "bytes",
    },
    {
      accessor: "lastModified",
      dataType: "datetime",
    },
  ];
  // These columns should only be included when not embedded
  if (!embedded) {
    columns.splice(1, 0, {
      accessor: "etag",
      dataType: "text",
    });
    columns.push({
      accessor: "uploadState",
      dataType: "text",
    });
  }

  const normalizedColumns = columns.map((column) => normalizeColumn(column));

  const { searchQuery } = objectsSearch;

  function renderToolbar() {
    if (embedded) {
      return null;
    }

    let toolbarMessage;
    if (searchQuery.isLoading) {
      toolbarMessage = <Typography>Fetching objects...</Typography>;
    } else if (searchQuery.isError) {
      toolbarMessage = (
        <Stack direction="row" alignItems="center" spacing={1}>
          <ErrorIcon color="error" />
          <Typography>Unable to perform search</Typography>
        </Stack>
      );
    } else if (searchQuery.isRefetching) {
      toolbarMessage = <Typography>Searching...</Typography>;
    } else {
      toolbarMessage = (
        <Typography>
          {pluralize(
            objectsSearch.getCurrentPageResponse(searchQuery.data).keyCount ??
              0,
            "object",
          )}
        </Typography>
      );
    }

    return (
      <Stack direction="row" sx={{ alignItems: "center" }}>
        <Tooltip title="Filters" sx={{ mr: 1 }}>
          <Badge badgeContent={0} color="primary">
            <IconButton size="small" onClick={toggleFiltersSidebar}>
              <Tune fontSize="small" />
            </IconButton>
          </Badge>
        </Tooltip>
        {toolbarMessage}
        {toolbarAction !== undefined && (
          <Box sx={{ ml: "auto" }}>{toolbarAction}</Box>
        )}
      </Stack>
    );
  }

  function renderDirectoryBreadcrumbs() {
    const {
      request: { directory },
    } = objectsSearch;

    if (directory === null) {
      return <Typography>{homeName}</Typography>;
    }

    let segments = directory.split(OBJECT_KEY_DELIMITER);
    if (segments.at(-1) === "") {
      segments = segments.slice(0, -1);
    }

    return (
      <Breadcrumbs>
        <Link
          component={RouterLink}
          to={createDirectoryPath({
            processing: objectsSearch.request.processing,
          })}
          replace={embedded}
        >
          {homeName}
        </Link>
        {segments.map((segment, index, segments) => {
          if (index === segments.length - 1) {
            return <Typography key={segment}>{segment}</Typography>;
          } else {
            const directory = `${segments
              .slice(0, index + 1)
              .join(OBJECT_KEY_DELIMITER)}${OBJECT_KEY_DELIMITER}`;

            return (
              <Link
                key={segment}
                component={RouterLink}
                to={createDirectoryPath({
                  directory:
                    prepareDirectorySearchParam?.(directory) ?? directory,
                  processing: objectsSearch.request.processing,
                })}
                replace={embedded}
              >
                {segment}
              </Link>
            );
          }
        })}
      </Breadcrumbs>
    );
  }

  // Don't wrap in a `<Card />` when embedded as it's assumed the embedding
  // component will be rendering some form of container
  const Root = embedded ? React.Fragment : Card;

  return (
    <Root>
      <Stack spacing={2}>
        {renderToolbar()}
        {renderDirectoryBreadcrumbs()}
        <Box position="relative">
          <Divider />
          {(searchQuery.isRefetching || searchQuery.isFetchingNextPage) && (
            <LinearProgress
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
              }}
            />
          )}
        </Box>
      </Stack>
      <QueryRenderer
        query={searchQuery}
        loading={
          <Box sx={{ py: 5 }}>
            <Loading type="circular" />
          </Box>
        }
        error={
          <Box sx={{ py: 5 }}>
            <ErrorMessage>An error occurred searching for objects</ErrorMessage>
          </Box>
        }
        success={(data) => {
          const response = objectsSearch.getCurrentPageResponse(data);

          if (
            (response.commonPrefixes === null ||
              response.commonPrefixes.length === 0) &&
            response.data.length === 0
          ) {
            return (
              <Center sx={{ py: 5 }}>
                <Typography variant="h4" component="p">
                  The search returned 0 results
                </Typography>
              </Center>
            );
          } else {
            const sortedPrefixes = [...(response.commonPrefixes ?? [])].sort(
              (prefix1, prefix2) =>
                formatCommonPrefix(prefix1).localeCompare(
                  formatCommonPrefix(prefix2),
                ),
            );
            const sortedObjects = [...response.data].sort((object1, object2) =>
              formatObjectKey(object1.key).localeCompare(
                formatObjectKey(object2.key),
              ),
            );
            // All common prefixes come before all objects
            const sortedRows = [...sortedPrefixes, ...sortedObjects];

            return (
              <TableContainer
                sx={{
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  ...(embedded && {
                    maxHeight: 350,
                    overflowY: "auto",
                  }),
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          bgcolor: (theme) =>
                            theme.palette.mode === "dark"
                              ? "grey.800"
                              : "grey.300",
                          borderBottom: "unset",
                        },
                      }}
                    >
                      {normalizedColumns.map((column) => (
                        <TableCell key={column.header} align={column.align}>
                          {column.header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedRows.map((row) => (
                      <TableRow
                        key={typeof row === "string" ? row : row.key}
                        sx={{
                          // Remove bottom border for table cells in last
                          // row
                          [`&:last-of-type .${tableCellClasses.root}`]: {
                            borderBottom: "unset",
                          },
                        }}
                      >
                        {typeof row === "string" ? (
                          <TableCell colSpan={normalizedColumns.length}>
                            <Link
                              component={RouterLink}
                              to={createDirectoryPath({
                                directory:
                                  prepareDirectorySearchParam?.(row) ?? row,
                                processing: objectsSearch.request.processing,
                              })}
                              replace={embedded}
                            >
                              {formatCommonPrefix(row)}
                            </Link>
                          </TableCell>
                        ) : (
                          normalizedColumns.map((column) => (
                            <React.Fragment key={column.header}>
                              {column.renderCell(row, {
                                align: column.align,
                              })}
                            </React.Fragment>
                          ))
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            );
          }
        }}
      />
      <Divider sx={{ mb: 2 }} />
      <Stack direction="row" justifyContent="end" spacing={1}>
        <Tooltip title="Previous page">
          <span>
            <IconButton
              disabled={objectsSearch.previousPageDisabled}
              onClick={objectsSearch.toPreviousPage}
            >
              <KeyboardArrowLeft />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Next page">
          <span>
            <IconButton
              disabled={objectsSearch.nextPageDisabled}
              onClick={objectsSearch.toNextPage}
            >
              <KeyboardArrowRight />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    </Root>
  );
}

export function ObjectExplorerFilters() {
  const [request, setRequest] = useObjectSearchRequest();

  const { control, handleSubmit } = useStudioForm({
    schema: listObjectsSchema,
    values: request,
    onSubmit: setRequest,
  });

  return (
    <FiltersSidebar handleSubmit={handleSubmit}>
      <TextField control={control} name="prefix" />
      <CheckboxField control={control} name="processing" />
    </FiltersSidebar>
  );
}

function formatObjectKey(key: CloudObject["key"]): string {
  return key.slice(key.lastIndexOf(OBJECT_KEY_DELIMITER) + 1);
}

function formatCommonPrefix(commonPrefix: string): string {
  return commonPrefix.slice(
    commonPrefix.lastIndexOf(
      OBJECT_KEY_DELIMITER,
      // Common prefixes end with the delimiter so the search needs to start
      // from the index directly before that
      commonPrefix.length - 2,
    ) + 1,
  );
}

function createDirectoryPath(searchParams: {
  directory?: string;
  processing?: boolean;
}): Partial<Path> {
  const searchString = serializeSearchParams({
    params: searchParams,
  }).toString();

  return {
    search: searchString,
  };
}
