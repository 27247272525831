import {
  ObjectExplorer,
  ObjectExplorerFilters as ObjectStoreObjectFilters,
  ObjectSearchRequestProvider as ObjectStoreObjectSearchRequestProvider,
} from "~/components/ObjectExplorer";
import {
  useObjectStoreObjectQueryOptionsFactory,
  useObjectStoreObjectsQueryOptionsFactory,
} from "~/lqs";
import { makeObjectStoreObjectLocation, useObjectStoreParams } from "~/paths";

export { ObjectStoreObjectSearchRequestProvider, ObjectStoreObjectFilters };

export function ObjectStoreObjectTable() {
  const { objectStoreId } = useObjectStoreParams();

  const createObjectStoreObjectQueryOptions =
    useObjectStoreObjectQueryOptionsFactory();
  const createObjectStoreObjectsQueryOptions =
    useObjectStoreObjectsQueryOptionsFactory();

  return (
    <ObjectExplorer
      homeName={objectStoreId}
      createObjectQueryOptions={createObjectStoreObjectQueryOptions.bind(
        null,
        objectStoreId,
      )}
      createSearchQueryOptions={createObjectStoreObjectsQueryOptions.bind(
        null,
        objectStoreId,
      )}
      createObjectLocation={(objectKey) =>
        makeObjectStoreObjectLocation({
          objectStoreId,
          objectKey,
        })
      }
    />
  );
}
