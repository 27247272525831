import { get } from "~/lib/std";
import type { LayoutProfile } from "../panels";
import { useDataStoreLayoutProfiles } from "../panels";
import { usePlayerLog } from "./usePlayerLog";

export function useInitialLayoutProfile(): LayoutProfile | undefined {
  const dataStoreLayoutProfiles = useDataStoreLayoutProfiles();

  const logQuery = usePlayerLog();

  if (!logQuery.isSuccess) {
    return;
  }

  const defaultProfileName = get(
    logQuery.data.context,
    "studio.default_layout_profile",
  );

  return dataStoreLayoutProfiles.find(
    (layoutProfile) => layoutProfile.name === defaultProfileName,
  );
}
