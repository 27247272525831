import React from "react";
import { Settings } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  Container,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Center } from "~/components/Center";
import { Helmet } from "~/components/Helmet";
import { QueryRenderer } from "~/components/QueryRenderer";
import { ErrorMessage } from "~/components/error-message";
import { DsmNavigation, MigrationAnnouncement, useDataStores } from "~/dsm";
import * as Layout from "~/layout";
import {
  LayoutStateProvider,
  ScrollableContainer,
  SidebarSwitch,
  SidebarTrigger,
} from "~/layout";
import type * as paths from "~/paths";
import { makeDataStoreDashboardLocation } from "~/paths";
import { SettingsSidebar } from "~/settings";
import type { Maybe } from "~/types";
import { selectData } from "~/utils";

export function StudioHomepage() {
  const dataStoresQuery = useDataStores(
    {
      limit: 100,
      sort: "asc",
      order: "name",
    },
    { select: selectData },
  );

  const location = useLocation();

  function renderRedirectStateAlert() {
    const locationState = location.state as Maybe<paths.StudioHomepageState>;

    if (locationState == null) {
      return null;
    }

    let children = undefined;

    if (locationState.unknownDataStore !== undefined) {
      children = (
        <>
          <AlertTitle>Error Connecting to DataStore</AlertTitle>
          <Typography fontSize="inherit" paragraph>
            You do not have access to the following DataStore:
          </Typography>
          <pre>{locationState.unknownDataStore}</pre>
          <Typography fontSize="inherit">
            If you expect to have access, you will need to contact an
            administrator.
          </Typography>
        </>
      );
    }

    return (
      <Grid item xs={12}>
        <Alert
          severity="error"
          variant="filled"
          sx={{
            "& .MuiAlert-message": {
              flexGrow: 1,
            },
            "& pre": {
              borderRadius: 1,
              bgcolor: "grey.400",
              p: 0.75,
            },
          }}
        >
          {children}
        </Alert>
      </Grid>
    );
  }

  return (
    <>
      <Helmet>
        <title>Your DataStores</title>
      </Helmet>
      <LayoutStateProvider>
        <Layout.Root>
          <Layout.Navigation>
            <DsmNavigation />
          </Layout.Navigation>
          <Layout.Header
            title="Your DataStores"
            actions={
              <SidebarTrigger
                sidebarId="settings"
                title="Settings"
                icon={<Settings />}
              />
            }
          />
          <Layout.Main>
            <ScrollableContainer>
              <Container fixed>
                <MigrationAnnouncement />
                <Typography variant="h6" component="p" paragraph>
                  Connect to one of your DataStores to use Studio.
                </Typography>
                <Grid container spacing={4}>
                  {renderRedirectStateAlert()}
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <QueryRenderer
                          query={dataStoresQuery}
                          loading={<Skeleton variant="rounded" height={78} />}
                          error={
                            <ErrorMessage>
                              Unable to load your DataStores
                            </ErrorMessage>
                          }
                          success={(dataStores) => {
                            if (dataStores.length === 0) {
                              return (
                                <Center>
                                  <Typography variant="h6" component="p">
                                    You don't have access to any DataStores
                                  </Typography>
                                </Center>
                              );
                            } else {
                              return (
                                <List
                                  disablePadding
                                  sx={{
                                    "& .MuiListItem-root": {
                                      flexWrap: "wrap",
                                    },
                                  }}
                                >
                                  {dataStores.map((dataStore) => (
                                    <ListItem key={dataStore.id} disablePadding>
                                      <ListItemButton
                                        component={RouterLink}
                                        to={makeDataStoreDashboardLocation()(
                                          dataStore,
                                        )}
                                      >
                                        <ListItemText
                                          primaryTypographyProps={{
                                            variant: "h5",
                                            component: "p",
                                            sx: {
                                              mb: 1,
                                            },
                                          }}
                                          primary={
                                            <>
                                              <span>{dataStore.name}</span>
                                              {dataStore.name !== null && (
                                                <Typography
                                                  variant="body1"
                                                  component="span"
                                                  color="text.secondary"
                                                  sx={{
                                                    fontStyle: "italic",
                                                    ml: 1.5,
                                                  }}
                                                >
                                                  {dataStore.note}
                                                </Typography>
                                              )}
                                            </>
                                          }
                                          secondary={dataStore.id}
                                        />
                                      </ListItemButton>
                                    </ListItem>
                                  ))}
                                </List>
                              );
                            }
                          }}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Container>
            </ScrollableContainer>
          </Layout.Main>
          <Layout.Sidebar>
            <SidebarSwitch
              options={[
                {
                  sidebarId: "settings",
                  element: <SettingsSidebar />,
                },
              ]}
            />
          </Layout.Sidebar>
        </Layout.Root>
      </LayoutStateProvider>
    </>
  );
}
