import React from "react";
import { DoNotDisturb } from "@mui/icons-material";
import { dividerClasses, Stack } from "@mui/material";
import { Loading } from "~/components/Loading";
import { ErrorMessage } from "~/components/error-message";
import { SidebarHeader } from "~/layout";
import { invariant } from "~/lib/invariant";
import { TagsControl } from "../components/tags-control";
import { TopicName } from "../components/topic-name";
import { usePlayerTopics } from "../hooks";
import { useControlledPanelContext } from "../layout";
import type { InitializedPanelNode } from "../panels";
import {
  flattenPanels,
  usePanelLayoutContext,
  VisualizationType,
} from "../panels";
import { usePlaybackSource } from "../playback";
import { ImageControls, ThreeDVisualizationControls } from "../visualizations";

export function PanelControlsSidebar() {
  const controlledPanelContext = useControlledPanelContext();

  const { layout } = usePanelLayoutContext();

  const controlledPanel = flattenPanels(layout).find(
    (panel): panel is InitializedPanelNode =>
      panel.isInitialized && panel.id === controlledPanelContext.panelId,
  );
  invariant(controlledPanel != null, "Expected to find panel");

  const playbackSource = usePlaybackSource();

  const playerTopicsQuery = usePlayerTopics();

  let content;
  if (playbackSource.isLoading || playerTopicsQuery.isLoading) {
    content = <Loading type="circular" />;
  } else if (playerTopicsQuery.isError) {
    content = <ErrorMessage>Error loading topics</ErrorMessage>;
  } else {
    const topic = playerTopicsQuery.data.find(
      (topic) => topic.name === controlledPanel.topicName,
    );

    if (topic === undefined) {
      content = (
        <ErrorMessage icon={<DoNotDisturb fontSize="large" color="error" />}>
          <TopicName monospace>{controlledPanel.topicName}</TopicName> is not in
          this log
        </ErrorMessage>
      );
    } else {
      switch (controlledPanel.visualization) {
        case VisualizationType.ThreeD: {
          content = (
            <ThreeDVisualizationControls
              panel={controlledPanel}
              topic={topic}
            />
          );
          break;
        }
        case VisualizationType.Image: {
          content = (
            <ImageControls
              panel={controlledPanel}
              topic={topic}
              playerTopics={playerTopicsQuery.data}
            />
          );
          break;
        }
        default: {
          content = <TagsControl topic={topic} />;
          break;
        }
      }
    }
  }

  return (
    <>
      <SidebarHeader title="Panel Controls" />
      <Stack
        sx={{
          [`& .${dividerClasses.root}`]: {
            my: 2,
          },
        }}
      >
        {content}
      </Stack>
    </>
  );
}
