import { Stack, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Card } from "~/components/Card";
import { DetailsLayout, RelatedResource } from "~/components/DetailsCards";
import {
  CheckboxField,
  Form,
  FormSkeleton,
  getChangedFields,
  ObjectField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { QueryRenderer } from "~/components/QueryRenderer";
import { pick } from "~/lib/std";
import type { ObjectStore } from "~/lqs";
import {
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteObjectStore,
  useObjectStore,
  useUpdateObjectStore,
} from "~/lqs";
import {
  makeEditObjectStoreLocation,
  makeObjectStoreObjectsLocation,
  makeObjectStoresLocation,
  useObjectStoreParams,
} from "~/paths";
import { selectData } from "~/utils";
import { editObjectStoreSchema } from "../schemas";
import {
  ObjectStoreObjectSearchRequestProvider,
  ObjectStoreObjectTable,
} from "./ObjectStoreObjectTable";

export function ObjectStoreDetails() {
  const { objectStoreId } = useObjectStoreParams();

  const query = useObjectStore(objectStoreId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const relatedResourcesSection = (
    <Stack spacing={2}>
      <Typography variant="h4" component="h2">
        Related Resources
      </Typography>
      <RelatedResource
        text="Objects"
        to={makeObjectStoreObjectsLocation({ objectStoreId })}
        table={
          <ObjectStoreObjectSearchRequestProvider embedded>
            <ObjectStoreObjectTable />
          </ObjectStoreObjectSearchRequestProvider>
        }
      />
    </Stack>
  );
  const infoSection = <InfoSection query={query} />;
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="object store"
      query={query}
      editLocation={makeEditObjectStoreLocation({ objectStoreId })}
      deleteMutation={useDeleteObjectStore(objectStoreId)}
      getReadableName={(objectStore) => objectStore.bucketName}
      listLocation={makeObjectStoresLocation()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {relatedResourcesSection}
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {historySection}
          {manageSection}
          {relatedResourcesSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<ObjectStore> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "text", accessor: "bucketName" },
          { dataType: "text", accessor: "accessKeyId" },
          { dataType: "text", accessor: "regionName" },
          { dataType: "text", accessor: "endpointUrl" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<ObjectStore> }) {
  return (
    <Card>
      <QueryRenderer
        query={query}
        loading={<FormSkeleton shapes={["multiline", "multiline", "text"]} />}
        success={(objectStore) => <InfoSectionImpl objectStore={objectStore} />}
      />
    </Card>
  );
}

function InfoSectionImpl({ objectStore }: { objectStore: ObjectStore }) {
  const updateObjectStore = useUpdateObjectStore(objectStore.id);

  const { enqueueSnackbar } = useSnackbar();

  const schema = editObjectStoreSchema.pick({
    note: true,
    context: true,
    disabled: true,
  });
  const FIELDS = schema.keyof().options;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useStudioForm({
    schema,
    defaultValues: pick(objectStore, FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateObjectStore.mutate(changedFields, {
        onSuccess(response) {
          enqueueSnackbar("Object store updated", { variant: "success" });

          reset(pick(response.data, FIELDS));
        },
        onError() {
          enqueueSnackbar("Unable to update object store", {
            variant: "error",
          });
        },
      });
    },
  });

  return (
    <Form
      onSubmit={handleSubmit}
      loading={updateObjectStore.isLoading}
      submitText="Save Changes"
    >
      <TextField control={control} name="note" multiline />
      <ObjectField control={control} name="context" />
      <CheckboxField control={control} name="disabled" />
    </Form>
  );
}
