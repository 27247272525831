import type { Topic } from "~/lqs";
import type { BasicRecordsSnapshot } from "../../record-store";
import { useRecords } from "../../record-store";

export function useThreeDRecords(topic: Topic): {
  snapshot: BasicRecordsSnapshot<"threeD">;
  isPlaceholder: boolean;
} {
  return useRecords({
    recordType: "threeD",
    topic,
    count: 30,
    limit: 10,
    prefetchBehind: 10,
    prefetchAhead: 90,
  });
}
