import { CheckboxField, ObjectField, TextField } from "~/components/Form";
import {
  EditLqsResourceForm,
  useGroup,
  useUpdateGroup,
  WorkflowSelect,
} from "~/lqs";
import { makeGroupLocation, useGroupParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_GROUP_FIELDS, editGroupSchema } from "../schemas";

export function GroupEditForm() {
  const { groupId } = useGroupParams();

  return (
    <EditLqsResourceForm
      schema={editGroupSchema}
      resourceName="group"
      query={useGroup(groupId, { select: selectData })}
      editableFields={EDITABLE_GROUP_FIELDS}
      mutation={useUpdateGroup(groupId)}
      detailsLocation={makeGroupLocation({ groupId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="name" required />
          <WorkflowSelect control={control} name="defaultWorkflowId" />
          <CheckboxField control={control} name="locked" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditLqsResourceForm>
  );
}
