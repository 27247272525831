import { TablePage } from "~/components/Table";
import { LqsNavigation } from "~/lqs";
import { IngestionFilters } from "./components/IngestionFilters";
import {
  IngestionSearchRequestProvider,
  IngestionTable,
} from "./components/IngestionTable";

export function IngestionsPage() {
  return (
    <TablePage
      title="Search Ingestions"
      navigation={<LqsNavigation />}
      RequestProvider={IngestionSearchRequestProvider}
      table={<IngestionTable />}
      filters={<IngestionFilters />}
    />
  );
}
