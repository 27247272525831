import React from "react";
import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  useTheme,
} from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import type { DataStorePathGenerator } from "~/paths";
import { Loading } from "../Loading";
import { QueryRenderer } from "../QueryRenderer";
import type { ForeignResourceRenderer } from "../Table";
import { ErrorMessage } from "../error-message";
import { DetailsToolbar } from "./DetailsToolbar";
import { renderField } from "./fields";
import type { ResourceField } from "./types";

export interface DetailsCardsProps<
  TResource extends object,
  TForeignResources extends string,
> {
  startActions?: React.ReactNode;
  editLocation?: DataStorePathGenerator;
  onDelete?: VoidFunction;
  query: UseQueryResult<TResource>;
  fields: ReadonlyArray<ResourceField<NoInfer<TResource>, TForeignResources>>;
  renderForeignResource: ForeignResourceRenderer<TForeignResources>;
}

export function DetailsCards<
  TResource extends object,
  TForeignResources extends string,
>({
  startActions,
  editLocation,
  onDelete,
  query,
  fields,
  renderForeignResource,
}: DetailsCardsProps<TResource, TForeignResources>) {
  const theme = useTheme();

  return (
    <Card>
      <CardContent>
        <DetailsToolbar
          query={query}
          startActions={startActions}
          editLocation={editLocation}
          onInitiateDelete={onDelete}
        />
        <LinearProgress
          sx={{
            my: 2,
            // Will always take up the same amount of space but won't be
            // visible unless refetch is happening. Prevents a layout shift
            // when refetching starts or ends
            visibility: query.isRefetching ? "visible" : "hidden",
          }}
        />
        <QueryRenderer
          query={query}
          loading={<Loading type="circular" />}
          error={<ErrorMessage>Error fetching details</ErrorMessage>}
          success={(resource) => (
            <Box
              component="dl"
              sx={{
                mx: 4,
                [theme.breakpoints.up("md")]: {
                  display: "grid",
                  gridTemplateColumns: "minmax(0, 1fr) minmax(0, 3fr)",
                  columnGap: 6,
                  "& dt": {
                    justifySelf: "end",
                  },
                },
                "& dd": {
                  wordBreak: "break-all",
                  // For `md` breakpoint and up, this will act like `row-gap`
                  mb: 3,
                },
              }}
            >
              {fields.map((field) =>
                renderField(resource, field, renderForeignResource),
              )}
            </Box>
          )}
        />
      </CardContent>
    </Card>
  );
}
