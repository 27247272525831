import type { FieldValues } from "react-hook-form";
import type { OverrideProperties } from "type-fest";
import type { NewResourceFormProps } from "~/components/Form";
import { NewResourceForm } from "~/components/Form";
import { useNormalizeDataStorePath } from "~/paths";
import type { DataStorePathGenerator } from "~/paths";

type NewLqsResourceFormProps<
  TRequest extends FieldValues,
  TFormValues extends TRequest,
  TResponse extends object,
> = OverrideProperties<
  NewResourceFormProps<TRequest, TFormValues, TResponse>,
  {
    createDetailsLocation: (response: TResponse) => DataStorePathGenerator;
  }
>;

export function NewLqsResourceForm<
  TRequest extends FieldValues,
  TFormValues extends TRequest,
  TResponse extends object,
>({
  createDetailsLocation,
  ...rest
}: NewLqsResourceFormProps<TRequest, TFormValues, TResponse>) {
  const normalizeDataStorePath = useNormalizeDataStorePath();

  return (
    <NewResourceForm
      {...rest}
      createDetailsLocation={(response) =>
        normalizeDataStorePath(createDetailsLocation(response))
      }
    />
  );
}
