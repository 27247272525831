import React from "react";
import type { SvgIcon } from "@mui/material";
import {
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
} from "@mui/material";
import type { To } from "react-router-dom";
import { Link as RouterLink, matchPath, useLocation } from "react-router-dom";
import {
  ScreenConfiguration,
  useLayoutStateContext,
  useScreenConfiguration,
} from "~/layout";
import { redirectToSignOut } from "~/lib/auth";
import { DOCS_ORIGIN } from "~/links";

export function useIsActivePath() {
  const location = useLocation();

  return function isActivePath(path: string, end = false): boolean {
    return matchPath({ path, end }, location.pathname) !== null;
  };
}

export function useShouldUseMiniDrawerStyles(): boolean {
  const { navigationOpen } = useLayoutStateContext();
  const screenConfiguration = useScreenConfiguration();

  return !(
    screenConfiguration === ScreenConfiguration.Mobile || navigationOpen
  );
}

export function NavigationLink({
  to,
  Icon,
  text,
  path,
  end,
}: {
  to: To;
  Icon: typeof SvgIcon;
  text: string;
  path: string;
  end?: boolean;
}) {
  const isActivePath = useIsActivePath();
  const shouldUseMiniDrawerStyles = useShouldUseMiniDrawerStyles();

  const isSelected = isActivePath(path, end);

  const icon = <Icon fontSize="small" />;

  return (
    <ListItem component="div" disablePadding>
      <ListItemButton
        component={RouterLink}
        to={to}
        selected={isSelected}
        sx={{
          py: 0.5,
          ...(shouldUseMiniDrawerStyles && {
            px: 0,
            justifyContent: "center",
          }),
        }}
      >
        {shouldUseMiniDrawerStyles ? (
          <ListItemIcon sx={{ minWidth: 0, width: "auto", my: 0.5 }}>
            <Tooltip title={text} placement="right">
              {icon}
            </Tooltip>
          </ListItemIcon>
        ) : (
          <>
            <ListItemIcon sx={{ minWidth: 0, width: "auto", mr: 2 }}>
              {icon}
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                variant: "body2",
                fontWeight: isSelected ? 600 : undefined,
              }}
            >
              {text}
            </ListItemText>
          </>
        )}
      </ListItemButton>
    </ListItem>
  );
}

interface GlobalNavigationProps {
  homepageLink: React.ReactNode;
  children?: React.ReactNode;
  footerActions?: React.ReactNode;
}

export function GlobalNavigation({
  homepageLink,
  children,
  footerActions,
}: GlobalNavigationProps) {
  const shouldUseMiniDrawerStyles = useShouldUseMiniDrawerStyles();

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <List
        component="nav"
        aria-label="global"
        sx={{
          "& .MuiListItemButton-root": {
            my: 0.5,
            borderRadius: "8px",
          },
        }}
      >
        <ListItem
          component="div"
          disablePadding
          sx={{
            justifyContent: "center",
            "& .homepage-link": {
              display: "flex",
              justifyContent: "center",
            },
            "& .logqs-logo": {
              display: "block",
              width: "auto",
              height: 40,
              mb: 2,
            },
          }}
        >
          {homepageLink}
        </ListItem>
        {children}
      </List>
      {!shouldUseMiniDrawerStyles && (
        <Stack spacing={2.5} sx={{ mt: "auto", pt: 3, alignItems: "center" }}>
          {footerActions}
          <Link
            href={DOCS_ORIGIN}
            variant="body2"
            underline="none"
            sx={{ fontWeight: "bold" }}
          >
            Docs
          </Link>
          <Link
            variant="body2"
            component="button"
            onClick={redirectToSignOut}
            underline="none"
            sx={{ fontWeight: "bold" }}
          >
            Sign Out
          </Link>
        </Stack>
      )}
    </Stack>
  );
}
