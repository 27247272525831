import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { TablePage } from "~/components/Table";
import { LqsNavigation } from "~/lqs";
import { WorkflowHookFilters } from "./components/WorkflowHookFilters";
import {
  WorkflowHookSearchRequestProvider,
  WorkflowHookTable,
} from "./components/WorkflowHookTable";

export function WorkflowHooksPage() {
  return (
    <TablePage
      title="Search Hooks"
      navigation={<LqsNavigation />}
      RequestProvider={WorkflowHookSearchRequestProvider}
      table={
        <>
          <DataStoreBreadcrumbs />
          <WorkflowHookTable />
        </>
      }
      filters={<WorkflowHookFilters />}
    />
  );
}
