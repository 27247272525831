import { useState } from "react";
import { Container, styled } from "@mui/material";
import { Page } from "~/layout";
import type { Label } from "~/lqs";
import { LqsNavigation } from "~/lqs";
import { LabelsSection } from "./labels-section";
import { LogsSection } from "./logs-section";

const Sections = styled(Container)(({ theme }) => ({
  display: "grid",
  gridTemplateAreas: `
    "labels logs"
  `,
  gridTemplateColumns: "minmax(0, 2fr) minmax(0, 3fr)",
  columnGap: theme.spacing(4),
  alignItems: "start",
}));

export function TaggingPage() {
  const [selectedLabelId, setSelectedLabelId] = useState<Label["id"] | null>(
    null,
  );

  return (
    <Page title="Tagging" navigation={<LqsNavigation />}>
      <Sections fixed>
        <LabelsSection
          selectedLabelId={selectedLabelId}
          setSelectedLabelId={setSelectedLabelId}
        />
        <LogsSection selectedLabelId={selectedLabelId} />
      </Sections>
    </Page>
  );
}
