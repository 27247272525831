import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { TablePage } from "~/components/Table";
import { LqsNavigation } from "~/lqs";
import { LogQueryFilters } from "./components/LogQueryFilters";
import {
  LogQuerySearchRequestProvider,
  LogQueryTable,
} from "./components/LogQueryTable";

export function LogQueriesPage() {
  return (
    <TablePage
      title="Search Queries"
      navigation={<LqsNavigation />}
      RequestProvider={LogQuerySearchRequestProvider}
      table={
        <>
          <DataStoreBreadcrumbs />
          <LogQueryTable />
        </>
      }
      filters={<LogQueryFilters />}
    />
  );
}
