import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { TablePage } from "~/components/Table";
import { LqsNavigation } from "~/lqs";
import { RecordFilters } from "./components/RecordFilters";
import {
  RecordSearchRequestProvider,
  RecordTable,
} from "./components/RecordTable";

export function RecordsPage() {
  return (
    <TablePage
      title="Search Records"
      navigation={<LqsNavigation />}
      RequestProvider={RecordSearchRequestProvider}
      table={
        <>
          <DataStoreBreadcrumbs />
          <RecordTable />
        </>
      }
      filters={<RecordFilters />}
    />
  );
}
