import type { Maybe } from "~/types";

export function formatDatetime(date: Date, timezone: "UTC" | "local") {
  if (timezone === "UTC") {
    return date.toUTCString();
  }

  return date.toLocaleString();
}

interface DatetimeProps {
  date: Maybe<Date>;
}

export function Datetime({ date }: DatetimeProps) {
  return date == null ? (
    "-"
  ) : (
    <span title={formatDatetime(date, "local")}>
      {formatDatetime(date, "UTC")}
    </span>
  );
}
