import React from "react";
import { TablePage } from "~/components/Table";
import { LqsNavigation } from "~/lqs";
import { LogFilters } from "./components/LogFilters";
import { LogSearchRequestProvider, LogTable } from "./components/LogTable";

export function LogsPage() {
  return (
    <TablePage
      title="Search Logs"
      navigation={<LqsNavigation />}
      RequestProvider={LogSearchRequestProvider}
      table={<LogTable />}
      filters={<LogFilters />}
    />
  );
}
