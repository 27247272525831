import { Link, TableCell, Typography } from "@mui/material";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { Log, LqsColumn } from "~/lqs";
import { LqsResourceTable, useLogs } from "~/lqs";
import { DataStoreLink, makeLogLocation, makeNewLogLocation } from "~/paths";
import { listLogsAliases, listLogsSchema } from "../schemas";

const columns: ReadonlyArray<LqsColumn<Log>> = [
  {
    header: "Log",
    sortKey: "name",
    renderCell(log) {
      return (
        <TableCell>
          {log.name}
          <Link
            component={DataStoreLink}
            to={makeLogLocation({ logId: log.id })}
          >
            <Typography variant="body2">{log.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "startTime",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "endTime",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "recordSize",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "recordCount",
    dataType: "number",
    isSortable: true,
  },
  {
    accessor: "objectSize",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "objectCount",
    dataType: "number",
    isSortable: true,
  },
  {
    accessor: "defaultWorkflowId",
    dataType: "foreign-key",
    resourceType: "workflow",
    defaultHidden: true,
  },
  {
    accessor: "locked",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "lockedBy",
    dataType: "foreign-key",
    resourceType: "user",
    defaultHidden: true,
  },
  {
    accessor: "lockedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "groupId",
    dataType: "foreign-key",
    resourceType: "group",
  },
  ...commonResourceColumns,
];

export const [useLogSearchRequest, LogSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listLogsSchema,
    aliases: listLogsAliases,
  });

export function LogTable() {
  const [request, setRequest] = useLogSearchRequest();

  const searchQuery = useLogs(preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="log"
      resourceCreateLocation={makeNewLogLocation()}
      getRowKey={(log) => log.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
