import React from "react";
import { DoNotDisturb } from "@mui/icons-material";
import { ErrorBoundary } from "react-error-boundary";
import { Loading } from "~/components/Loading";
import { ErrorMessage } from "~/components/error-message";
import { find } from "~/lib/std";
import type { Topic } from "~/lqs";
import { assertNever } from "~/utils";
import PanelLayout from "../components/PanelLayout";
import { TopicName } from "../components/topic-name";
import { usePanelContext, VisualizationType } from "../panels";
import { usePlaybackSource } from "../playback";
import ChartVisualization from "./ChartVisualization";
import { ImageVisualization } from "./ImageVisualization";
import type { MapVisualizationProps } from "./MapVisualization";
import { ThreeDVisualization } from "./ThreeDVisualization";
import TimelineVisualization from "./TimelineVisualization";
import { TopicSelector } from "./TopicSelector";

const MapPanel = React.lazy(() => import("./MapVisualization"));

export function VisualizationSwitch({
  topics,
}: {
  topics: ReadonlyArray<Topic>;
}) {
  const playbackSource = usePlaybackSource();

  const panel = usePanelContext();

  if (playbackSource.isLoading) {
    // Visualizations expect playback source to be loaded when they're rendered
    return <Loading type="circular" />;
  } else if (!panel.isInitialized) {
    return <TopicSelector panelId={panel.id} topics={topics} />;
  } else {
    const topic = find(topics, { name: panel.topicName });

    if (topic === undefined) {
      return (
        <PanelLayout>
          <ErrorMessage icon={<DoNotDisturb fontSize="large" color="error" />}>
            <TopicName monospace>{panel.topicName}</TopicName> is not in this
            log
          </ErrorMessage>
        </PanelLayout>
      );
    } else {
      switch (panel.visualization) {
        case VisualizationType.Timeline: {
          return <TimelineVisualization panel={panel} topic={topic} />;
        }
        case VisualizationType.Chart: {
          return <ChartVisualization panel={panel} topic={topic} />;
        }
        case VisualizationType.Image: {
          return (
            <ImageVisualization
              panel={panel}
              topic={topic}
              playerTopics={topics}
            />
          );
        }
        case VisualizationType.Map: {
          return renderLazyMapPanel({ panel, topic });
        }
        case VisualizationType.ThreeD: {
          return <ThreeDVisualization panel={panel} topic={topic} />;
        }
        default: {
          assertNever(panel.visualization);
        }
      }
    }
  }
}

function renderLazyMapPanel(props: MapVisualizationProps) {
  return (
    <ErrorBoundary
      fallback={renderPanelFallback(
        <ErrorMessage>Could not initialize map view</ErrorMessage>,
      )}
    >
      <React.Suspense
        fallback={renderPanelFallback(<Loading type="circular" />)}
      >
        <MapPanel {...props} />
      </React.Suspense>
    </ErrorBoundary>
  );
}

function renderPanelFallback(children: React.ReactNode) {
  return <PanelLayout>{children}</PanelLayout>;
}
