import { TablePage } from "~/components/Table";
import { LqsNavigation } from "~/lqs";
import { RoleFilters } from "./components/RoleFilters";
import { RoleSearchRequestProvider, RoleTable } from "./components/RoleTable";

export function RolesPage() {
  return (
    <TablePage
      title="Search Roles"
      navigation={<LqsNavigation />}
      RequestProvider={RoleSearchRequestProvider}
      table={<RoleTable />}
      filters={<RoleFilters />}
    />
  );
}
