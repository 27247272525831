import React from "react";
import { Page } from "~/layout";
import { LqsNavigation } from "../LqsNavigation";

export function LqsPage({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <Page title={title} navigation={<LqsNavigation />}>
      {children}
    </Page>
  );
}
