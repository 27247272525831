import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { Center } from "~/components/Center";
import { redirectToSignIn } from "~/lib/auth";
import { MigrationAnnouncement } from "./MigrationAnnouncement";

export function GuestLandingPage() {
  return (
    <Center>
      <Card sx={{ width: 500, maxWidth: "calc(100% - 2rem)" }}>
        <CardHeader
          title="Welcome to LogQS Studio"
          titleTypographyProps={{ component: "h1" }}
        />
        <CardContent>
          <MigrationAnnouncement />
        </CardContent>
        <CardContent
          sx={{
            // Counter the migration announcement's bottom margin
            mt: -4,
          }}
        >
          <Typography paragraph>
            To use LogQS Studio, you'll first need to sign in or register for an
            account.
          </Typography>
          <Button
            sx={{ mt: 2 }}
            color="primary"
            variant="contained"
            fullWidth
            disableElevation
            onClick={redirectToSignIn}
          >
            Sign In / Register
          </Button>
        </CardContent>
      </Card>
    </Center>
  );
}
