import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { UserEditPage } from "~/domain/common";
import { LqsNavigation } from "~/lqs";

export function LqsUserEditPage() {
  return (
    <UserEditPage
      navigation={<LqsNavigation />}
      breadcrumbs={<DataStoreBreadcrumbs />}
    />
  );
}
