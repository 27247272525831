import { TablePage } from "~/components/Table";
import { LqsNavigation } from "~/lqs";
import { GroupFilters } from "./components/GroupFilters";
import {
  GroupSearchRequestProvider,
  GroupTable,
} from "./components/GroupTable";

export function GroupsPage() {
  return (
    <TablePage
      title="Search Groups"
      navigation={<LqsNavigation />}
      RequestProvider={GroupSearchRequestProvider}
      table={<GroupTable />}
      filters={<GroupFilters />}
    />
  );
}
