import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { TablePage } from "~/components/Table";
import { LqsNavigation } from "~/lqs";
import { useLogParams } from "~/paths";
import {
  LogObjectFilters,
  LogObjectSearchRequestProvider,
  LogObjectTable,
} from "./components/LogObjectTable";

export function LogObjectsPage() {
  const { logId } = useLogParams();

  return (
    <TablePage
      title="Search Objects"
      navigation={<LqsNavigation />}
      RequestProvider={LogObjectSearchRequestProvider}
      table={
        <>
          <DataStoreBreadcrumbs />
          <LogObjectTable logId={logId} />
        </>
      }
      filters={<LogObjectFilters />}
    />
  );
}
