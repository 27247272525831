import React from "react";
import {
  Album,
  Badge,
  CloudDownload,
  CloudUpload,
  CloudUploadOutlined,
  Dashboard,
  Group,
  Key,
  Label,
  LocalOffer,
  People,
  Person,
  Schema,
} from "@mui/icons-material";
import type { SvgIcon } from "@mui/material";
import { Button, Divider } from "@mui/material";
import { Database, FileCloud } from "mdi-material-ui";
import { Link as RouterLink } from "react-router-dom";
import {
  GlobalNavigation,
  NavigationLink,
  useShouldUseMiniDrawerStyles,
} from "~/components/GlobalNavigation";
import { Logo } from "~/components/Logo";
import { AdminGuard } from "~/domain/common";
import type { DataStorePathGenerator } from "~/paths";
import * as paths from "~/paths";
import { useNormalizeDataStorePath } from "~/paths";

export function LqsNavigation() {
  const shouldUseMiniDrawerStyles = useShouldUseMiniDrawerStyles();

  const normalizeDataStorePath = useNormalizeDataStorePath();

  function renderLink(
    Icon: typeof SvgIcon,
    text: string,
    path: string,
    to: DataStorePathGenerator,
    end?: boolean,
  ) {
    return (
      <NavigationLink
        to={normalizeDataStorePath(to)}
        Icon={Icon}
        text={text}
        path={path}
        end={end}
      />
    );
  }

  return (
    <GlobalNavigation
      homepageLink={
        <RouterLink
          className="homepage-link"
          to={paths.makeStudioHomepageLocation()}
        >
          <Logo alt="Homepage" icon={shouldUseMiniDrawerStyles} />
        </RouterLink>
      }
      footerActions={
        <Button
          component={RouterLink}
          to={paths.makeStudioHomepageLocation()}
          variant="contained"
          size="small"
        >
          Change DataStore
        </Button>
      }
    >
      {renderLink(
        Dashboard,
        "Dashboard",
        paths.DATASTORE,
        paths.makeDataStoreDashboardLocation(),
        true,
      )}
      {renderLink(
        CloudUpload,
        "Upload",
        paths.UPLOAD,
        paths.makeUploadLocation(),
      )}
      {renderLink(Album, "Player", paths.PLAYER, paths.makePlayerLocation())}
      {renderLink(
        LocalOffer,
        "Tagging",
        paths.TAGGING,
        paths.makeTaggingLocation(),
      )}
      <Divider />
      {renderLink(FileCloud, "Logs", paths.LOGS, paths.makeLogsLocation())}
      {renderLink(
        CloudDownload,
        "Topics",
        paths.TOPICS,
        paths.makeTopicsLocation(),
      )}
      {renderLink(
        CloudUploadOutlined,
        "Ingestions",
        paths.INGESTIONS,
        paths.makeIngestionsLocation(),
      )}
      {renderLink(
        CloudDownload,
        "Digestions",
        paths.DIGESTIONS,
        paths.makeDigestionsLocation(),
      )}
      {renderLink(
        Schema,
        "Workflows",
        paths.WORKFLOWS,
        paths.makeWorkflowsLocation(),
      )}
      {renderLink(Label, "Labels", paths.LABELS, paths.makeLabelsLocation())}
      {renderLink(
        Database,
        "Object Stores",
        paths.OBJECT_STORES,
        paths.makeObjectStoresLocation(),
      )}
      <Divider />
      {renderLink(
        Person,
        "Profile",
        paths.PROFILE,
        paths.makeProfileLocation(),
      )}
      <AdminGuard>
        {renderLink(People, "Users", paths.USERS, paths.makeUsersLocation())}
        {renderLink(Group, "Groups", paths.GROUPS, paths.makeGroupsLocation())}
        {renderLink(Badge, "Roles", paths.ROLES, paths.makeRolesLocation())}
        {renderLink(
          Key,
          "API Keys",
          paths.API_KEYS,
          paths.makeApiKeysLocation(),
        )}
      </AdminGuard>
    </GlobalNavigation>
  );
}
