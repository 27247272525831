import { CircularProgress, Stack, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Card } from "~/components/Card";
import { Center } from "~/components/Center";
import {
  DetailsLayout,
  LockCard,
  RelatedResource,
} from "~/components/DetailsCards";
import {
  Form,
  FormSkeleton,
  getChangedFields,
  ObjectField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import {
  OBJECT_KEY_DELIMITER,
  ObjectSearchRequestProvider,
} from "~/components/ObjectExplorer";
import { QueryRenderer } from "~/components/QueryRenderer";
import { ErrorMessage } from "~/components/error-message";
import { pick } from "~/lib/std";
import type { Digestion } from "~/lqs";
import {
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteDigestion,
  useDigestion,
  useUpdateDigestion,
} from "~/lqs";
import {
  calculateProcessRefetchInterval,
  Process,
} from "~/lqs/components/process";
import {
  makeDigestionPartsLocation,
  makeDigestionsLocation,
  makeDigestionTopicsLocation,
  makeEditDigestionLocation,
  makeLogObjectsLocation,
  useDigestionParams,
} from "~/paths";
import { selectData } from "~/utils";
import { LogObjectTable } from "../../logs/components/LogObjectTable";
import { editDigestionSchema } from "../schemas";
import {
  DigestionPartSearchRequestProvider,
  DigestionPartTable,
} from "./DigestionPartTable";
import {
  DigestionTopicSearchRequestProvider,
  DigestionTopicTable,
} from "./DigestionTopicTable";

export function DigestionDetails() {
  const { digestionId } = useDigestionParams();

  const query = useDigestion(digestionId, {
    select: selectData,
    refetchInterval: calculateProcessRefetchInterval,
  });

  const generalSection = <GeneralSection query={query} />;
  const processSection = (
    <Process query={query} mutation={useUpdateDigestion(digestionId)} />
  );
  const relatedResourcesSection = (
    <Stack spacing={2}>
      <Typography variant="h4" component="h2">
        Related Resources
      </Typography>
      <RelatedObjects query={query} />
      <RelatedResource
        text="Topics"
        to={makeDigestionTopicsLocation({ digestionId })}
        table={
          <DigestionTopicSearchRequestProvider embedded>
            <DigestionTopicTable />
          </DigestionTopicSearchRequestProvider>
        }
      />
      <RelatedResource
        text="Parts"
        to={makeDigestionPartsLocation({ digestionId })}
        table={
          <DigestionPartSearchRequestProvider embedded>
            <DigestionPartTable />
          </DigestionPartSearchRequestProvider>
        }
      />
    </Stack>
  );
  const infoSection = <InfoSection query={query} />;
  const lockSection = (
    <LockCard
      resourceName="digestion"
      query={query}
      updateMutation={useUpdateDigestion(digestionId)}
    />
  );
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="digestion"
      query={query}
      editLocation={makeEditDigestionLocation({ digestionId })}
      deleteMutation={useDeleteDigestion(digestionId)}
      getReadableName={(digestion) => digestion.name ?? digestion.id}
      listLocation={makeDigestionsLocation()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {processSection}
          {relatedResourcesSection}
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {processSection}
          {lockSection}
          {historySection}
          {manageSection}
          {relatedResourcesSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Digestion> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "foreign-key", resourceType: "log", accessor: "logId" },
          {
            dataType: "foreign-key",
            resourceType: "workflow",
            accessor: "workflowId",
          },
          { dataType: "json", accessor: "workflowContext" },
        ]}
      />
    </Card>
  );
}

function RelatedObjects({ query }: { query: UseQueryResult<Digestion> }) {
  return (
    <QueryRenderer
      query={query}
      loading={
        <Card>
          <Center sx={{ height: 350 }}>
            <CircularProgress />
          </Center>
        </Card>
      }
      error={
        <Card>
          <ErrorMessage>Couldn't load objects</ErrorMessage>
        </Card>
      }
      success={(digestion) => {
        const digestionPrefix = ["digestions", digestion.id, ""].join(
          OBJECT_KEY_DELIMITER,
        );

        return (
          <RelatedResource
            text="Objects"
            to={makeLogObjectsLocation(digestion.logId, {
              directory: digestionPrefix,
            })}
            table={
              <ObjectSearchRequestProvider embedded>
                <LogObjectTable
                  logId={digestion.logId}
                  homeName={digestion.id}
                  subResourcePrefix={digestionPrefix}
                />
              </ObjectSearchRequestProvider>
            }
          />
        );
      }}
    />
  );
}

function InfoSection({ query }: { query: UseQueryResult<Digestion> }) {
  return (
    <Card>
      <QueryRenderer
        query={query}
        loading={<FormSkeleton shapes={["text", "multiline", "multiline"]} />}
        success={(digestion) => <InfoSectionImpl digestion={digestion} />}
      />
    </Card>
  );
}

function InfoSectionImpl({ digestion }: { digestion: Digestion }) {
  const updateDigestion = useUpdateDigestion(digestion.id);

  const { enqueueSnackbar } = useSnackbar();

  const schema = editDigestionSchema.pick({
    name: true,
    note: true,
    context: true,
  });
  const FIELDS = schema.keyof().options;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useStudioForm({
    schema,
    defaultValues: pick(digestion, FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateDigestion.mutate(changedFields, {
        onSuccess(response) {
          enqueueSnackbar("Digestion updated", { variant: "success" });

          reset(pick(response.data, FIELDS));
        },
        onError() {
          enqueueSnackbar("Unable to update digestion", { variant: "error" });
        },
      });
    },
  });

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        loading={updateDigestion.isLoading}
        submitText="Save Changes"
        disabled={digestion.locked}
      >
        <TextField control={control} name="name" />
        <TextField control={control} name="note" multiline />
        <ObjectField control={control} name="context" />
      </Form>
      {digestion.locked && (
        <Typography variant="body2">
          Unlock this digestion to make updates.
        </Typography>
      )}
    </>
  );
}
