import { Stack, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Card } from "~/components/Card";
import { DetailsLayout, RelatedResource } from "~/components/DetailsCards";
import {
  CheckboxField,
  Form,
  FormSkeleton,
  getChangedFields,
  ObjectField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { QueryRenderer } from "~/components/QueryRenderer";
import { pick } from "~/lib/std";
import type { Workflow } from "~/lqs";
import {
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteWorkflow,
  useUpdateWorkflow,
  useWorkflow,
} from "~/lqs";
import {
  makeEditWorkflowLocation,
  makeHooksLocation,
  makeWorkflowsLocation,
  useWorkflowParams,
} from "~/paths";
import { selectData } from "~/utils";
import { editWorkflowSchema } from "../schemas";
import {
  WorkflowHookSearchRequestProvider,
  WorkflowHookTable,
} from "./WorkflowHookTable";

export function WorkflowDetails() {
  const { workflowId } = useWorkflowParams();

  const query = useWorkflow(workflowId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const relatedResourcesSection = (
    <Stack spacing={2}>
      <Typography variant="h4" component="h2">
        Related Resources
      </Typography>
      <RelatedResource
        text="Hooks"
        to={makeHooksLocation({ workflowId })}
        table={
          <WorkflowHookSearchRequestProvider embedded>
            <WorkflowHookTable />
          </WorkflowHookSearchRequestProvider>
        }
      />
    </Stack>
  );
  const infoSection = <InfoSection query={query} />;
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="workflow"
      query={query}
      editLocation={makeEditWorkflowLocation({ workflowId })}
      deleteMutation={useDeleteWorkflow(workflowId)}
      getReadableName={(workflow) => workflow.name}
      listLocation={makeWorkflowsLocation()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {relatedResourcesSection}
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {historySection}
          {manageSection}
          {relatedResourcesSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Workflow> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "text", accessor: "name" },
          { dataType: "boolean", accessor: "managed" },
          { dataType: "json", accessor: "contextSchema" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<Workflow> }) {
  return (
    <Card>
      <QueryRenderer
        query={query}
        loading={
          <FormSkeleton shapes={["multiline", "multiline", "text", "text"]} />
        }
        success={(workflow) => <InfoSectionImpl workflow={workflow} />}
      />
    </Card>
  );
}

function InfoSectionImpl({ workflow }: { workflow: Workflow }) {
  const updateWorkflow = useUpdateWorkflow(workflow.id);

  const { enqueueSnackbar } = useSnackbar();

  const schema = editWorkflowSchema.pick({
    note: true,
    context: true,
    _default: true,
    disabled: true,
  });
  const FIELDS = schema.keyof().options;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useStudioForm({
    schema,
    defaultValues: pick(workflow, FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateWorkflow.mutate(changedFields, {
        onSuccess(response) {
          enqueueSnackbar("Workflow updated", { variant: "success" });

          reset(pick(response.data, FIELDS));
        },
        onError() {
          enqueueSnackbar("Unable to update workflow", { variant: "error" });
        },
      });
    },
  });

  return (
    <Form
      onSubmit={handleSubmit}
      loading={updateWorkflow.isLoading}
      submitText="Save Changes"
    >
      <TextField control={control} name="note" multiline />
      <ObjectField control={control} name="context" />
      <CheckboxField control={control} name="_default" />
      <CheckboxField control={control} name="disabled" />
    </Form>
  );
}
