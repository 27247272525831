import React, { useCallback, useState } from "react";
import { createSafeContext } from "~/contexts";
import {
  ControlledLayoutStateProvider,
  useLayoutStateProviderValue,
} from "~/layout";
import { invariant } from "~/lib/invariant";
import { usePlayerConfig } from "./hooks";
import type { InitializedPanelNode, PanelNode } from "./panels";

type SidebarState =
  | { id: "logs" }
  | { id: "digestions" }
  | { id: "settings" }
  | { id: "panel-controls"; panelId: InitializedPanelNode["id"] };

interface ControlledPanelContextValue {
  panelId: InitializedPanelNode["id"] | null;
  handleLoadLayout: () => void;
  togglePanelControls: (panel: InitializedPanelNode) => void;
  handleClearPanelTopic: (panel: PanelNode) => void;
  handleClosePanel: (panel: PanelNode) => void;
  closeControls: () => void;
}

export const [useControlledPanelContext, ControlledPanelContext] =
  createSafeContext<ControlledPanelContextValue>("ControlledPanel");

export function PlayerLayoutStateProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { logId } = usePlayerConfig();

  const [sidebarState, setSidebarState] = useState<SidebarState | null>(
    logId == null ? { id: "logs" } : null,
  );

  const layoutStateProviderValue = useLayoutStateProviderValue({
    currentSidebarId: sidebarState?.id ?? null,
    // While the player stores more than just IDs as sidebar state, some
    // layout context consumers like the sidebar triggers expect a
    // useState-like setter to update just the sidebar ID. This adapts the
    // player's sidebar state to work with those consumers.
    setCurrentSidebarId: useCallback((action) => {
      setSidebarState((prevState) =>
        // It's not valid to set the sidebar ID to "panel-controls" without also
        // passing along the corresponding panel, so this guard is necessary
        // to prevent an inconsistent state. Anything that does need to set the
        // sidebar to "panel-controls" can use the `ControlledPanelContextValue`
        // handlers.
        preventInvalidSidebarId(
          typeof action !== "function" ? action : action(prevState?.id ?? null),
        ),
      );
    }, []),
  });

  // If the user had a log selected but, by some type of navigation, ends up
  // with no log selected (i.e. `logId == null`), there will no longer be any
  // panel with controls open since the <PanelLayoutProvider /> is reset with
  // a key whenever there's no log ID. Putting a key on this layout provider
  // _would_ reset the sidebar state but also everything else along with it
  // which is not desired. Also, the sidebar state only needs reset if panel
  // controls were open at the time. For those reasons, it makes more sense to
  // reset the state conditionally during render.
  if (logId == null && sidebarState?.id === "panel-controls") {
    setSidebarState(null);
    return null;
  }

  const controlledPanelContextValue: ControlledPanelContextValue = {
    panelId:
      sidebarState?.id === "panel-controls" ? sidebarState.panelId : null,
    handleLoadLayout() {
      setSidebarState((prevState) =>
        // When the user loads a layout, any panel whose controls are open will
        // almost certainly be irrelevant. If a panel's controls are currently
        // open, close the sidebar; otherwise, leave as is.
        prevState?.id === "panel-controls" ? null : prevState,
      );
    },
    togglePanelControls(panel) {
      setSidebarState((prevState) => {
        // If the panel's controls are already open, the result of toggling
        // them should be the sidebar closing. If not, then either a
        // different or no panel's controls are open so show this panel's
        // controls now.
        if (
          prevState?.id === "panel-controls" &&
          prevState.panelId === panel.id
        ) {
          return null;
        } else {
          invariant(
            panel.isInitialized,
            "Can only show controls for initialized panel",
          );

          return { id: "panel-controls", panelId: panel.id };
        }
      });
    },
    handleClearPanelTopic(panel) {
      setSidebarState((prevState) =>
        // This panel is switching from initialized to not initialized so its
        // controls should be closed if they're currently open; otherwise,
        // leave as is
        prevState?.id === "panel-controls" && prevState.panelId === panel.id
          ? null
          : prevState,
      );
    },
    handleClosePanel(panel) {
      setSidebarState((prevState) =>
        // When any panel gets closed, if its controls are open in the sidebar
        // then close the sidebar; otherwise, leave as is.
        prevState?.id === "panel-controls" && prevState.panelId === panel.id
          ? null
          : prevState,
      );
    },
    closeControls() {
      setSidebarState(null);
    },
  };

  return (
    <ControlledLayoutStateProvider value={layoutStateProviderValue}>
      <ControlledPanelContext.Provider value={controlledPanelContextValue}>
        {children}
      </ControlledPanelContext.Provider>
    </ControlledLayoutStateProvider>
  );
}

function preventInvalidSidebarId(
  nextSidebarId: string | null,
): SidebarState | null {
  if (nextSidebarId === null) {
    return null;
  } else {
    invariant(
      nextSidebarId !== "panel-controls",
      "Cannot open panel controls sidebar like this",
    );

    return {
      id: nextSidebarId as Exclude<SidebarState["id"], "panel-controls">,
    };
  }
}
