import React from "react";
import type { FieldValues } from "react-hook-form";
import type { OverrideProperties } from "type-fest";
import type { EditResourceFormProps } from "~/components/Form";
import { EditResourceForm } from "~/components/Form";
import type { DataStorePathGenerator } from "~/paths";
import { useNormalizeDataStorePath } from "~/paths";

type EditLqsResourceFormProps<
  TRequest extends FieldValues,
  TFormValues extends TRequest,
  TResource extends object,
> = OverrideProperties<
  EditResourceFormProps<TRequest, TFormValues, TResource>,
  {
    detailsLocation: DataStorePathGenerator;
  }
>;

export function EditLqsResourceForm<
  TRequest extends FieldValues,
  TFormValues extends TRequest,
  TResource extends object,
>({
  detailsLocation,
  ...rest
}: EditLqsResourceFormProps<TRequest, TFormValues, TResource>) {
  const normalizeDataStorePath = useNormalizeDataStorePath();

  return (
    <EditResourceForm
      {...rest}
      detailsLocation={normalizeDataStorePath(detailsLocation)}
    />
  );
}
