import { ObjectField, TextField } from "~/components/Form";
import { EditLqsResourceForm, useLogQuery, useUpdateLogQuery } from "~/lqs";
import { makeLogQueryLocation, useLogQueryParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_LOG_QUERY_FIELDS, editLogQuerySchema } from "../schemas";

export function LogQueryEditForm() {
  const { logId, queryId } = useLogQueryParams();

  return (
    <EditLqsResourceForm
      schema={editLogQuerySchema}
      resourceName="query"
      query={useLogQuery(logId, queryId, { select: selectData })}
      editableFields={EDITABLE_LOG_QUERY_FIELDS}
      mutation={useUpdateLogQuery(logId, queryId)}
      detailsLocation={makeLogQueryLocation({ logId, queryId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="name" />
          <TextField control={control} name="statement" multiline monospace />
          <ObjectField control={control} name="parameters" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditLqsResourceForm>
  );
}
