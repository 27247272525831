import React from "react";
import { Divider } from "@mui/material";
import type { Topic } from "~/lqs";
import { TagsControl } from "../../../components/tags-control";
import {
  InferenceFrameControls,
  OnDemandInferenceControls,
  searchForInferenceTopic,
} from "../../../inference";
import type { InitializedPanelNode } from "../../../panels";
import { useImageFrames } from "../use-image-frames";
import { ImageTransformControls } from "./image-transform-controls";

export function ImageControls({
  panel,
  topic,
  playerTopics,
}: {
  panel: InitializedPanelNode;
  topic: Topic;
  playerTopics: ReadonlyArray<Topic>;
}) {
  const inferenceTopicSearchResult = searchForInferenceTopic(
    panel,
    playerTopics,
  );

  const { snapshot } = useImageFrames({
    topic,
    inferenceTopic: inferenceTopicSearchResult.topic,
  });

  return (
    <>
      <TagsControl topic={topic} />
      <Divider />
      <ImageTransformControls
        panel={panel}
        flipDirection={panel.imageFlipDirection}
      />
      <Divider />
      <InferenceFrameControls
        panel={panel}
        topic={topic}
        playerTopics={playerTopics}
        inferenceTopicSearchResult={inferenceTopicSearchResult}
        imageFrame={snapshot.value?.current}
      />
      <Divider />
      <OnDemandInferenceControls panel={panel} />
    </>
  );
}
