import { ObjectField, TextField } from "~/components/Form";
import { NewLqsResourceForm, useCreateLogQuery } from "~/lqs";
import { makeLogQueryLocation, useLogParams } from "~/paths";
import { createLogQuerySchema } from "../schemas";

export function LogQueryCreateForm() {
  const { logId } = useLogParams();

  return (
    <NewLqsResourceForm
      schema={createLogQuerySchema}
      defaultValues={{
        name: null,
        note: null,
        statement: null,
        parameters: null,
        context: null,
      }}
      resourceName="query"
      mutation={useCreateLogQuery(logId)}
      createDetailsLocation={(response) =>
        makeLogQueryLocation({
          logId,
          queryId: response.data.id,
        })
      }
    >
      {(control) => (
        <>
          <TextField control={control} name="name" />
          <TextField control={control} name="statement" multiline monospace />
          <ObjectField control={control} name="parameters" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </NewLqsResourceForm>
  );
}
