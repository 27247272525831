import { TextField } from "~/components/Form";
import { NewLqsResourceForm, useCreateLabel } from "~/lqs";
import { makeLabelLocation } from "~/paths";
import { createLabelSchema } from "../schemas";

export function LabelCreateForm() {
  return (
    <NewLqsResourceForm
      schema={createLabelSchema}
      defaultValues={{
        value: null,
        note: null,
      }}
      resourceName="label"
      mutation={useCreateLabel()}
      createDetailsLocation={(response) =>
        makeLabelLocation({ labelId: response.data.id })
      }
    >
      {(control) => (
        <>
          <TextField control={control} name="value" required />
          <TextField control={control} name="note" />
        </>
      )}
    </NewLqsResourceForm>
  );
}
