import React from "react";
import { DarkMode, Error as ErrorIcon, LightMode } from "@mui/icons-material";
import {
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useMatch } from "react-router-dom";
import { Dl, renderDlGroup } from "~/components/DescriptionList";
import { SidebarHeader } from "~/layout";
import { useCurrentDataStore, useCurrentUser } from "~/lqs";
import { DATASTORE } from "~/paths";
import { selectData } from "~/utils";
import type { BytesFormat, ColorScheme } from "./provider";
import { useSettings } from "./provider";

interface SettingsSidebarProps {
  children?: React.ReactNode;
}

export function SettingsSidebar({ children }: SettingsSidebarProps) {
  const settings = useSettings();

  function handleColorSchemeChange(
    _: unknown,
    newColorScheme: ColorScheme | null,
  ): void {
    if (newColorScheme === null) {
      return;
    }

    settings.setColorScheme(newColorScheme);
  }

  function handleBytesFormatChange(
    _: unknown,
    newBytesFormat: BytesFormat | null,
  ): void {
    if (newBytesFormat === null) {
      return;
    }

    settings.setBytesFormat(newBytesFormat);
  }

  return (
    <>
      <SidebarHeader title="Settings" />
      <Stack spacing={2}>
        <LqsRouteGuard>
          <CurrentDataStoreInfo />
        </LqsRouteGuard>
        <div>
          <Typography
            variant="h6"
            component="p"
            id="color-scheme-title"
            gutterBottom
          >
            Color Scheme
          </Typography>
          <ToggleButtonGroup
            sx={{
              "& .MuiSvgIcon-root": {
                mr: 1,
              },
            }}
            aria-labelledby="color-scheme-title"
            value={settings.colorScheme}
            onChange={handleColorSchemeChange}
            exclusive
            color="primary"
            fullWidth
          >
            <ToggleButton value="light">
              <LightMode />
              Light
            </ToggleButton>
            <ToggleButton value="dark">
              <DarkMode />
              Dark
            </ToggleButton>
          </ToggleButtonGroup>
          <Typography
            sx={{ mt: 2 }}
            variant="h6"
            component="p"
            id="bytes-format-title"
            gutterBottom
          >
            Bytes Format
          </Typography>
          <ToggleButtonGroup
            sx={{
              "& .MuiSvgIcon-root": {
                mr: 1,
              },
            }}
            aria-labelledby="bytes-format-title"
            value={settings.bytesFormat}
            onChange={handleBytesFormatChange}
            exclusive
            color="primary"
            fullWidth
          >
            <ToggleButton value="plain">Plain</ToggleButton>
            <ToggleButton value="compact">Compact</ToggleButton>
          </ToggleButtonGroup>
        </div>
        {children !== undefined && (
          <div>
            <Divider sx={{ my: 2 }} />
            {children}
          </div>
        )}
      </Stack>
    </>
  );
}

// TODO: Can't put this in the `lqs` folder or jest starts to fail from circular imports
function LqsRouteGuard({ children }: { children: React.ReactNode }) {
  const isLqsRoute = useMatch({ path: DATASTORE, end: false }) !== null;

  if (isLqsRoute) {
    return children;
  } else {
    return null;
  }
}

function CurrentDataStoreInfo() {
  const dataStore = useCurrentDataStore();
  const currentUserQuery = useCurrentUser({ select: selectData });

  return (
    <div>
      <Typography variant="h6" component="p">
        Current DataStore Connection
      </Typography>
      <Dl spacing={3}>
        {renderDlGroup("Name", dataStore.name, { xs: 12 })}
        {renderDlGroup("ID", dataStore.id, { xs: 12 })}
        {renderDlGroup(
          "Username",
          currentUserQuery.isSuccess ? (
            currentUserQuery.data?.username ?? "System User"
          ) : currentUserQuery.isError ? (
            <Stack direction="row" alignItems="center" spacing={1}>
              <ErrorIcon color="error" />
              <Typography paragraph>
                You're signed in but your account information couldn't be
                retrieved.
              </Typography>
            </Stack>
          ) : (
            "Fetching your account information..."
          ),
          { xs: 12 },
        )}
      </Dl>
    </div>
  );
}
