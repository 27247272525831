import {
  CheckboxField,
  NumberField,
  ObjectField,
  SelectField,
  TextField,
} from "~/components/Form";
import {
  EditLqsResourceForm,
  processStateOptions,
  useDigestion,
  useUpdateDigestion,
  WorkflowSelect,
} from "~/lqs";
import { makeDigestionLocation, useDigestionParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_DIGESTION_FIELDS, editDigestionSchema } from "../schemas";

export function DigestionEditForm() {
  const { digestionId } = useDigestionParams();

  return (
    <EditLqsResourceForm
      schema={editDigestionSchema}
      resourceName="digestion"
      query={useDigestion(digestionId, { select: selectData })}
      editableFields={EDITABLE_DIGESTION_FIELDS}
      mutation={useUpdateDigestion(digestionId)}
      detailsLocation={makeDigestionLocation({ digestionId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="name" required />
          <SelectField
            control={control}
            name="state"
            options={processStateOptions}
            required
          />
          <NumberField control={control} name="progress" />
          <WorkflowSelect control={control} name="workflowId" />
          <ObjectField control={control} name="workflowContext" />
          <ObjectField control={control} name="error" />
          <CheckboxField control={control} name="locked" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditLqsResourceForm>
  );
}
