import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Menu, MenuOpen } from "@mui/icons-material";
import {
  AppBar,
  dividerClasses,
  Drawer,
  IconButton,
  styled,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { ValueOf } from "ts-essentials";
import { createSafeContext } from "~/contexts";
import { useSessionStorage } from "~/hooks";

export const ScreenConfiguration = {
  Mobile: "mobile",
  Desktop: "desktop",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ScreenConfiguration = ValueOf<typeof ScreenConfiguration>;

interface SidebarStateContextValue {
  currentSidebarId: string | null;
  setCurrentSidebarId: React.Dispatch<React.SetStateAction<string | null>>;
}

interface LayoutStateContextValue extends SidebarStateContextValue {
  navigationOpen: boolean;
  setNavigationOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const [useLayoutStateContext, LayoutStateContext] =
  createSafeContext<LayoutStateContextValue>("LayoutState");

const DESKTOP_NAVIGATION_OPEN_STORAGE_KEY = "desktop-nav-open";

/**
 * Low-level hook for managing layout state context value. The return value
 * should be passed to `<ControlledLayoutStateProvider />`. This must only be
 * called once per page.
 *
 * To _read_ the layout state from context, call the `useLayoutStateContext`
 * hook instead.
 *
 * The main use case for using this rather than `<LayoutStateProvider />`
 * directly is the Player page which sometimes needs to update state during
 * render in response to query param changes, so it needs access to the state
 * setters.
 */
export function useLayoutStateProviderValue({
  currentSidebarId,
  setCurrentSidebarId,
}: SidebarStateContextValue): LayoutStateContextValue {
  const isMobileConfiguration =
    useScreenConfiguration() === ScreenConfiguration.Mobile;

  // Navigation defaults to open for desktop screen configuration. State is
  // synced with session storage to preserve it across view changes and page
  // refreshes.
  const [desktopNavigationOpen, setDesktopNavigationOpen] = useSessionStorage(
    DESKTOP_NAVIGATION_OPEN_STORAGE_KEY,
    true,
    Boolean,
  );
  // Navigation defaults to closed for mobile screen configuration.
  // Additionally, it's only kept in memory for mobile configuration since it
  // will be shown as a modal. It wouldn't be good UX for the nav to be open on
  // page refresh just because it was open prior to refresh
  const [mobileNavigationOpen, setMobileNavigationOpen] = useState(false);

  useEffect(
    function handleScreenConfigurationChange() {
      // Regardless of how the configuration changed, the mobile navigation
      // should always be closed. Especially important if it was open and the
      // user went from mobile -> desktop -> mobile: don't want it open when
      // they return to mobile configuration.
      setMobileNavigationOpen(false);

      if (isMobileConfiguration) {
        // Going from desktop -> mobile with the sidebar open should result
        // in it closing. Accomplishing this in an effect isn't ideal though as
        // it could cause a flash where the sidebar is open on screen only
        // to immediately close.
        setCurrentSidebarId(null);
      }
    },
    [isMobileConfiguration, setCurrentSidebarId],
  );

  let navigationOpen;
  let setNavigationOpen;
  if (isMobileConfiguration) {
    navigationOpen = mobileNavigationOpen;
    setNavigationOpen = setMobileNavigationOpen;
  } else {
    navigationOpen = desktopNavigationOpen;
    setNavigationOpen = setDesktopNavigationOpen;
  }

  return useMemo(
    () => ({
      navigationOpen,
      setNavigationOpen,
      currentSidebarId,
      setCurrentSidebarId,
    }),
    [navigationOpen, setNavigationOpen, currentSidebarId, setCurrentSidebarId],
  );
}

export function ControlledLayoutStateProvider({
  value,
  children,
}: {
  value: LayoutStateContextValue;
  children: React.ReactNode;
}) {
  return (
    <LayoutStateContext.Provider value={value}>
      {children}
    </LayoutStateContext.Provider>
  );
}

// Must be rendered above the <Layout /> component. Allows maintaining
// layout state even if <Layout /> gets remounted in a given view.
// TODO: See if there are better ways of handling layout in app
export function LayoutStateProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentSidebarId, setCurrentSidebarId] = useState<string | null>(null);

  const value = useLayoutStateProviderValue({
    currentSidebarId,
    setCurrentSidebarId,
  });

  return (
    <ControlledLayoutStateProvider value={value}>
      {children}
    </ControlledLayoutStateProvider>
  );
}

export const Root = styled("div")({
  flex: "1",
  minHeight: 0,
  display: "grid",
  gridTemplateAreas: `
    "nav header    header"
    "nav subheader subheader"
    "nav main      sidebar"
  `,
  gridTemplateColumns: "auto minmax(0, 1fr) auto",
  gridTemplateRows: "auto auto minmax(0, 1fr)",
});

const DESKTOP_NAVIGATION_OPEN_WIDTH_PX = 220;
const DESKTOP_NAVIGATION_CLOSED_WIDTH_PX = 64;
const DesktopNavigation = styled("div")(({ theme }) => ({
  gridArea: "nav",
  overflowX: "hidden",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  borderRight: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2, 1, 4),
  width: DESKTOP_NAVIGATION_CLOSED_WIDTH_PX,
  "&[data-open=true]": {
    width: DESKTOP_NAVIGATION_OPEN_WIDTH_PX,
  },
  [`& .${dividerClasses.root}`]: {
    marginBlock: theme.spacing(0.75),
  },
}));

const MobileNavigation = styled(Drawer)(({ theme }) => ({
  overflowX: "hidden",
  "& .MuiDrawer-paper": {
    width: DESKTOP_NAVIGATION_OPEN_WIDTH_PX,
    maxWidth: "80%",
    padding: theme.spacing(2, 1, 5),
  },
}));

export function Navigation({ children }: { children: React.ReactNode }) {
  const { navigationOpen, setNavigationOpen } = useLayoutStateContext();
  const isMobileConfiguration =
    useScreenConfiguration() === ScreenConfiguration.Mobile;

  return isMobileConfiguration ? (
    <MobileNavigation
      anchor="left"
      variant="temporary"
      transitionDuration={0}
      open={navigationOpen}
      onClose={() => setNavigationOpen(false)}
    >
      {children}
    </MobileNavigation>
  ) : (
    <DesktopNavigation data-open={navigationOpen}>{children}</DesktopNavigation>
  );
}

export function Header({
  title,
  actions,
}: {
  title: React.ReactNode;
  actions?: React.ReactNode;
}) {
  const { navigationOpen, setNavigationOpen } = useLayoutStateContext();

  return (
    <AppBar sx={{ gridArea: "header" }} position="static">
      <Toolbar
        variant="dense"
        sx={{ "& .MuiIconButton-root": { color: "inherit" } }}
      >
        <Tooltip title={navigationOpen ? "Close" : "Open"}>
          <IconButton
            sx={{ mr: 2 }}
            onClick={() => setNavigationOpen(!navigationOpen)}
          >
            {navigationOpen ? <MenuOpen /> : <Menu />}
          </IconButton>
        </Tooltip>
        <Typography variant="h6" component="h1" noWrap sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        {actions}
      </Toolbar>
    </AppBar>
  );
}

export const Subheader = styled("div")({
  gridArea: "subheader",
});

export const Main = styled("main")({
  gridArea: "main",
  display: "flex",
});

const SIDEBAR_WIDTH_PX = 400;
const DesktopSidebar = styled("div")(({ theme }) => ({
  gridArea: "sidebar",
  overflowX: "hidden",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  width: 0,
  // Sometimes the sidebar's children can't render for some reason but
  // weren't able to easily clear the current sidebar ID, e.g. the sidebar
  // ID needing to be closed in response to a specific type of navigation.
  // Instead, the children can render nothing - not even whitespace - and the
  // sidebar won't be visible since it'll match the :empty selector. The
  // <SidebarSwitch /> will still attempt to render the matching element for
  // the sidebar ID so the element should be resilient to that.
  "&[data-open=true]:not(:empty)": {
    width: SIDEBAR_WIDTH_PX,
    borderLeft: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 2, 5),
  },
}));

const MobileSidebar = styled(Drawer)(({ theme }) => ({
  overflowX: "hidden",
  "& .MuiDrawer-paper": {
    width: SIDEBAR_WIDTH_PX,
    maxWidth: "80%",
    padding: theme.spacing(2, 2, 5),
  },
}));

export function Sidebar({ children }: { children: React.ReactNode }) {
  const { currentSidebarId, setCurrentSidebarId } = useLayoutStateContext();
  const isMobileConfiguration =
    useScreenConfiguration() === ScreenConfiguration.Mobile;

  const isSidebarOpen = currentSidebarId !== null;

  return isMobileConfiguration ? (
    <MobileSidebar
      anchor="right"
      variant="temporary"
      transitionDuration={0}
      open={isSidebarOpen}
      onClose={() => setCurrentSidebarId(null)}
    >
      {children}
    </MobileSidebar>
  ) : (
    <DesktopSidebar data-open={isSidebarOpen}>{children}</DesktopSidebar>
  );
}

export function useSidebarTrigger(sidebarId: string): () => void {
  const { setCurrentSidebarId } = useLayoutStateContext();

  return useCallback(() => {
    setCurrentSidebarId((prevSidebarId) =>
      prevSidebarId === sidebarId ? null : sidebarId,
    );
  }, [setCurrentSidebarId, sidebarId]);
}

interface SidebarTriggerProps {
  title: string;
  sidebarId: string;
  icon: React.ReactNode;
}

export function SidebarTrigger({
  title,
  sidebarId,
  icon,
}: SidebarTriggerProps) {
  const handleClick = useSidebarTrigger(sidebarId);

  return (
    <Tooltip title={title}>
      <IconButton onClick={handleClick}>{icon}</IconButton>
    </Tooltip>
  );
}

interface SidebarOption {
  sidebarId: string;
  element: React.ReactNode;
}

interface SidebarSwitchProps {
  options: ReadonlyArray<SidebarOption>;
}

export function SidebarSwitch({ options }: SidebarSwitchProps) {
  const { currentSidebarId } = useLayoutStateContext();

  if (currentSidebarId === null) {
    return null;
  }

  // If a matching options is found, render its element. Otherwise render
  // nothing (though that shouldn't be expected to happen).
  return (
    options.find((option) => option.sidebarId === currentSidebarId)?.element ??
    null
  );
}

export function useScreenConfiguration(): ScreenConfiguration {
  const theme = useTheme();
  const isMobileConfiguration = useMediaQuery(theme.breakpoints.down("lg"), {
    noSsr: true,
  });

  return isMobileConfiguration
    ? ScreenConfiguration.Mobile
    : ScreenConfiguration.Desktop;
}
