import React, { useEffect, useMemo, useRef } from "react";
import { ArrowBack, Clear } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { z } from "zod";
import { BreakableText } from "~/components/BreakableText";
import { Details } from "~/components/Details";
import { TextField, useStudioForm } from "~/components/Form";
import { optionalObject, optionalText, optionalUuid } from "~/domain/common";
import { SidebarHeader, useLayoutStateContext } from "~/layout";
import { invariant } from "~/lib/invariant";
import { find, sortBy } from "~/lib/std";
import type { Digestion, Log, Topic } from "~/lqs";
import { WorkflowSelect } from "~/lqs";
import { DataStoreLink, makeDigestionLocation } from "~/paths";
import { getEventHandlerProps, pluralize } from "~/utils";
import { usePlayerActions } from "../../actions";
import { TopicTree } from "../../components/topic-tree";
import { usePlayerConfig, usePlayerTopics } from "../../hooks";
import { useFormatPlaybackTimestamp, usePlaybackSource } from "../../playback";
import { useCreateDigestion } from "../../queries";
import type { DraftDigestionTopic } from "../../types";
import TfStaticAlert from "./TfStaticAlert";
import type { DraftDigestion } from "./useDraftDigestion";
import {
  abortFinalizing,
  draftSelectedTopics,
  removeDraftTopic,
  resetDraft,
  selectLayoutTopics,
  setSelectedTopics,
  startFinalizing,
} from "./useDraftDigestion";
import { WorkflowContextField } from "./workflow-context-field";

const defaultFormValues = {
  name: null,
  workflowId: null,
  workflowContext: null,
};

export function DigestionSidebar({
  draftDigestion,
  createDigestion,
  form,
  workflowId,
}: { draftDigestion: DraftDigestion } & ReturnType<
  typeof useDigestionFinalizer
>) {
  const { logId } = usePlayerConfig();

  const topicsQuery = usePlayerTopics();

  const playbackSource = usePlaybackSource();
  const playerActions = usePlayerActions();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  const loading = playbackSource.isLoading || !topicsQuery.isSuccess;
  const areRangeEndsEqual =
    !playbackSource.isLoading &&
    playbackSource.range.startTime === playbackSource.range.endTime;

  const selectLayoutTopicsHandlerProps = getEventHandlerProps(
    "onClick",
    !loading &&
      !playbackSource.inRangeMode &&
      draftDigestion.canSelectLayoutTopics &&
      function handleSelectLayoutTopics() {
        draftDigestion.dispatch(selectLayoutTopics());
      },
  );

  // Must be memoized for <TopicTree />
  const { dispatch: draftDigestionDispatch } = draftDigestion;
  const topicSelectionHandlerProps = useMemo(
    () =>
      getEventHandlerProps(
        "onSelect",
        !loading &&
          !playbackSource.inRangeMode &&
          function handleTopicSelection(newSelection: ReadonlyArray<Topic>) {
            draftDigestionDispatch(setSelectedTopics({ topics: newSelection }));
          },
      ),
    [loading, playbackSource.inRangeMode, draftDigestionDispatch],
  );

  const manualTimeSelectionHandlerProps = getEventHandlerProps(
    "onClick",
    !loading &&
      function handleSelectTimeManually() {
        playerActions.enterPlaybackRangeMode();
      },
  );

  const timeSelectionConfirmationHandlerProps = getEventHandlerProps(
    "onClick",
    !areRangeEndsEqual &&
      function handleConfirmSelectedTime() {
        playerActions.exitPlaybackRangeMode();
      },
  );

  const addDraftTopicsHandlerProps = getEventHandlerProps(
    "onClick",
    !loading &&
      !playbackSource.inRangeMode &&
      draftDigestion.selectedTopicIds.length > 0 &&
      function handleAddDraftTopics() {
        draftDigestion.dispatch(draftSelectedTopics());
      },
  );

  const startFinalizingHandlerProps = getEventHandlerProps(
    "onClick",
    !loading &&
      !playbackSource.inRangeMode &&
      draftDigestion.topics.length > 0 &&
      function handleStartFinalization() {
        draftDigestion.dispatch(startFinalizing());
      },
  );

  const abortFinalizingHandlerProps = getEventHandlerProps(
    "onClick",
    !createDigestion.isLoading &&
      !createDigestion.isSuccess &&
      function handleAbortFinalizing() {
        draftDigestion.dispatch(abortFinalizing());
      },
  );

  const resetHandlerProps = getEventHandlerProps(
    "onClick",
    createDigestion.isSuccess &&
      function handleReset() {
        draftDigestion.dispatch(resetDraft());

        createDigestion.reset();
        form.reset(defaultFormValues);
      },
  );

  // Must be memoized for <TopicTree />
  const selectedTopics = useMemo(
    () =>
      topicsQuery.data?.filter((topic) =>
        draftDigestion.selectedTopicIds.includes(topic.id),
      ) ?? [],
    [topicsQuery.data, draftDigestion.selectedTopicIds],
  );

  if (!draftDigestion.isFinalizing) {
    return (
      <>
        <SidebarHeader title="Create a Digestion" />
        {renderOptionalAlert(logId)}
        <Stack spacing={5}>
          <div>
            <Typography variant="h6" component="p" gutterBottom>
              Select Topics
            </Typography>
            <Stack spacing={3}>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                fullWidth
                {...selectLayoutTopicsHandlerProps}
              >
                Select Layout Topics
              </Button>
              <TopicTree
                // Once topics are fetched, `topicsQuery.data` will always be
                // used and TanStack Query memoizes query data so this won't
                // break the tree's inner memoization when it matters, i.e. when
                // there are actually topics to display
                topics={topicsQuery.data ?? []}
                multiple
                selected={selectedTopics}
                {...topicSelectionHandlerProps}
              />
            </Stack>
          </div>
          <div>
            <Typography variant="h6" component="p" gutterBottom>
              Time Range
            </Typography>
            <Stack spacing={3}>
              {playbackSource.inRangeMode ? (
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    fullWidth
                    {...timeSelectionConfirmationHandlerProps}
                  >
                    Confirm Selection
                  </Button>
                  {areRangeEndsEqual && (
                    <Typography>Time range edges cannot be equal</Typography>
                  )}
                </div>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  fullWidth
                  {...manualTimeSelectionHandlerProps}
                >
                  Select Time Range
                </Button>
              )}
              <div>
                <Typography>Selected time range:</Typography>
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    "& pre": {
                      bgcolor: (theme) =>
                        theme.palette.mode === "light"
                          ? "grey.300"
                          : "grey.700",
                      border: 1,
                      borderColor: "divider",
                      borderRadius: 1,
                      m: 0,
                      px: 1,
                      py: 0.5,
                    },
                  }}
                >
                  <pre>
                    {loading
                      ? "-"
                      : formatPlaybackTimestamp(playbackSource.range.startTime)}
                  </pre>
                  -
                  <pre>
                    {loading
                      ? "-"
                      : formatPlaybackTimestamp(playbackSource.range.endTime)}
                  </pre>
                </Stack>
              </div>
            </Stack>
          </div>
          <div>
            <Typography variant="h6" gutterBottom>
              Add to Draft
            </Typography>
            <Typography paragraph>
              {draftDigestion.selectedTopicIds.length === 0
                ? "No topics selected"
                : pluralize(draftDigestion.selectedTopicIds.length, "topic") +
                  " will be added in the selected time range"}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              disableElevation
              {...addDraftTopicsHandlerProps}
            >
              Add to Draft
            </Button>
          </div>
          <div>
            <Typography variant="h6" component="p" gutterBottom>
              Digestion Summary
            </Typography>
            {logId === null ? (
              <Typography paragraph>
                Choose a log to start making a digestion
              </Typography>
            ) : loading ? (
              <Typography paragraph>Loading...</Typography>
            ) : draftDigestion.topics.length === 0 ? (
              <Typography paragraph>
                No topics added to this draft yet
              </Typography>
            ) : (
              <DraftDigestionList
                draftDigestionTopics={draftDigestion.topics}
                dispatch={draftDigestion.dispatch}
              />
            )}
            <Button
              color="primary"
              variant="contained"
              fullWidth
              {...startFinalizingHandlerProps}
            >
              Finalize Digestion
            </Button>
          </div>
        </Stack>
      </>
    );
  } else {
    return (
      <>
        <SidebarHeader title="Finalize Digestion" />
        <Button
          sx={{ mb: 2 }}
          color="primary"
          variant="text"
          startIcon={<ArrowBack />}
          {...abortFinalizingHandlerProps}
        >
          Back to Draft
        </Button>
        {!loading && (
          <TfStaticAlert
            playbackTopics={topicsQuery.data}
            playerBounds={playbackSource.bounds}
            draftDigestionTopics={draftDigestion.topics}
            dispatch={draftDigestion.dispatch}
          />
        )}
        <Typography mt={2}>
          The following topics will be extracted in the time ranges you chose:
        </Typography>
        <DraftDigestionList
          disableRemove
          draftDigestionTopics={draftDigestion.topics}
          dispatch={draftDigestion.dispatch}
        />
        <form onSubmit={form.handleSubmit}>
          <Typography paragraph>
            You can provide a name for the digestion to help you identify it
            later:
          </Typography>
          <TextField control={form.control} name="name" />
          <Details>
            <Details.Summary paragraph>Workflow Options</Details.Summary>
            <Stack spacing={2}>
              <WorkflowSelect control={form.control} name="workflowId" />
              <WorkflowContextField
                key={workflowId}
                workflowId={workflowId}
                control={form.control}
                name="workflowContext"
              />
            </Stack>
          </Details>
          <LoadingButton
            sx={{ mt: 2, mb: 4 }}
            color="primary"
            variant="contained"
            fullWidth
            loading={createDigestion.isLoading}
            disabled={createDigestion.isSuccess}
            type="submit"
          >
            Submit Digestion
          </LoadingButton>
        </form>
        {createDigestion.isError && (
          <Alert severity="error" variant="filled">
            Failed to create the digestion
          </Alert>
        )}
        {createDigestion.isSuccess && (
          <>
            <SuccessAlert digestionId={createDigestion.data.data.id} />
            <Button
              sx={{ mt: 2 }}
              color="primary"
              variant="text"
              {...resetHandlerProps}
            >
              Start New Digestion
            </Button>
          </>
        )}
      </>
    );
  }
}

function SuccessAlert({ digestionId }: { digestionId: Digestion["id"] }) {
  return (
    <Alert severity="success" variant="filled">
      <Link
        component={DataStoreLink}
        to={makeDigestionLocation({ digestionId })}
        color="inherit"
      >
        View your digestion's details
      </Link>
    </Alert>
  );
}

function DraftDigestionList({
  disableRemove = false,
  draftDigestionTopics,
  dispatch,
}: {
  disableRemove?: boolean;
  draftDigestionTopics: DraftDigestionTopic[];
  dispatch: DraftDigestion["dispatch"];
}) {
  const topicsQuery = usePlayerTopics();
  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  function makeRemoveDraftTopicHandler(draftTopic: DraftDigestionTopic) {
    return function handleRemoveDraftTopic() {
      dispatch(removeDraftTopic({ topic: draftTopic }));
    };
  }

  return (
    <List>
      {sortBy(Array.from(groupDrafts(draftDigestionTopics).entries()), "0").map(
        ([topicId, drafts]) => {
          const topic = find(topicsQuery.data, { id: topicId });

          invariant(topic !== undefined, "Topic not found");

          return (
            <ListItem
              key={topicId}
              sx={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              <ListItemText>
                <BreakableText separator={/(\/)/}>{topic.name}</BreakableText>
              </ListItemText>
              <List sx={{ alignSelf: "stretch" }}>
                {sortBy(drafts, "startTime").map((draft) => (
                  <ListItem
                    key={draft.startTime}
                    secondaryAction={
                      disableRemove ? undefined : (
                        <Tooltip title="Remove this time range">
                          <IconButton
                            onClick={makeRemoveDraftTopicHandler(draft)}
                          >
                            <Clear />
                          </IconButton>
                        </Tooltip>
                      )
                    }
                  >
                    <ListItemText>
                      {formatPlaybackTimestamp(draft.startTime)}
                      {" - "}
                      {formatPlaybackTimestamp(draft.endTime)}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </ListItem>
          );
        },
      )}
    </List>
  );
}

function groupDrafts(
  drafts: ReadonlyArray<DraftDigestionTopic>,
): Map<DraftDigestionTopic["topicId"], Array<DraftDigestionTopic>> {
  const draftMap = new Map<
    DraftDigestionTopic["topicId"],
    Array<DraftDigestionTopic>
  >();

  drafts.forEach((draft) => {
    if (!draftMap.has(draft.topicId)) {
      draftMap.set(draft.topicId, []);
    }

    draftMap.get(draft.topicId)!.push(draft);
  });

  return draftMap;
}

function renderOptionalAlert(logId: Log["id"] | null) {
  if (logId === null) {
    return (
      <Alert severity="info" variant="filled" sx={{ mb: 2 }}>
        Choose a log to create a digestion
      </Alert>
    );
  }
}

export function useDigestionFinalizer(
  draftDigestionTopics: ReadonlyArray<DraftDigestionTopic>,
) {
  const { logId } = usePlayerConfig();

  const { currentSidebarId } = useLayoutStateContext();

  // Since <DigestionSidebar /> is always mounted even if not visible, there
  // needs to be a way to let the user know their digestion is done if they
  // close the drawer while the mutation is ongoing. This ref is used by the
  // mutation callbacks to show a snackbar if the mutation settles while the
  // drawer is closed.
  const isDrawerOpenRef = useRef(currentSidebarId === "digestions");
  useEffect(
    function updateDrawerOpenRef() {
      isDrawerOpenRef.current = currentSidebarId === "digestions";
    },
    [currentSidebarId],
  );

  const { enqueueSnackbar } = useSnackbar();

  const createDigestion = useCreateDigestion();

  const form = useStudioForm({
    schema: z.object({
      name: optionalText,
      workflowId: optionalUuid,
      workflowContext: optionalObject,
    }),
    defaultValues: defaultFormValues,
    onSubmit(values) {
      invariant(logId !== null, "Log ID cannot be null");

      createDigestion.mutate(
        {
          logId,
          draftDigestionTopics,
          name: values.name,
          workflowId: values.workflowId,
          workflowContext: values.workflowContext,
        },
        {
          onSuccess() {
            if (isDrawerOpenRef.current) {
              return;
            }

            enqueueSnackbar(
              "Digestion created! Open the digestion sidebar for more details",
              { variant: "success" },
            );
          },
          onError() {
            if (isDrawerOpenRef.current) {
              return;
            }

            enqueueSnackbar(
              "Couldn't create digestion. Open the digestion sidebar for more details",
              { variant: "error" },
            );
          },
        },
      );
    },
  });

  // The workflow context field will need the selected workflow's ID to
  // fetch its context schema
  const workflowId = form.watch("workflowId");

  return {
    createDigestion,
    workflowId,
    form,
  };
}
