import { SortDirection } from "~/components/Table";
import { useTags } from "~/lqs";
import { usePlayerConfig } from "../hooks";
import { useTagFilters } from "./store";

export interface PlaybackTag {
  id: string;
  timestamp: bigint;
}

export function usePlaybackTags() {
  const { logId } = usePlayerConfig();

  const tagFilters = useTagFilters();

  const filtersExist = tagFilters !== null;

  return useTags(
    logId,
    {
      ...tagFilters,
      startTimeNull: false,
      endTimeNull: true,
      limit: 100,
      sort: SortDirection.Asc,
      order: "start_time",
    },
    {
      enabled: filtersExist,
      select({ data }): ReadonlyArray<PlaybackTag> {
        return data.map((tag) => ({
          id: tag.id,
          timestamp: tag.startTime!,
        }));
      },
    },
  );
}
