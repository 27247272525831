import { Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import {
  LqsResourceFields,
  useObjectStoreObjectQueryOptionsFactory,
} from "~/lqs";
import { useObjectStoreObjectParams } from "~/paths";
import { selectData } from "~/utils";

export function ObjectStoreObjectDetails() {
  const { objectStoreId, objectKey } = useObjectStoreObjectParams();

  const createObjectStoreObjectQueryOptions =
    useObjectStoreObjectQueryOptionsFactory();

  const query = useQuery({
    ...createObjectStoreObjectQueryOptions(objectStoreId, objectKey),
    select: selectData,
  });

  const presignedUrl = query.data?.presignedUrl;

  return (
    <Card
      title="General"
      action={
        <Button
          variant="contained"
          {...(presignedUrl == null
            ? { disabled: true }
            : { href: presignedUrl })}
        >
          Download
        </Button>
      }
    >
      <LqsResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "key" },
          { dataType: "text", accessor: "etag" },
          { dataType: "bytes", accessor: "size" },
          { dataType: "text", accessor: "uploadState" },
          { dataType: "datetime", accessor: "lastModified" },
        ]}
      />
    </Card>
  );
}
