import React from "react";
import { CommonResourcesContextProvider } from "~/domain/common";
import {
  makeEditUserLocation,
  makeNewUserLocation,
  makeRoleLocation,
  makeUserLocation,
  makeUsersLocation,
  useNormalizeDataStorePath,
} from "~/paths";
import {
  useCurrentUserQueryOptionsFactory,
  useRoleQueryOptionsFactory,
  useRolesQueryOptionsFactory,
  useUserCreateMutationOptionsFactory,
  useUserDeleteMutationOptionsFactory,
  useUserQueryOptionsFactory,
  useUsersQueryOptionsFactory,
  useUserUpdateMutationOptionsFactory,
} from "./api";

export function LqsCommonResourcesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const createCurrentUserQueryOptions = useCurrentUserQueryOptionsFactory();

  const createRoleListQueryOptions = useRolesQueryOptionsFactory();
  const createRoleFetchQueryOptions = useRoleQueryOptionsFactory();

  const createUserListQueryOptions = useUsersQueryOptionsFactory();
  const createUserFetchQueryOptions = useUserQueryOptionsFactory();
  const createUserCreateMutationOptions = useUserCreateMutationOptionsFactory();
  const createUserUpdateMutationOptions = useUserUpdateMutationOptionsFactory();
  const createUserDeleteMutationOptions = useUserDeleteMutationOptionsFactory();

  const normalizeDataStorePath = useNormalizeDataStorePath();

  return (
    <CommonResourcesContextProvider
      value={{
        createCurrentUserQueryOptions,
        createRoleListQueryOptions,
        createRoleFetchQueryOptions,
        createRoleDetailsLocation: (params) =>
          normalizeDataStorePath(makeRoleLocation(params)),
        createUserListQueryOptions,
        createUserFetchQueryOptions,
        createUserCreateMutationOptions,
        createUserUpdateMutationOptions,
        createUserDeleteMutationOptions,
        userTableLocation: normalizeDataStorePath(makeUsersLocation()),
        userCreateLocation: normalizeDataStorePath(makeNewUserLocation()),
        createUserDetailsLocation: (params) =>
          normalizeDataStorePath(makeUserLocation(params)),
        createUserEditLocation: (params) =>
          normalizeDataStorePath(makeEditUserLocation(params)),
      }}
    >
      {children}
    </CommonResourcesContextProvider>
  );
}
