import { LOGQS_ICON, LOGQS_LOGO } from "~/links";

export interface LogoProps {
  icon?: boolean;
  alt?: string;
}

export function Logo({ icon = false, alt = "LogQS Icon" }: LogoProps) {
  return (
    <img
      // Forces react to re-mount the image rather than reusing. When toggling
      // between the icon and full-size images, there won't be a quick flash
      // of the old image when the new one is expected
      key={String(icon)}
      className="logqs-logo"
      src={icon ? LOGQS_ICON : LOGQS_LOGO}
      alt={alt}
    />
  );
}
