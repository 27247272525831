import React from "react";
import { Box, Chip, chipClasses, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { renderQuery } from "~/components/QueryRenderer";
import { useDeleteTag, useLabel } from "~/lqs";
import type { Log, Tag } from "~/lqs";
import { selectData } from "~/utils";

export function LogTagChip({
  log,
  tag,
  active = false,
  bulkManaging = false,
}: {
  log: Log;
  tag: Tag;
  active?: boolean;
  bulkManaging?: boolean;
}) {
  const labelQuery = useLabel(tag.labelId, { select: selectData });

  const { enqueueSnackbar } = useSnackbar();

  const deleteTagMutation = useDeleteTag(log.id, tag.id);

  function handleDeleteTag(): void {
    deleteTagMutation.mutate(undefined, {
      onError() {
        enqueueSnackbar("Unable to remove tag", { variant: "error" });
      },
    });
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Chip
        sx={{
          ...(!labelQuery.isSuccess && {
            [`& .${chipClasses.label}`]: {
              fontStyle: "italic",
            },
          }),
        }}
        variant={labelQuery.isSuccess ? "filled" : "outlined"}
        color={active ? "primary" : "default"}
        label={renderQuery(labelQuery, {
          loading: "Loading...",
          error: "Unknown",
          success: (label) => label.value,
        })}
        {...(!bulkManaging && {
          disabled: !labelQuery.isSuccess || deleteTagMutation.isLoading,
          onDelete: handleDeleteTag,
        })}
      />
      {deleteTagMutation.isLoading && (
        <CircularProgress
          size="1rem"
          sx={{
            position: "absolute",
            inset: 0,
            margin: "auto",
          }}
        />
      )}
    </Box>
  );
}
