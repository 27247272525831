import type { OverrideProperties } from "type-fest";
import type { ManageCardProps } from "~/components/DetailsCards";
import { ManageCard } from "~/components/DetailsCards";
import { useNormalizeDataStorePath } from "~/paths";
import type { DataStorePathGenerator } from "~/paths";

type LqsManageCardProps<TResource extends object> = OverrideProperties<
  ManageCardProps<TResource>,
  {
    editLocation?: DataStorePathGenerator;
    listLocation: DataStorePathGenerator;
  }
>;

export function LqsManageCard<TResource extends object>({
  editLocation,
  listLocation,
  ...rest
}: LqsManageCardProps<TResource>) {
  const normalizeDataStorePath = useNormalizeDataStorePath();

  return (
    <ManageCard
      {...rest}
      editLocation={
        editLocation === undefined
          ? undefined
          : normalizeDataStorePath(editLocation)
      }
      listLocation={normalizeDataStorePath(listLocation)}
    />
  );
}
