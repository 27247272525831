import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { UserCreatePage } from "~/domain/common";
import { LqsNavigation } from "~/lqs";

export function LqsUserCreatePage() {
  return (
    <UserCreatePage
      navigation={<LqsNavigation />}
      breadcrumbs={<DataStoreBreadcrumbs />}
    />
  );
}
