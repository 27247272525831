import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import type { Label, Log, Tag, TagListResponse } from "~/lqs";
import { useTags } from "~/lqs";
import type { Maybe } from "~/types";

function useLogLevelTagsImpl<TData>(
  log: Maybe<Log>,
  options: Required<
    Pick<UseQueryOptions<TagListResponse, unknown, TData>, "select">
  >,
): UseQueryResult<TData> {
  return useTags(
    log?.id ?? null,
    // TODO: Add `topicIdNull` filter when available
    {
      order: "created_at",
      sort: "asc",
      startTimeNull: true,
      endTimeNull: true,
      // TODO: Consider fetching all tags or having some form of pagination
      limit: 500,
    },
    options,
  );
}

/**
 * List all log-level tags for a log. A log-level tag is one with no start time,
 * end time or topic ID, so it's considered to apply to the log as a whole.
 */
export function useLogLevelTags(log: Maybe<Log>): UseQueryResult<Array<Tag>> {
  return useLogLevelTagsImpl(log, {
    select(response) {
      // TODO: Until the tag list endpoint provides a `topicIdNull` filter
      //       ignore any tags with associated topics
      return response.data.filter((tag) => tag.topicId === null);
    },
  });
}

export function useLogLevelTag(
  log: Maybe<Log>,
  labelId: Maybe<Label["id"]>,
): UseQueryResult<Tag | null> {
  return useLogLevelTagsImpl(log, {
    select(response) {
      if (labelId == null) {
        return null;
      }

      return (
        response.data.find(
          (tag) =>
            // TODO: Until the tag list endpoint provides a `topicIdNull` filter
            //       ignore any tags with associated topics
            tag.topicId === null && tag.labelId === labelId,
        ) ?? null
      );
    },
  });
}
