import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Card } from "~/components/Card";
import { DetailsLayout } from "~/components/DetailsCards";
import {
  CheckboxField,
  Form,
  FormSkeleton,
  getChangedFields,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { QueryRenderer } from "~/components/QueryRenderer";
import { pick } from "~/lib/std";
import type { Role } from "~/lqs";
import {
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteRole,
  useRole,
  useUpdateRole,
} from "~/lqs";
import {
  makeEditRoleLocation,
  makeRolesLocation,
  useRoleParams,
} from "~/paths";
import { selectData } from "~/utils";
import { editRoleSchema } from "../schemas";

export function RoleDetails() {
  const { roleId } = useRoleParams();

  const query = useRole(roleId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const infoSection = <InfoSection query={query} />;
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="role"
      query={query}
      editLocation={makeEditRoleLocation({ roleId })}
      deleteMutation={useDeleteRole(roleId)}
      getReadableName={(role) => role.name}
      listLocation={makeRolesLocation()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={generalSection}
      secondaryGridColumn={
        <>
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Role> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "text", accessor: "name" },
          { dataType: "boolean", accessor: "managed" },
          { dataType: "json", accessor: "policy" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<Role> }) {
  return (
    <Card>
      <QueryRenderer
        query={query}
        loading={<FormSkeleton shapes={["multiline", "text", "text"]} />}
        success={(role) => <InfoSectionImpl role={role} />}
      />
    </Card>
  );
}

function InfoSectionImpl({ role }: { role: Role }) {
  const updateRole = useUpdateRole(role.id);

  const { enqueueSnackbar } = useSnackbar();

  const schema = editRoleSchema.pick({
    note: true,
    _default: true,
    disabled: true,
  });
  const FIELDS = schema.keyof().options;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useStudioForm({
    schema,
    defaultValues: pick(role, FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateRole.mutate(changedFields, {
        onSuccess(response) {
          enqueueSnackbar("Role updated", { variant: "success" });

          reset(pick(response.data, FIELDS));
        },
        onError() {
          enqueueSnackbar("Unable to update role", { variant: "error" });
        },
      });
    },
  });

  return (
    <Form
      onSubmit={handleSubmit}
      loading={updateRole.isLoading}
      submitText="Save Changes"
    >
      <TextField control={control} name="note" multiline />
      <CheckboxField control={control} name="_default" />
      <CheckboxField control={control} name="disabled" />
    </Form>
  );
}
