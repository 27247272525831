import React from "react";
import { LocalOffer, Settings } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Skeleton, Stack, Typography } from "@mui/material";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { FileCloud, ScissorsCutting } from "mdi-material-ui";
import { Center } from "~/components/Center";
import { Helmet } from "~/components/Helmet";
import { ErrorMessage } from "~/components/error-message";
import * as Layout from "~/layout";
import { SidebarTrigger } from "~/layout";
import { LqsNavigation } from "~/lqs";
import PlaybackController from "./components/PlaybackController";
import TopicSection from "./components/TopicSection";
import {
  useInitialLayoutProfile,
  usePlayerConfig,
  usePlayerLog,
} from "./hooks";
import { OnDemandInferenceProvider } from "./inference";
import { PlayerLayoutStateProvider } from "./layout";
import { PanelLayoutProvider } from "./panels";
import {
  LogPlaybackSourceProvider,
  PlaybackSettingsProvider,
  PlaybackTimerProvider,
} from "./playback";
import { RecordStoreProvider } from "./record-store";
import { PlayerSidebar } from "./sidebar";
import { TagStoreProvider } from "./tags";
import { logIsBounded, logIsTooLong } from "./utils";
import { ThreeDControlsProvider } from "./visualizations";

export function PlayerPage() {
  const { logId } = usePlayerConfig();

  const logQuery = usePlayerLog();

  const initialLayoutProfile = useInitialLayoutProfile();

  let content;
  if (logId == null) {
    content = (
      <Center>
        <Typography variant="h5" component="p" paragraph sx={{ px: 4 }}>
          To get started, search for a log in the side panel to the right
        </Typography>
      </Center>
    );
  } else if (logQuery.isSuccess) {
    if (!logIsBounded(logQuery.data)) {
      content = (
        <Center>
          <Typography variant="h5" component="p" paragraph>
            This log doesn't have any records to play yet
          </Typography>
          <LoadingButton
            variant="contained"
            loading={logQuery.isRefetching}
            onClick={() => logQuery.refetch()}
          >
            Refresh
          </LoadingButton>
        </Center>
      );
    } else if (logIsTooLong(logQuery.data)) {
      content = (
        <ErrorMessage disableTypography>
          <ErrorMessage.Paragraph>
            This log is too long to play.
          </ErrorMessage.Paragraph>
          <ErrorMessage.Paragraph>
            Studio only supports logs less than 24 hours long.
          </ErrorMessage.Paragraph>
        </ErrorMessage>
      );
    } else {
      content = <TopicSection />;
    }
  } else {
    content = <TopicSection />;
  }

  const title =
    logId === null ? (
      "Player"
    ) : logQuery.isSuccess ? (
      `Player - ${logQuery.data.name}`
    ) : (
      <>
        Player - <Skeleton width="15ch" sx={{ display: "inline-block" }} />
      </>
    );

  const hasLogId = logId != null;

  return (
    <>
      <Helmet>
        <title>Player</title>
      </Helmet>
      <PlayerLayoutStateProvider>
        <Layout.Root>
          <Layout.Navigation>
            <LqsNavigation />
          </Layout.Navigation>
          <Layout.Header
            title={title}
            actions={
              <Stack direction="row" spacing={1}>
                <SidebarTrigger
                  title="Logs"
                  sidebarId="logs"
                  icon={<FileCloud />}
                />
                <SidebarTrigger
                  title="Tagging"
                  sidebarId="tagging"
                  icon={<LocalOffer />}
                />
                <SidebarTrigger
                  title="Create a digestion"
                  sidebarId="digestions"
                  icon={<ScissorsCutting />}
                />
                <SidebarTrigger
                  title="Settings"
                  sidebarId="settings"
                  icon={<Settings />}
                />
              </Stack>
            }
          />
          <PlaybackSettingsProvider>
            <PanelLayoutProvider
              // This will reset all panel layout state when switching between
              // there being some log selected and no log selected. It's
              // important for the key not to change when switching between
              // logs since keeping the same panels open is a supported use
              // case. This value should only change in a few situations:
              // 1. Selecting a log for the first time on the player page
              //    (`false` -> `true`)
              // 2. Clicking the browser's back button after situation (1) or
              //    otherwise traversing session history to an entry where the
              //    URL doesn't have a log ID (`true` -> `false`)
              // 3. Clicking the "Player" link in the global navigation when
              //    a log is currently selected (`true` -> `false`)
              key={String(hasLogId)}
              initialLayout={initialLayoutProfile?.layout}
            >
              <LogPlaybackSourceProvider>
                <RecordStoreProvider>
                  <TagStoreProvider>
                    <PlaybackTimerProvider>
                      <ThreeDControlsProvider>
                        <OnDemandInferenceProvider>
                          <Layout.Main>
                            <Stack sx={{ flex: 1, minWidth: 0 }}>
                              {content}
                              <PlaybackController />
                            </Stack>
                          </Layout.Main>
                          <Layout.Sidebar>
                            <PlayerSidebar />
                          </Layout.Sidebar>
                        </OnDemandInferenceProvider>
                      </ThreeDControlsProvider>
                    </PlaybackTimerProvider>
                  </TagStoreProvider>
                </RecordStoreProvider>
              </LogPlaybackSourceProvider>
            </PanelLayoutProvider>
          </PlaybackSettingsProvider>
        </Layout.Root>
      </PlayerLayoutStateProvider>
      <ReactQueryDevtools position="bottom-left" />
    </>
  );
}
