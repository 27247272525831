import React from "react";
import { Error as ErrorIcon } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  LinearProgress,
  Skeleton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Card } from "~/components/Card";
import { useStudioForm } from "~/components/Form";
import { Loading } from "~/components/Loading";
import { renderQuery } from "~/components/QueryRenderer";
import { ErrorMessage } from "~/components/error-message";
import { selectCountableListResponse } from "~/domain/common";
import {
  filterSchema,
  getSortParams,
  GroupsField,
  LabelsField,
  LogTagChip,
  LogThumbnailCard,
  NameField,
  PaginationControls,
  PlayableLogsField,
  SortField,
  useLogLevelTag,
  useLogLevelTags,
  useLogsSearchRequest,
} from "~/domain/logs";
import type { Label, Log, Tag } from "~/lqs";
import { useCreateTag, useDeleteTag, useLogsQueryOptionsFactory } from "~/lqs";
import type { Maybe } from "~/types";
import { getEventHandlerProps, pluralize } from "~/utils";

const Root = styled(Card)({
  gridArea: "logs",
});

export function LogsSection({
  selectedLabelId,
}: {
  selectedLabelId: Label["id"] | null;
}) {
  const [request, setRequest] = useLogsSearchRequest({ prefix: true });

  const createLogsQueryOptions = useLogsQueryOptionsFactory();
  const logsQuery = useQuery({
    ...createLogsQueryOptions({
      nameLike: request.name,
      startTimeNull: request.playable ? false : undefined,
      limit: request.limit,
      offset: request.offset,
      groupId: request.groupIds,
      tagLabelIdsIncludes: request.labelIds,
      ...getSortParams(request),
      includeCount: true,
    }),
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const { control, handleSubmit } = useStudioForm({
    schema: filterSchema,
    values: { name: request.name },
    onSubmit: setRequest,
  });

  return (
    <Root title="Logs">
      <Stack spacing={3}>
        <Stack spacing={1.5}>
          <Stack direction="row" spacing={1.5} sx={{ flex: "none" }}>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ flex: "auto" }}
            >
              <NameField control={control} />
            </Box>
            <SortField request={request} setRequest={setRequest} />
          </Stack>
          <Stack direction="row" spacing={1.5} sx={{ flex: "none" }}>
            <Box sx={{ minWidth: 0, flex: "1 0" }}>
              <GroupsField request={request} setRequest={setRequest} />
            </Box>
            <Box sx={{ minWidth: 0, flex: "1 0" }}>
              <LabelsField request={request} setRequest={setRequest} />
            </Box>
          </Stack>
          <Box>
            <PlayableLogsField request={request} setRequest={setRequest} />
          </Box>
        </Stack>
        <Box sx={{ position: "relative" }}>
          <Divider />
          {logsQuery.isRefetching && (
            <LinearProgress
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
              }}
            />
          )}
        </Box>
        {renderQuery(logsQuery, {
          loading: <Loading type="circular" />,
          error: <ErrorMessage>Unable to load logs</ErrorMessage>,
          success(response) {
            return (
              <Stack spacing={2}>
                <Typography>{pluralize(response.count, "log")}</Typography>
                {response.data.map((log) => (
                  <LogListItem
                    key={log.id}
                    log={log}
                    selectedLabelId={selectedLabelId}
                  />
                ))}
              </Stack>
            );
          },
        })}
        {logsQuery.isSuccess && (
          <>
            <Divider />
            <PaginationControls
              disableJumping
              logCount={logsQuery.data.count}
              request={request}
              setRequest={setRequest}
            />
          </>
        )}
      </Stack>
    </Root>
  );
}

function LogListItem({
  log,
  selectedLabelId,
}: {
  log: Log;
  selectedLabelId: Label["id"] | null;
}) {
  const tagQuery = useLogLevelTag(log, selectedLabelId);

  const { enqueueSnackbar } = useSnackbar();

  const createTagMutation = useCreateTag(log.id);
  const deleteTagMutation = useDeleteTag(log.id);

  const bulkManaging = selectedLabelId !== null;

  const toggleTagEnabled =
    bulkManaging &&
    tagQuery.isSuccess &&
    !createTagMutation.isLoading &&
    !deleteTagMutation.isLoading;

  const tagToggleHandlerProps = getEventHandlerProps(
    "onChange",
    toggleTagEnabled &&
      function handleToggleTag() {
        if (tagQuery.data !== null) {
          // Delete existing tag
          deleteTagMutation.mutate(tagQuery.data.id, {
            onError() {
              enqueueSnackbar("Unable to remove tag", { variant: "error" });
            },
          });

          createTagMutation.reset();
        } else {
          // Create new tag
          createTagMutation.mutate(
            { labelId: selectedLabelId },
            {
              onError() {
                enqueueSnackbar("Unable to tag log", { variant: "error" });
              },
            },
          );

          deleteTagMutation.reset();
        }
      },
  );

  return (
    <Stack direction="row" spacing={1}>
      {bulkManaging && (
        <Box sx={{ alignSelf: "center", position: "relative" }}>
          <Checkbox
            checked={tagQuery.isSuccess && tagQuery.data !== null}
            {...tagToggleHandlerProps}
          />
          {(createTagMutation.isLoading || deleteTagMutation.isLoading) && (
            <CircularProgress
              size="2rem"
              sx={{ position: "absolute", inset: 0, margin: "auto" }}
            />
          )}
        </Box>
      )}
      <LogThumbnailCard
        log={log}
        dense
        tags={
          <Tags
            log={log}
            activeTag={tagQuery.data}
            bulkManaging={bulkManaging}
          />
        }
      />
    </Stack>
  );
}

function Tags({
  log,
  activeTag,
  bulkManaging,
}: {
  log: Log;
  activeTag: Maybe<Tag>;
  bulkManaging: boolean;
}) {
  const tagsQuery = useLogLevelTags(log);

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ flexWrap: tagsQuery.status === "error" ? "nowrap" : "wrap" }}
    >
      {renderQuery(tagsQuery, {
        loading: [1, 2, 3].map((key) => (
          <Skeleton
            key={key}
            variant="rectangular"
            width="8ch"
            height={32}
            sx={{ borderRadius: 4 }}
          />
        )),
        error: (
          <>
            <ErrorIcon color="error" />
            <Typography>
              Couldn't load this log's tags. Adding and removing tags is
              disabled.
            </Typography>
          </>
        ),
        success(tags) {
          return tags.map((tag) => (
            <LogTagChip
              key={tag.id}
              log={log}
              tag={tag}
              active={tag.id === activeTag?.id}
              bulkManaging={bulkManaging}
            />
          ));
        },
      })}
    </Stack>
  );
}
