import { SidebarSwitch } from "~/layout";
import { SettingsSidebar } from "~/settings";
import { usePlayerTopics } from "../hooks";
import { usePanelLayoutContext } from "../panels";
import { usePlaybackSource } from "../playback";
import {
  DigestionSidebar,
  useDigestionFinalizer,
  useDraftDigestion,
} from "./DigestionSidebar";
import LogSidebar from "./LogSidebar";
import PlayerSettings from "./PlayerSettings";
import { PanelControlsSidebar } from "./panel-controls-sidebar";
import { TaggingSidebar } from "./tagging-sidebar";

export default function PlayerSidebar() {
  const topicsQuery = usePlayerTopics();

  const playbackSource = usePlaybackSource();

  const { layout } = usePanelLayoutContext();

  const draftDigestion = useDraftDigestion({
    playerTopics: topicsQuery.data,
    playerRange: playbackSource.range,
    layout,
  });

  const { createDigestion, workflowId, form } = useDigestionFinalizer(
    draftDigestion.topics,
  );

  return (
    <SidebarSwitch
      options={[
        {
          sidebarId: "logs",
          element: <LogSidebar />,
        },
        {
          sidebarId: "digestions",
          element: (
            <DigestionSidebar
              draftDigestion={draftDigestion}
              createDigestion={createDigestion}
              form={form}
              workflowId={workflowId}
            />
          ),
        },
        {
          sidebarId: "settings",
          element: (
            <SettingsSidebar>
              <PlayerSettings />
            </SettingsSidebar>
          ),
        },
        {
          sidebarId: "panel-controls",
          element: <PanelControlsSidebar />,
        },
        {
          sidebarId: "tagging",
          element: <TaggingSidebar />,
        },
      ]}
    />
  );
}
