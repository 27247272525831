import React, { useState } from "react";
import { ViewComfy } from "@mui/icons-material";
import {
  Divider,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { usePlayerActions } from "../actions";
import type { LayoutProfile } from "../panels";
import { useDataStoreLayoutProfiles, useLayoutProfiles } from "../panels";
import { usePlaybackSource } from "../playback";
import { ProfileDialog } from "./profile-dialog";

export function LayoutProfilesMenu() {
  const [dialogOpen, setDialogOpen] = useState(false);

  const playbackSource = usePlaybackSource();

  const playerActions = usePlayerActions();

  const dataStoreLayoutProfiles = useDataStoreLayoutProfiles();
  const layoutProfilesQuery = useLayoutProfiles();

  const profilesMenuState = usePopupState({
    variant: "popover",
    popupId: "profiles-menu",
  });

  function handleOpenMenu() {
    setDialogOpen(true);

    profilesMenuState.close();
  }

  function renderProfileMenuItem(profile: LayoutProfile) {
    function handleSelectLayout() {
      playerActions.loadLayout(profile.layout);
      profilesMenuState.close();
    }

    return (
      <MenuItem key={profile.name} onClick={handleSelectLayout}>
        {profile.name}
      </MenuItem>
    );
  }

  return (
    <>
      <Tooltip title="Layout profiles">
        <span>
          <IconButton
            disabled={
              playbackSource.isLoading || !layoutProfilesQuery.isSuccess
            }
            size="large"
            {...bindTrigger(profilesMenuState)}
          >
            <ViewComfy />
          </IconButton>
        </span>
      </Tooltip>
      {layoutProfilesQuery.isSuccess && (
        <Menu {...bindMenu(profilesMenuState)}>
          {dataStoreLayoutProfiles.length > 0 && (
            <ListSubheader sx={{ lineHeight: 2 }} disableSticky>
              DataStore Layouts
            </ListSubheader>
          )}
          {dataStoreLayoutProfiles.map(renderProfileMenuItem)}
          {dataStoreLayoutProfiles.length > 0 && (
            <Divider component="li" sx={{ my: 1 }} />
          )}
          {layoutProfilesQuery.data.length > 0 && (
            <ListSubheader sx={{ lineHeight: 2 }} disableSticky>
              Your Layouts
            </ListSubheader>
          )}
          {layoutProfilesQuery.data.map(renderProfileMenuItem)}
          {layoutProfilesQuery.data.length > 0 && (
            <Divider component="li" sx={{ my: 1 }} />
          )}
          <MenuItem onClick={handleOpenMenu}>Manage Profiles...</MenuItem>
        </Menu>
      )}
      <ProfileDialog open={dialogOpen} setOpen={setDialogOpen} />
    </>
  );
}
