import { Button } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import { DetailsLayout } from "~/components/DetailsCards";
import type { CloudObject } from "~/lqs";
import {
  LqsManageCard,
  LqsResourceFields,
  useDeleteLogObject,
  useLogObject,
} from "~/lqs";
import { makeLogObjectsLocation, useLogObjectParams } from "~/paths";
import { selectData } from "~/utils";

export function LogObjectDetails() {
  const { logId, key } = useLogObjectParams();

  const query = useLogObject(logId, key, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="object"
      query={query}
      deleteMutation={useDeleteLogObject(logId, key)}
      getReadableName={(logObject) => logObject.key}
      listLocation={makeLogObjectsLocation(logId)}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={generalSection}
      secondaryGridColumn={manageSection}
      stack={
        <>
          {generalSection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<CloudObject> }) {
  const presignedUrl = query.data?.presignedUrl;

  return (
    <Card
      title="General"
      action={
        <Button
          variant="contained"
          {...(presignedUrl == null
            ? { disabled: true }
            : { href: presignedUrl })}
        >
          Download
        </Button>
      }
    >
      <LqsResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "key" },
          { dataType: "text", accessor: "etag" },
          { dataType: "bytes", accessor: "size" },
          { dataType: "text", accessor: "uploadState" },
          { dataType: "datetime", accessor: "lastModified" },
        ]}
      />
    </Card>
  );
}
