import { NumberField, ObjectField, TimestampField } from "~/components/Form";
import {
  EditLqsResourceForm,
  useDigestionTopic,
  useUpdateDigestionTopic,
} from "~/lqs";
import { makeDigestionTopicLocation, useDigestionTopicParams } from "~/paths";
import { selectData } from "~/utils";
import {
  EDITABLE_DIGESTION_TOPIC_FIELDS,
  editDigestionTopicSchema,
} from "../schemas";

export function DigestionTopicEditForm() {
  const { digestionId, topicId } = useDigestionTopicParams();

  return (
    <EditLqsResourceForm
      schema={editDigestionTopicSchema}
      resourceName="digestion topic"
      query={useDigestionTopic(digestionId, topicId, { select: selectData })}
      editableFields={EDITABLE_DIGESTION_TOPIC_FIELDS}
      mutation={useUpdateDigestionTopic(digestionId, topicId)}
      detailsLocation={makeDigestionTopicLocation({ digestionId, topicId })}
    >
      {(control) => (
        <>
          <TimestampField control={control} name="startTime" />
          <TimestampField control={control} name="endTime" />
          <NumberField control={control} name="frequency" />
          <ObjectField control={control} name="queryDataFilter" />
          <ObjectField control={control} name="contextFilter" />
        </>
      )}
    </EditLqsResourceForm>
  );
}
