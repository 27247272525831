import React from "react";
import type { StrictOmit } from "ts-essentials";
import type { OverrideProperties } from "type-fest";
import type { Column, ResourceTableProps } from "~/components/Table";
import { ResourceTable } from "~/components/Table";
import type { DataStorePathGenerator } from "~/paths";
import { useNormalizeDataStorePath } from "~/paths";
import { renderForeignResource } from "./renderForeignResource";
import type { LqsForeignResourceType } from "./types";

export type LqsColumn<TResource extends object> = Column<
  TResource,
  LqsForeignResourceType
>;

type LqsResourceTableProps<TResource extends object> = OverrideProperties<
  StrictOmit<
    ResourceTableProps<TResource, LqsForeignResourceType>,
    "renderForeignResource"
  >,
  { resourceCreateLocation?: DataStorePathGenerator }
>;

export function LqsResourceTable<TResource extends object>({
  resourceCreateLocation,
  ...rest
}: LqsResourceTableProps<TResource>) {
  const normalizeDataStorePath = useNormalizeDataStorePath();

  return (
    <ResourceTable
      {...rest}
      resourceCreateLocation={
        resourceCreateLocation !== undefined
          ? normalizeDataStorePath(resourceCreateLocation)
          : undefined
      }
      renderForeignResource={renderForeignResource}
    />
  );
}
