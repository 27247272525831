import prettyMilliseconds from "pretty-ms";
import { Time } from "~/components/Time";
import {
  fromUnixTime,
  nanosecondsToMilliseconds,
  nanosecondsToSeconds,
} from "~/lib/dates";
import type { Log } from "~/lqs";

export function renderRecorded(log: Log) {
  return log.startTime === null ? (
    "-"
  ) : (
    <Time date={fromUnixTime(nanosecondsToSeconds(log.startTime))} />
  );
}

export function renderDuration(log: Log) {
  const { startTime, endTime } = log;

  return startTime === null || endTime === null
    ? "-"
    : prettyMilliseconds(nanosecondsToMilliseconds(endTime - startTime), {
        secondsDecimalDigits: 0,
      });
}
