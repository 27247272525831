import { TablePage } from "~/components/Table";
import { LqsNavigation } from "~/lqs";
import { ApiKeyFilters } from "./components/ApiKeyFilters";
import {
  ApiKeySearchRequestProvider,
  ApiKeyTable,
} from "./components/ApiKeyTable";

export function ApiKeysPage() {
  return (
    <TablePage
      title="Search API Keys"
      navigation={<LqsNavigation />}
      RequestProvider={ApiKeySearchRequestProvider}
      table={<ApiKeyTable />}
      filters={<ApiKeyFilters />}
    />
  );
}
