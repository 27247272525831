import {
  CheckboxField,
  NumberField,
  ObjectField,
  SelectField,
  TextField,
} from "~/components/Form";
import {
  EditLqsResourceForm,
  processStateOptions,
  useIngestionPart,
  useUpdateIngestionPart,
  WorkflowSelect,
} from "~/lqs";
import { makeIngestionPartLocation, useIngestionPartParams } from "~/paths";
import { selectData } from "~/utils";
import {
  EDITABLE_INGESTION_PART_FIELDS,
  editIngestionPartSchema,
} from "../schemas";

export function IngestionPartEditForm() {
  const { ingestionId, ingestionPartId } = useIngestionPartParams();

  return (
    <EditLqsResourceForm
      schema={editIngestionPartSchema}
      resourceName="ingestion part"
      query={useIngestionPart(ingestionId, ingestionPartId, {
        select: selectData,
      })}
      editableFields={EDITABLE_INGESTION_PART_FIELDS}
      mutation={useUpdateIngestionPart(ingestionId, ingestionPartId)}
      detailsLocation={makeIngestionPartLocation({
        ingestionPartId,
        ingestionId,
      })}
    >
      {(control) => (
        <>
          <NumberField control={control} name="sequence" required />
          <TextField control={control} name="source" />
          <SelectField
            control={control}
            name="state"
            options={processStateOptions}
            required
          />
          <NumberField control={control} name="progress" />
          <WorkflowSelect control={control} name="workflowId" />
          <ObjectField control={control} name="workflowContext" />
          <ObjectField control={control} name="error" />
          <CheckboxField control={control} name="locked" />
        </>
      )}
    </EditLqsResourceForm>
  );
}
