import React, { useState } from "react";
import { Error as ErrorIcon, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  paginationClasses,
  Skeleton,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import * as z from "zod";
import { TextField, useStudioForm } from "~/components/Form";
import { Loading } from "~/components/Loading";
import { Pagination } from "~/components/Pagination";
import { renderQuery } from "~/components/QueryRenderer";
import { useSearchRequest } from "~/components/Table";
import { ErrorMessage } from "~/components/error-message";
import {
  filterText,
  requiredText,
  selectCountableListResponse,
} from "~/domain/common";
import { LogTagChip, useLogLevelTag, useLogLevelTags } from "~/domain/logs";
import { SidebarHeader } from "~/layout";
import type { Label, Log } from "~/lqs";
import { useCreateLabel, useCreateTag, useDeleteTag, useLabels } from "~/lqs";
import { createPrefixedAliases, getEventHandlerProps } from "~/utils";
import { usePlayerConfig, usePlayerLog } from "../../hooks";
import { useAreLogTagsShowing, useTagActions } from "../../tags";

const LABELS_LIMIT = 15;

const labelsSearchSchema = z.object({
  value: filterText,
});

const labelsRequestSchema = labelsSearchSchema.extend({
  offset: z.coerce
    .number()
    .nonnegative()
    .refine((arg) => arg % LABELS_LIMIT === 0)
    .catch(0),
});

const newLabelSchema = z.object({
  value: requiredText,
});

export function TaggingSidebar() {
  const { logId } = usePlayerConfig();
  const playerLogQuery = usePlayerLog();

  const areLogTagsShowing = useAreLogTagsShowing();
  const { toggleTags } = useTagActions();

  function handleToggleTags(): void {
    toggleTags({ topicId: null });
  }

  const tagsQuery = useLogLevelTags(playerLogQuery.data);

  const [labelsSearchRequest, setLabelsSearchRequest] = useSearchRequest(
    labelsRequestSchema,
    createPrefixedAliases("label", labelsRequestSchema.keyof().options),
  );

  const labelsQuery = useLabels(
    {
      valueLike: labelsSearchRequest.value,
      limit: LABELS_LIMIT,
      offset: labelsSearchRequest.offset,
      sort: "asc",
      order: "value",
      includeCount: true,
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      select: selectCountableListResponse,
    },
  );

  const labelsSearchForm = useStudioForm({
    schema: labelsSearchSchema,
    values: { value: labelsSearchRequest.value },
    onSubmit: setLabelsSearchRequest,
  });

  const { enqueueSnackbar } = useSnackbar();

  const [creating, setCreating] = useState(false);

  const createLabelMutation = useCreateLabel();
  const newLabelForm = useStudioForm({
    schema: newLabelSchema,
    defaultValues: { value: null },
    onSubmit(values) {
      createLabelMutation.mutate(values, {
        onSuccess() {
          enqueueSnackbar("Tag created", { variant: "success" });
          setCreating(false);
          newLabelForm.reset();
        },
        onError() {
          enqueueSnackbar("Unable to create tag", { variant: "error" });
        },
      });
    },
  });

  const startCreatingHandlerProps = getEventHandlerProps(
    "onClick",
    labelsQuery.isSuccess &&
      !creating &&
      function handleStartCreating() {
        setCreating(true);
      },
  );

  const cancelCreatingHandlerProps = getEventHandlerProps(
    "onClick",
    creating &&
      !createLabelMutation.isLoading &&
      function handleCancelCreating() {
        setCreating(false);
        newLabelForm.reset();
      },
  );

  let content;
  if (logId == null) {
    content = (
      <Alert severity="info" variant="filled" sx={{ mb: 2 }}>
        Choose a log in the log sidebar to begin tagging
      </Alert>
    );
  } else {
    content = renderQuery(playerLogQuery, {
      loading: <Loading type="circular" />,
      error: <ErrorMessage>Unable to load log. Cannot tag.</ErrorMessage>,
      success(log) {
        return (
          <>
            <FormControlLabel
              sx={{ justifyContent: "space-between", ml: 0 }}
              control={
                <Switch
                  checked={areLogTagsShowing}
                  onChange={handleToggleTags}
                />
              }
              label="Show timestamped log tags"
              labelPlacement="start"
            />
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" component="p" paragraph>
              Applied Tags
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                flexWrap: tagsQuery.status === "error" ? "nowrap" : "wrap",
              }}
            >
              {renderQuery(tagsQuery, {
                loading: [1, 2, 3].map((key) => (
                  <Skeleton
                    key={key}
                    variant="rectangular"
                    width="8ch"
                    height={32}
                    sx={{ borderRadius: 4 }}
                  />
                )),
                error: (
                  <>
                    <ErrorIcon color="error" />
                    <Typography>
                      Couldn't load this log's tags. Adding and removing tags is
                      disabled.
                    </Typography>
                  </>
                ),
                success(tags) {
                  return tags.length === 0 ? (
                    <Typography sx={{ fontStyle: "italic", height: 32 }}>
                      No log-level tags
                    </Typography>
                  ) : (
                    tags.map((tag) => (
                      <LogTagChip key={tag.id} log={log} tag={tag} />
                    ))
                  );
                },
              })}
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Stack
              direction="row"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h6" component="p">
                All Tags
              </Typography>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                {...startCreatingHandlerProps}
              >
                New Tag
              </Button>
            </Stack>
            {creating && (
              <>
                <Stack
                  spacing={1}
                  component="form"
                  noValidate
                  onSubmit={newLabelForm.handleSubmit}
                >
                  <Typography sx={{ fontWeight: "bold" }}>New Tag</Typography>
                  <TextField
                    name="value"
                    control={newLabelForm.control}
                    required
                    size="small"
                  />
                  <Stack direction="row" spacing={2}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="small"
                      disableElevation
                      loading={createLabelMutation.isLoading}
                    >
                      Create tag
                    </LoadingButton>
                    <Button
                      variant="text"
                      color="secondary"
                      size="small"
                      {...cancelCreatingHandlerProps}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
                <Divider sx={{ my: 2 }} />
              </>
            )}
            <Box
              component="form"
              noValidate
              onSubmit={labelsSearchForm.handleSubmit}
            >
              <TextField
                name="value"
                control={labelsSearchForm.control}
                label="Search tags"
                size="small"
                noHelperText
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip title="Search">
                      <IconButton type="submit" edge="end" size="small">
                        <Search fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
              />
            </Box>
            <LinearProgress
              // Remount when made visible so animation restarts
              key={String(labelsQuery.isRefetching)}
              sx={{
                mt: 2,
                // Always present to prevent layout shift when made visible
                visibility: labelsQuery.isRefetching ? "visible" : "hidden",
              }}
            />
            {renderQuery(labelsQuery, {
              loading: <Loading type="circular" />,
              error: <ErrorMessage>Unable to load tags</ErrorMessage>,
              success(response) {
                return (
                  <>
                    {response.data.length === 0 ? (
                      <Typography
                        sx={{
                          my: 2,
                          fontStyle: "italic",
                          textAlign: "center",
                        }}
                      >
                        No tags
                      </Typography>
                    ) : (
                      <List disablePadding>
                        {response.data.map((label) => (
                          <LabelListItem
                            key={label.id}
                            log={log}
                            label={label}
                          />
                        ))}
                      </List>
                    )}
                    <Box
                      sx={{
                        mt: 2,
                        [`& .${paginationClasses.ul}`]: {
                          justifyContent: "space-between",
                        },
                      }}
                    >
                      <Pagination
                        size="large"
                        disableJumping
                        count={response.count}
                        limit={LABELS_LIMIT}
                        offset={labelsSearchRequest.offset}
                        onChange={(offset) =>
                          setLabelsSearchRequest({ offset })
                        }
                      />
                    </Box>
                  </>
                );
              },
            })}
          </>
        );
      },
    });
  }

  return (
    <>
      <SidebarHeader title="Tagging" />
      <Stack>{content}</Stack>
    </>
  );
}

function LabelListItem({ log, label }: { log: Log; label: Label }) {
  const tagQuery = useLogLevelTag(log, label.id);

  const { enqueueSnackbar } = useSnackbar();

  const createTagMutation = useCreateTag(log.id);
  const deleteTagMutation = useDeleteTag(log.id);

  const toggleTagEnabled =
    tagQuery.isSuccess &&
    !createTagMutation.isLoading &&
    !deleteTagMutation.isLoading;

  const buttonHandlerProps = getEventHandlerProps(
    "onClick",
    toggleTagEnabled &&
      function handleToggleTag() {
        if (tagQuery.data !== null) {
          // Delete existing tag
          deleteTagMutation.mutate(tagQuery.data.id, {
            onError() {
              enqueueSnackbar("Unable to remove tag", { variant: "error" });
            },
          });

          createTagMutation.reset();
        } else {
          // Create new tag
          createTagMutation.mutate(
            { labelId: label.id },
            {
              onError() {
                enqueueSnackbar("Unable to tag log", { variant: "error" });
              },
            },
          );

          deleteTagMutation.reset();
        }
      },
  );

  return (
    <ListItem key={label.id} disablePadding>
      <ListItemButton {...buttonHandlerProps} disableGutters>
        <ListItemIcon>
          <Box sx={{ alignSelf: "center", position: "relative" }}>
            <Checkbox
              tabIndex={-1}
              checked={tagQuery.isSuccess && tagQuery.data !== null}
              disabled={buttonHandlerProps.disabled}
            />
            {(createTagMutation.isLoading || deleteTagMutation.isLoading) && (
              <CircularProgress
                size="2rem"
                sx={{ position: "absolute", inset: 0, margin: "auto" }}
              />
            )}
          </Box>
        </ListItemIcon>
        <Chip label={label.value} />
      </ListItemButton>
    </ListItem>
  );
}
