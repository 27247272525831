import { Card, CardContent } from "@mui/material";
import { NumberField, ObjectField, TimestampField } from "~/components/Form";
import { Loading } from "~/components/Loading";
import { QueryRenderer } from "~/components/QueryRenderer";
import { ErrorMessage } from "~/components/error-message";
import {
  NewLqsResourceForm,
  TopicSelect,
  useCreateDigestionTopic,
  useDigestion,
} from "~/lqs";
import { makeDigestionTopicLocation, useDigestionParams } from "~/paths";
import { selectData } from "~/utils";
import { createDigestionTopicSchema } from "../schemas";

export function DigestionTopicCreateForm() {
  const { digestionId } = useDigestionParams();

  const digestionQuery = useDigestion(digestionId, { select: selectData });

  const createDigestionTopic = useCreateDigestionTopic(digestionId);

  return (
    <QueryRenderer
      query={digestionQuery}
      loading={
        <Card>
          <CardContent>
            <Loading type="circular" />
          </CardContent>
        </Card>
      }
      error={
        <Card>
          <CardContent>
            <ErrorMessage>Error fetching digestion</ErrorMessage>
          </CardContent>
        </Card>
      }
      success={(digestion) => (
        <NewLqsResourceForm
          schema={createDigestionTopicSchema}
          defaultValues={{
            topicId: null,
            startTime: null,
            endTime: null,
            frequency: null,
            queryDataFilter: null,
            contextFilter: null,
          }}
          resourceName="digestion topic"
          mutation={createDigestionTopic}
          createDetailsLocation={(response) =>
            makeDigestionTopicLocation({
              digestionId,
              topicId: response.data.id,
            })
          }
        >
          {(control) => (
            <>
              <TopicSelect
                control={control}
                name="topicId"
                logId={digestion.logId}
                required
              />
              <TimestampField control={control} name="startTime" />
              <TimestampField control={control} name="endTime" />
              <NumberField control={control} name="frequency" />
              <ObjectField control={control} name="queryDataFilter" />
              <ObjectField control={control} name="contextFilter" />
            </>
          )}
        </NewLqsResourceForm>
      )}
    />
  );
}
