import { useCallback } from "react";
import type { Topic } from "~/lqs";
import type { PointerLocation } from "./hooks";
import { useOnDemandInference } from "./inference";
import { useControlledPanelContext } from "./layout";
import type {
  FlipDirection,
  InitializedPanelNode,
  LayoutNode,
  PanelNode,
  RotationDirection,
  SplitOrientation,
  UninitializedPanelNode,
  VisualizationType,
} from "./panels";
import * as panels from "./panels";
import * as playback from "./playback";
import type { PlaybackSpeedValue, TimeRange, TimestepValue } from "./types";

// This hook provides handlers for responding to most actions in the Player.
// Many of these actions just wrap the underlying dispatch or state-setting
// call but some of these require state updates across several providers.
// Rather than spreading the logic around the Player, this hook encapsulates it
// and exposes a simple function per action for what's happening from the
// consumer's perspective.
// Were the Player to switch to a zustand store or even just one big React
// reducer for all the related state, this hook would nicely encapsulate that
// change and should let consumers continue using these actions as-is.
export function usePlayerActions() {
  const { dispatch: panelLayoutDispatch } = panels.usePanelLayoutContext();

  const { dispatch: playbackSourceDispatch } = playback.usePlaybackSource();

  const playbackSettings = playback.usePlaybackSettings();

  const controlledPanelContext = useControlledPanelContext();

  const onDemandInference = useOnDemandInference();

  return {
    loadLayout(layout: LayoutNode): void {
      panelLayoutDispatch(panels.loadLayout({ layout }));
      controlledPanelContext.handleLoadLayout();
      onDemandInference.handleLoadLayout();
    },
    // Used in a memoized callback passed to <TopicTree />
    selectPanelTopic: useCallback(
      (panelId: UninitializedPanelNode["id"], topic: Topic): void => {
        panelLayoutDispatch(panels.selectTopic({ panelId, topic }));
      },
      [panelLayoutDispatch],
    ),
    chooseVisualization(
      panel: PanelNode,
      visualization: VisualizationType,
    ): void {
      panelLayoutDispatch(
        panels.chooseVisualization({ panelId: panel.id, tab: visualization }),
      );
      onDemandInference.handleChangePanelVisualization(panel);
    },
    clearPanelTopic(panel: PanelNode): void {
      panelLayoutDispatch(panels.chooseNewTopic({ panelId: panel.id }));
      controlledPanelContext.handleClearPanelTopic(panel);
      onDemandInference.handleClearPanel(panel);
    },
    closePanel(panel: PanelNode): void {
      panelLayoutDispatch(panels.removePanel({ panelId: panel.id }));
      controlledPanelContext.handleClosePanel(panel);
      onDemandInference.handleClosePanel(panel);
    },
    togglePanelControls(panel: InitializedPanelNode): void {
      controlledPanelContext.togglePanelControls(panel);
    },
    splitPanel(panel: PanelNode, orientation: SplitOrientation): void {
      panelLayoutDispatch(
        panels.splitPanel({ panelId: panel.id, orientation }),
      );
    },
    toggleImageColorization(
      panel: InitializedPanelNode,
      colorize: boolean,
    ): void {
      panelLayoutDispatch(
        panels.toggleImageColorization({ panelId: panel.id, colorize }),
      );
    },
    rotateImage(
      panel: InitializedPanelNode,
      direction: RotationDirection,
    ): void {
      panelLayoutDispatch(panels.rotateImage({ panelId: panel.id, direction }));
    },
    flipImage(
      panel: InitializedPanelNode,
      flipDirection: FlipDirection | null,
    ): void {
      panelLayoutDispatch(
        panels.setImageFlipDirection({
          panelId: panel.id,
          flipDirection,
        }),
      );
    },
    setInferenceTopic(panel: InitializedPanelNode, topic: Topic | null): void {
      panelLayoutDispatch(
        panels.setInferenceTopic({ panelId: panel.id, inferenceTopic: topic }),
      );
    },
    toggleInferenceTransformLock(
      panel: InitializedPanelNode,
      lock: boolean,
    ): void {
      panelLayoutDispatch(
        panels.toggleInferenceTransformLock({ panelId: panel.id, lock }),
      );
    },
    toggleInferenceBoundingBoxes(
      panel: InitializedPanelNode,
      show: boolean,
    ): void {
      panelLayoutDispatch(
        panels.showDetectionBoundingBoxes({
          panelId: panel.id,
          showDetectionBoundingBoxes: show,
        }),
      );
    },
    toggleInferenceClassNames(
      panel: InitializedPanelNode,
      show: boolean,
    ): void {
      panelLayoutDispatch(
        panels.showDetectionClassNames({
          panelId: panel.id,
          showDetectionClassNames: show,
        }),
      );
    },
    toggleObjectClass(
      panel: InitializedPanelNode,
      className: string,
      show: boolean,
    ): void {
      panelLayoutDispatch(
        panels.changeObjectClassVisibility({
          panelId: panel.id,
          className,
          hideClass: !show,
        }),
      );
    },
    setInferenceImageOpacity(
      panel: InitializedPanelNode,
      opacity: number,
    ): void {
      panelLayoutDispatch(
        panels.setInferenceImageOpacity({ panelId: panel.id, opacity }),
      );
    },
    toggleInferenceImageColorization(
      panel: InitializedPanelNode,
      colorize: boolean,
    ): void {
      panelLayoutDispatch(
        panels.toggleInferenceImageColorization({
          panelId: panel.id,
          colorize,
        }),
      );
    },
    clipInferenceImage(
      panel: InitializedPanelNode,
      pointerLocation: PointerLocation,
      baseImageDimensions: { naturalWidth: number; naturalHeight: number },
    ): void {
      panelLayoutDispatch(
        panels.setInferenceImageClipInset({
          panelId: panel.id,
          pointerLocation,
          baseImageDimensions,
        }),
      );
    },
    addChartField(
      panel: InitializedPanelNode,
      field: string,
      data: object | null,
    ): void {
      panelLayoutDispatch(
        panels.addChartField({ panelId: panel.id, field, data }),
      );
    },
    removeChartField(panel: InitializedPanelNode, field: string): void {
      panelLayoutDispatch(
        panels.removeChartField({ panelId: panel.id, field }),
      );
    },
    setPointCloudPointSize(panel: InitializedPanelNode, size: number): void {
      panelLayoutDispatch(
        panels.setPointCloudPointSize({ panelId: panel.id, pointSize: size }),
      );
    },
    selectTag(panel: InitializedPanelNode, tag: string): void {
      panelLayoutDispatch(panels.selectTag({ panelId: panel.id, tag }));
    },
    setPlaybackSpeed(speed: PlaybackSpeedValue): void {
      playbackSettings.setSpeed(speed);
    },
    setPlaybackTimestep(timestep: TimestepValue): void {
      playbackSettings.setTimestep(timestep);
    },
    play(): void {
      playbackSourceDispatch(playback.play());
    },
    pause(): void {
      playbackSourceDispatch(playback.pause());
    },
    restart(): void {
      playbackSourceDispatch(playback.restart());
    },
    previousFrame(): void {
      playbackSourceDispatch(playback.previousFrame());
    },
    nextFrame(): void {
      playbackSourceDispatch(playback.nextFrame());
    },
    seek(to: bigint): void {
      playbackSourceDispatch(playback.seek({ to }));
    },
    // Used in an Effect
    tick: useCallback(() => {
      playbackSourceDispatch(playback.tick());
    }, [playbackSourceDispatch]),
    enterPlaybackRangeMode(): void {
      playbackSourceDispatch(playback.enterRangeMode());
    },
    exitPlaybackRangeMode(): void {
      playbackSourceDispatch(playback.exitRangeMode());
    },
    setRange(range: TimeRange): void {
      playbackSourceDispatch(playback.setRange({ range }));
    },
  };
}
